import { useCallback } from 'react'

import { auth, firestore, GeoPoint } from 'services/firebase'

import useGetUserByUid from '../users/useGetUserByUid'

const useGetContacts = () => {
  const { uid } = auth.currentUser
  const doGetUserByUid = useGetUserByUid()

  return useCallback(async () => {
    const clients = await firestore
      .collection('chats')
      .where('lawyerUid', '==', uid)
      .get()
      .then(async (snap) => {
        const contacts = snap.docs.map(async (item) => {
          const getUserData = await doGetUserByUid(item.data().clientUid)
          if (getUserData) {
            return getUserData[0]
          }
        })
        return Promise.all(contacts).then((result) => {
          return result
        })
      })
    const lawyers = await firestore
      .collection('chats')
      .where('clientUid', '==', uid)
      .get()
      .then(async (snap) => {
        const contacts = snap.docs.map(async (item) => {
          const getUserData = await doGetUserByUid(item.data().lawyerUid)
          if (getUserData) {
            return getUserData[0]
          }
        })
        return Promise.all(contacts).then((result) => {
          return result
        })
      })
    return Promise.all([clients, lawyers]).then((result) => {
      return lawyers.concat(clients)
    })
  }, [])
}

export default useGetContacts
