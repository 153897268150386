import { useCallback } from 'react'

import moment from 'moment'

import { auth, firestore } from 'services/firebase'

const useGetUserNotifications = () => {
  return useCallback(async () => {
    const { uid } = auth.currentUser

    return firestore
      .collection('users')
      .doc(uid)
      .collection('notifications')
      .orderBy('date', 'desc')
      .get()
      .then((snap) => {
        if (snap.size > 0) {
          return snap.docs.map((item) => {
            return { id: item.id, ...item.data() }
          })
        }
      })
  }, []) // eslint-disable-line
}

export default useGetUserNotifications
