import React from 'react'

import { FaBell } from 'react-icons/fa'

import WhiteBox from 'components/atoms/WhiteBox'

import Generic from '../Generic'

const Notifications = () => {
  return (
    <WhiteBox title='Notifications' modifier='-notifications' icon={<FaBell />}>
      <main>
        <Generic
          toggle
          type='notificationByEmail'
          title='Email'
          legend='Receive Email notifications for new alerts'
        />
        <Generic
          toggle
          type='notificationByPushNotification'
          title='Mobile'
          legend='Enable Push notifications on your mobile device or tablet'
        />
      </main>
    </WhiteBox>
  )
}

export default Notifications
