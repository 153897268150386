import React from 'react'

import { makeStyles, Theme, Typography } from '@material-ui/core'
import Avatar from 'react-avatar'

import loginLogo from 'images/login-logo.png'

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#11132c',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '379px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#18a1db',
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      textAlign: 'center',
      position: 'relative',
      padding: 20,
      '& svg': {
        height: '64px',
      },
    },
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
    maxWidth: 150,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '3em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
      textAlign: 'center',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  subContentContainer: {
    position: 'absolute',
    marginTop: '1em',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
}))

interface IntroContainerProps {
  children: React.ReactNode
  subContent?: React.ReactNode
  name: string
  avatar: string
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles()

  return (
    <div className={classes.background}>
      <div className={classes.logo}>
        <img src={loginLogo} alt='' />
      </div>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <Avatar
                size='120'
                maxInitials={2}
                round='100%'
                color='#26395A'
                textSizeRatio={2.5}
                src={props.avatar}
                name={props.name}
              />
              <Typography variant='h6' className={classes.title}>
                Video call with <br />
                <b>{props.name}</b>
              </Typography>
            </div>
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
        {props.subContent && (
          <div className={classes.subContentContainer}>{props.subContent}</div>
        )}
      </div>
    </div>
  )
}

export default IntroContainer
