import React, { useEffect, useRef, useState } from 'react'

import { RiCheckFill, RiPencilFill, RiUserFill } from 'react-icons/ri'

import WhiteBox from 'components/atoms/WhiteBox'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

import Experience from './Experience'
import Languages from './Languages'
import TeamMembers from './TeamMembers'

import './AboutMe.scss'

const AboutMe = () => {
  const elementRef = useRef(null)
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.about)
  const [activeFooterItem, setActiveFooterItem] = useState(null)

  const handleEdit = () => {
    if (edit) {
      if (value) {
        doUpdateUserProfile({
          about: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  useEffect(() => {
    if (edit) {
      elementRef.current.focus()
    }
  }, [edit])

  return (
    <WhiteBox
      title='About Me'
      modifier='-aboutMe'
      icon={<RiUserFill />}
      actions={
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <RiCheckFill /> : <RiPencilFill />}
        </button>
      }
    >
      <div className='m-aboutMe'>
        <main>
          {edit ? (
            <textarea
              ref={elementRef}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              {value}
            </textarea>
          ) : (
            <p className={value ? '' : '-empty'}>
              {value ? value : 'Tell us a little about yourself'}
            </p>
          )}
        </main>
        <footer>
          {(activeFooterItem === null || activeFooterItem === 'experience') && (
            <Experience handleEditing={setActiveFooterItem} />
          )}
          {(activeFooterItem === null || activeFooterItem === 'languages') && (
            <Languages handleEditing={setActiveFooterItem} />
          )}
          {(activeFooterItem === null || activeFooterItem === 'members') && (
            <TeamMembers handleEditing={setActiveFooterItem} />
          )}
        </footer>
      </div>
    </WhiteBox>
  )
}

export default AboutMe
