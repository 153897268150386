import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'

import { auth } from 'services/firebase'
import useGetCases from 'services/firebase/hooks/cases/useGetCases'

export const CasesContext = createContext()

export const useCases = () => useContext(CasesContext)

export const CasesProvider = (props) => {
  const currentUser = auth.currentUser

  const doGetCases = useGetCases()

  const [filter, setFilter] = useState('all')
  const [fetchingCases, setFetchingCases] = useState(true)
  const [activeCases, setActiveCases] = useState([])
  const [offers, setOffers] = useState([])
  const [completeCases, setCompleteCases] = useState([])
  const [cases, setCases] = useState([])
  const [casesWithoutFilter, setCasesWithoutFilter] = useState([])

  const [caseData, setCaseData] = useState(null)
  const [modalIcon, setModalIcon] = useState(null)
  const [showAction, setShowAction] = useState(null)

  const getCases = useCallback(async () => {
    doGetCases()
      .then(async (result) => {
        if (result) {
          await handleCasesType(result)
        }
        setFetchingCases(false)
      })
      .catch(() => {
        setFetchingCases(false)
      })
  }, [])

  const updateCases = async (caseData) => {
    const cases = casesWithoutFilter
    const caseIndex = cases.findIndex((item) => item.caseId === caseData.caseId)
    cases[caseIndex] = {
      ...cases[caseIndex],
      ...caseData,
    }
    await handleCasesType(cases)
  }

  const handleCasesType = async (cases) => {
    setCasesWithoutFilter(cases)
    const activeCases = await cases.filter((item) => item.status === 'active')
    const offers = await cases.filter((item) => item.status === 'offer')
    const completedCases = await cases.filter(
      (item) =>
        item.status === 'complete' || item.status === 'completed_by_lawyer'
    )
    if (activeCases.length > 0) {
      setActiveCases(activeCases)
    }
    if (offers.length > 0) {
      setOffers(offers)
    }
    if (completedCases.length > 0) {
      setCompleteCases(completedCases)
    }
  }

  const removeOffer = (offerId) => {
    const newCases = [...cases]
    const indexOffer = newCases.findIndex((item) => offerId === item.caseId)
    newCases.splice(indexOffer, 1)
    setCases(newCases)
  }

  const handleModal = ({ caseData: item, type, icon }) => {
    if (item && item.senderUid !== currentUser.uid) {
      if (type === 'markAsCompleted' && item.status !== 'completed_by_lawyer') {
        return false
      }
      if (type === 'requestExtension' && !item.newDeadline) {
        return false
      }
    }
    setModalIcon(icon)
    setShowAction(type)
    setCaseData(item)
  }

  useEffect(() => {
    setCases(casesWithoutFilter)
  }, [casesWithoutFilter])

  useEffect(() => {
    switch (filter) {
      case 'offers':
        setCases(offers)
        break
      case 'active':
        setCases(activeCases)
        break
      case 'completed':
        setCases(completeCases)
        break
      default:
        setCases(casesWithoutFilter)
    }
  }, [filter])

  return (
    <CasesContext.Provider
      value={{
        getCases,
        cases,
        casesWithoutFilter,
        filter,
        setFilter,
        fetchingCases,
        handleModal,
        modalIcon,
        showAction,
        setShowAction,
        caseData,
        removeOffer,
        updateCases,
      }}
      {...props}
    />
  )
}
