import React, { useEffect, useState } from 'react'

import Loader from 'components/atoms/Loader'
import PanelContainer from 'components/atoms/PanelContainer'
import TimelineItem from 'components/atoms/TimelineItem'
import WhiteBox from 'components/atoms/WhiteBox'
import CaseCard from 'components/molecules/CaseCard'
import { ReactComponent as FolderIcon } from 'images/icons/folder.svg'
import { ReactComponent as TimelineIcon } from 'images/icons/timeline.svg'
import useGetCaseById from 'services/firebase/hooks/cases/useGetCaseById'
import useGetCaseTimeline from 'services/firebase/hooks/cases/useGetCaseTimeline'

import './ManageCase.scss'

const ManageCase = ({ match }) => {
  const doGetCaseById = useGetCaseById()
  const doGetCaseTimeline = useGetCaseTimeline()

  const [isLoading, setIsLoading] = useState(true)
  const [caseData, setCaseData] = useState(null)
  const [timeline, setTimeline] = useState([])

  useEffect(() => {
    doGetCaseTimeline(match.params.caseId).then((result) => {
      setTimeline(result)
    })
    doGetCaseById(match.params.caseId).then((result) => {
      setCaseData(result)
      setIsLoading(false)
    })
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <PanelContainer>
      <div className='p-manageCase'>
        <WhiteBox
          withOpacity
          icon={<FolderIcon className='p-cases__items--icon' />}
          title='About the case'
        >
          <CaseCard data={caseData} />
        </WhiteBox>
        <WhiteBox
          modifier='-timeline'
          icon={<TimelineIcon className='p-cases__items--icon' />}
          title='Timeline'
        >
          {timeline.length > 0 ? (
            <div className='p-manageCase__timeline'>
              {timeline.map((item, key) => {
                return <TimelineItem data={item} key={`timeline-item-${key}`} />
              })}
            </div>
          ) : (
            <div />
          )}
        </WhiteBox>
      </div>
    </PanelContainer>
  )
}

export default ManageCase
