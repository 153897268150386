import React from 'react'

import successImage from 'images/successfully-image.png'
import './SuccessfullyRegistered.scss'

const SuccessfullyRegistered = () => {
  return (
    <div className='o-successfullyRegistered'>
      <div className='o-successfullyRegistered__title'>
        Registration <br />
        complete
      </div>
      <div className='o-successfullyRegistered__image'>
        <img src={successImage} alt='' />
      </div>
      <div className='o-successfullyRegistered__footer'>
        <span>
          Our team is verifying you now. <br />
          We will email you as soon your profile is online{' '}
        </span>
        <small>All new accounts veriﬁed within 24 hours</small>
      </div>
    </div>
  )
}

export default SuccessfullyRegistered
