import React, { useState } from 'react'

import { HiOutlinePlusCircle } from 'react-icons/hi'
import {
  RiCheckFill,
  RiLinksFill,
  RiPencilFill,
  RiLinkedinFill,
} from 'react-icons/ri'

import WhiteBox from 'components/atoms/WhiteBox'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

const Linkedin = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.linkedIn)
  const handleEdit = () => {
    if (edit) {
      if (value !== userInfo.linkedIn) {
        doUpdateUserProfile({
          linkedIn: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  return (
    <WhiteBox
      withOpacity
      title='LinkedIn'
      modifier='-linkedIn'
      icon={<RiLinkedinFill />}
      actions={
        (userInfo.linkedIn || edit) && (
          <button
            onClick={handleEdit}
            type='button'
            className={edit ? '-editing' : ''}
          >
            {edit ? <RiCheckFill /> : <RiPencilFill />}
          </button>
        )
      }
    >
      {edit ? (
        <input
          type='text'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : !userInfo.linkedIn ? (
        <button onClick={handleEdit} type='button'>
          Add LinkedIn
          <HiOutlinePlusCircle />
        </button>
      ) : (
        <a href={value} title={value} rel='noopener noreferrer' target='_blank'>
          <div className='-icon'>
            <RiLinksFill />
          </div>
          <span>{value.replace('https://', '')}</span>
        </a>
      )}
    </WhiteBox>
  )
}

export default Linkedin
