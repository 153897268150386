import React, { useState } from 'react'

import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as CloseIcon } from 'images/icon-close-modal.svg'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

const ProfileCompleteMessage = () => {
  const [userInfo] = useUserInfo()
  const doUpdateUserProfile = useUpdateUserProfile()
  const [hideComplete, setHideComplete] = useState(false)

  const handleHideCompleteMessage = () => {
    doUpdateUserProfile({ hideComplete: true }, false)
    setHideComplete(true)
  }

  return (
    <>
      {userInfo.validatedAccount && !userInfo.hideComplete && !hideComplete && (
        <div className='o-lawyerDashboard__main--box -welcome'>
          <header>
            <h3>
              Your profile is now <b>complete!</b>
            </h3>
            <button onClick={handleHideCompleteMessage}>
              <CloseIcon />
            </button>
          </header>
          <main className='-complete'>
            <div className='c-flex'>
              <div>
                <p>
                  Thank you for verifying your credentials, your profile is now
                  online! People can search and contact you. You&apos;ll receive
                  a message when a client has contacted you.
                </p>
                <p>
                  If you want to stand out from the crowd, add a short Bio and
                  invite your colleagues, this will appear on your profile and
                  increase consumer confidence.
                </p>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  )
}

export default ProfileCompleteMessage
