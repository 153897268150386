import React from 'react'

import Section from '../../atoms/Section'
import Title from '../../atoms/Title'
import SponsorsCarousel from '../SponsorsCarousel'

import './OurSponsors.scss'

const OurSponsors = () => {
  return (
    <Section className='o-ourSponsors'>
      <Title>Supported by</Title>
      <div className='o-ourSponsors__carousel'>
        <SponsorsCarousel />
      </div>
    </Section>
  )
}

export default OurSponsors
