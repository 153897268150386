import React, { useEffect, useState } from 'react'

import { Menu, MenuItem } from '@material-ui/core'
import { FiChevronDown } from 'react-icons/fi'

import { useCases } from 'context/cases'

import './CaseFilters.scss'

const CaseFilters = () => {
  const { filter, setFilter } = useCases()
  const [filterLabel, setFilterLabel] = useState<string>('All')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  useEffect(() => {
    switch (filter) {
      case 'completed':
        setFilterLabel('Completed')
        break
      case 'active':
        setFilterLabel('In Progress')
        break
      case 'offer':
        setFilterLabel('Offers')
        break
      default:
        setFilterLabel('All')
    }
  }, [filter])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFilter = (type: string | null) => {
    setAnchorEl(null)
    if (type) setFilter(type)
  }

  return (
    <div className='a-caseFilters'>
      <div className='a-caseFilters__buttons'>
        <button
          onClick={() => setFilter('all')}
          className={`${filter === 'all' ? '-current' : ''}`}
        >
          All
        </button>
        <button
          onClick={() => setFilter('active')}
          className={`${filter === 'active' ? '-current' : ''}`}
        >
          In Progress
        </button>
        <button
          onClick={() => setFilter('completed')}
          className={`${filter === 'completed' ? '-current' : ''}`}
        >
          Completed
        </button>
        <button
          onClick={() => setFilter('offers')}
          className={`${filter === 'offers' ? '-current' : ''}`}
        >
          Offers
        </button>
      </div>

      <div className='a-caseFilters__select'>
        <button type='button' onClick={handleClick}>
          {filterLabel} <FiChevronDown />
        </button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleFilter(null)}
        >
          <MenuItem onClick={() => handleFilter('all')}>All</MenuItem>
          <MenuItem onClick={() => handleFilter('active')}>
            In Progress
          </MenuItem>
          <MenuItem onClick={() => handleFilter('completed')}>
            Completed
          </MenuItem>
          <MenuItem onClick={() => handleFilter('offers')}>Offers</MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default CaseFilters
