import React, { useEffect, useState } from 'react'

import {
  FormControl,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import moment from 'moment'
import { toast } from 'react-toastify'

import StripeCheckoutForm from 'components/organisms/StripeCheckoutForm/StripeCheckoutForm'
import { useCases } from 'context/cases'
import { useNotifications } from 'context/notifications'
import { auth } from 'services/firebase'
import useRegisterTimelineItem from 'services/firebase/hooks/cases/useRegisterTimelineItem'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'
import { formatter } from 'utils/functions'

const useStyles = makeStyles(() => ({
  formControl: {
    backgroundColor: 'transparent',
    minWidth: 120,
    border: 'none',
  },
}))

const MarkAsCompleted = ({
  caseData,
  handleCloseModal,
  handleModalEmptyMode,
}) => {
  const classes = useStyles()

  const { uid } = auth.currentUser
  const { updateCases } = useCases()
  const doUpdateCaseById = useUpdateCaseById()
  const [amount, setAmount] = useState(0)
  const [amountTotal, setAmountTotal] = useState(0)
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const doRegisterTimelineItem = useRegisterTimelineItem()
  const doRegisterUserNotification = useRegisterUserNotification()
  const doAddCaseSystemMessage = useAddCaseSystemMessage()
  const { updateNotificationsStates } = useNotifications()

  useEffect(() => {
    if (!amount) {
      setAmountTotal(0)
    } else {
      setAmountTotal((caseData.rate / 100) * amount)
    }
  }, [amount])

  const handleMarkAsComplete = (data?) => {
    let newAmount = caseData.amount
    if (caseData.paymentType === 'noWinNoFee') {
      if (!amount) {
        toast.error('You need to enter the amount.')
        return
      }
      newAmount = amount
    }

    doUpdateCaseById(caseData.caseId, {
      status: 'completed_by_lawyer',
      amount: newAmount,
      stripe: data || caseData.stripe,
    }).then(async () => {
      /**
       * Lawyer notification
       */
      await doRegisterUserNotification(caseData.senderUid, {
        message: 'You marked the case as complete.',
        type: 'completed',
        link: `/panel/cases/${caseData.caseId}`,
      })

      /**
       * Client notification
       */
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `${
          caseData.senderName || 'The lawyer'
        } marked the case as complete.`,
        type: 'completed',
        link: `/panel/cases/${caseData.caseId}`,
      })

      await doRegisterTimelineItem(caseData.caseId, {
        type: 'completedByLawyer',
        messages: {
          lawyer: 'You marked the case as complete.',
          client: 'The lawyer marked the case as complete.',
        },
        createdAt: new Date(),
      })

      await updateNotificationsStates()

      await doAddCaseSystemMessage(
        caseData.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        {
          [caseData.senderUid]: 'You marked the case as complete.',
          [caseData.receiverUid]: 'The lawyer marked the case as complete.',
        },
        'link'
      )

      toast.success('Case marked as complete!')
      await updateCases({
        caseId: caseData.caseId,
        status: 'completed_by_lawyer',
      })
      handleCloseModal(null, null)
    })
  }

  const handleAcceptAsComplete = async () => {
    if (caseData.status === 'complete') {
      toast.warn('This case has already been completed!')
      await updateCases({
        caseId: caseData.caseId,
        status: 'complete',
      })
      handleCloseModal(null, null)
      return false
    }
    doUpdateCaseById(caseData.caseId, {
      status: 'complete',
      payout: moment().add(7, 'days').toDate(),
    }).then(async () => {
      /**
       * Lawyer notification
       */
      await doRegisterUserNotification(caseData.senderUid, {
        message: `${
          caseData.receiverName || 'The client'
        } accepted the delivery of the case and marked it as finished.`,
        type: 'completed-accepted',
        link: `/panel/cases/${caseData.caseId}`,
      })

      /**
       * Client notification
       */
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `You accepted the delivery of the case and marked it as finished.`,
        type: 'completed-accepted',
        link: `/panel/cases/${caseData.caseId}`,
      })

      await doRegisterUserNotification(caseData.receiverUid, {
        message: `Send a review about ${caseData.senderName || 'the lawyer'}`,
        type: 'review',
        link: `/panel/cases/${caseData.caseId}`,
      })

      await doRegisterTimelineItem(caseData.caseId, {
        type: 'completed',
        messages: {
          lawyer: 'The client accepted the delivery of the case',
          client: 'You accepted the delivery of the case',
        },
        createdAt: new Date(),
      })

      await updateNotificationsStates()

      await doAddCaseSystemMessage(
        caseData.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        {
          [caseData.senderUid]:
            'The client accepted the delivery of the case and marked it as finished.',
          [caseData.receiverUid]:
            'You accepted the delivery of the case and marked it as finished.',
        },
        'message'
      )
      toast.success('Case marked as complete!')

      await updateCases({
        caseId: caseData.caseId,
        status: 'complete',
      })
      handleCloseModal(null, null)
    })
  }

  const handleToCompleteCase = () => {
    if (caseData.paymentType === 'noWinNoFee' && caseData.status === 'active') {
      handleShowPaymentModal(true)
    } else {
      if (caseData.status === 'active') {
        handleMarkAsComplete()
      } else {
        handleAcceptAsComplete()
      }
    }
  }

  const handleShowPaymentModal = (show) => {
    handleModalEmptyMode(show)
    setShowPaymentModal(show)
  }

  return (
    <div className='a-modal__markAsCompleted'>
      {!showPaymentModal ? (
        <>
          {/*Lawyer actions*/}
          {caseData.senderUid === uid && (
            <>
              {caseData.status === 'completed_by_lawyer' ? (
                <>
                  <div className='a-modal__markAsCompleted--markedAsComplete'>
                    This case has already been marked as completed
                  </div>
                  <div className='a-modal__actions'>
                    <button
                      type='button'
                      className='a-modal__actions--submitButton'
                      onClick={handleCloseModal}
                    >
                      Ok
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {/*Mark as Completed*/}
                  {caseData.status !== 'completed_by_lawyer' &&
                    caseData.status !== 'complete' &&
                    caseData.status !== 'canceled' && (
                      <>
                        {caseData.paymentType === 'noWinNoFee' ? (
                          <>
                            <p className='-text'>
                              I have completed the case. Please confirm with my
                              client the amount of settled or recovered funds is
                              correct to close the case & pay Lawyerup&apos;s
                              fee.
                            </p>
                            <FormControl
                              fullWidth
                              variant='outlined'
                              className={classes.formControl}
                            >
                              <TextField
                                label='Amount'
                                variant='outlined'
                                required
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      £
                                    </InputAdornment>
                                  ),
                                }}
                                type='number'
                                onChange={(e) =>
                                  setAmount(parseFloat(e.target.value))
                                }
                              />

                              <small className='-gray'>
                                This total is the amount received from the
                                &apos;settled&apos; or &apos;recovered
                                funds&apos;.
                              </small>
                            </FormControl>
                            {amount > 0 && (
                              <div className='a-modal__markAsCompleted--content-paymentInfo'>
                                <div className='a-modal__markAsCompleted--content-calculation'>
                                  <b>My fee ({caseData.rate}%):</b>
                                  <span>
                                    {formatter.format(
                                      (caseData.rate / 100) * amount
                                    )}
                                  </span>
                                </div>
                                <div className='a-modal__markAsCompleted--content-calculation'>
                                  <b>
                                    I have paid my client ({caseData.clientRate}
                                    %):
                                  </b>
                                  <b>
                                    {formatter.format(
                                      (caseData.clientRate / 100) * amount
                                    )}
                                  </b>
                                </div>
                                <div className='a-modal__markAsCompleted--content-calculation'>
                                  <span>Lawyerup 10% + VAT:</span>
                                  <span>
                                    {formatter.format(amountTotal * 0.12)}
                                  </span>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <p className='-message'>
                            I have completed the case, please send confirmation
                            request to my client and approve payment.
                          </p>
                        )}
                        {caseData.paymentType !== 'noWinNoFee' &&
                          caseData.paymentType !== 'legalAid' && (
                            <small>
                              Note: If the client does not confirm within 7days,
                              funds are automatically paid to you
                            </small>
                          )}
                        <div className='a-modal__actions'>
                          <button
                            type='button'
                            className='a-modal__actions--cancelButton'
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                          <button
                            className='a-modal__actions--submitButton'
                            onClick={handleToCompleteCase}
                          >
                            Confirm
                            {caseData.paymentType === 'noWinNoFee' && ' & Pay'}
                          </button>
                        </div>
                      </>
                    )}
                </>
              )}
            </>
          )}

          {/*Client actions*/}
          {caseData.senderUid !== uid && (
            <>
              {caseData.paymentType === 'noWinNoFee' ? (
                <>
                  <p>
                    Your lawyer has completed the case. If the amount awarded
                    for your case is correct and you have received your
                    settlement click accept to mark the case as complete.
                  </p>
                  <div className='a-modal__markAsCompleted--content-paymentInfo'>
                    <div className='a-modal__markAsCompleted--content-calculation'>
                      <span>Total amount awarded for your case:</span>
                      <span>{formatter.format(caseData.amount)}</span>
                    </div>
                    <div className='a-modal__markAsCompleted--content-calculation'>
                      <b>Less lawyer fee ({caseData.rate}%):</b>
                      <b>
                        {formatter.format(
                          (caseData.rate / 100) * caseData.amount
                        )}
                      </b>
                    </div>
                    <div className='a-modal__markAsCompleted--content-calculation -start'>
                      <b>You received ({caseData.clientRate}%):</b>
                      <b>
                        {formatter.format(
                          (caseData.clientRate / 100) * caseData.amount
                        )}
                      </b>
                    </div>
                    <small>
                      If you have not received payment, please report a dispute.
                    </small>
                  </div>
                </>
              ) : (
                <p className='-message -textCenter'>
                  Your lawyer has completed the case. <br />
                  Do you agree to mark the case as completed?
                </p>
              )}
              <div className='a-modal__actions'>
                <button
                  type='button'
                  className='a-modal__actions--cancelButton'
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  className='a-modal__actions--submitButton'
                  onClick={handleToCompleteCase}
                >
                  Accept
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <StripeCheckoutForm
          handleShowModal={handleShowPaymentModal}
          offerId={caseData.offerId}
          offer={caseData}
          handleOffer={handleMarkAsComplete}
          amount={amountTotal * 0.12}
          type='directCharge'
        />
      )}
    </div>
  )
}

export default MarkAsCompleted
