import React, { useEffect, useState, memo } from 'react'

import moment from 'moment'
import Avatar from 'react-avatar'
import { Link } from 'react-router-dom'

import { ReactComponent as BusinessEmploymentIcon } from 'images/cases/icons/business-employment.svg'
import { ReactComponent as CalendarIcon } from 'images/cases/icons/calendar.svg'
import { ReactComponent as CriminalLawIcon } from 'images/cases/icons/criminal-law.svg'
import { ReactComponent as FamilyIcon } from 'images/cases/icons/family.svg'
import { ReactComponent as NoWinNoFeeIcon } from 'images/cases/icons/no-win-no-fee.svg'
import { ReactComponent as OtherIcon } from 'images/cases/icons/other.svg'
import { ReactComponent as PropertyIcon } from 'images/cases/icons/property.svg'
import { ReactComponent as VisaImmigrationIcon } from 'images/cases/icons/visa-immigration.svg'
import { ReactComponent as WillsProbateIcon } from 'images/cases/icons/wills-probate.svg'
import { expertise } from 'utils/constants'

import './CaseCardHeader.scss'

import { ICaseCardHeader } from './caseCardHeader'

const getIcon = (type) => {
  switch (type) {
    case 'family':
      return <FamilyIcon />
    case 'property':
      return <PropertyIcon />
    case 'noWinNoFee':
      return <NoWinNoFeeIcon />
    case 'willsProbate':
      return <WillsProbateIcon />
    case 'businessEmployment':
      return <BusinessEmploymentIcon />
    case 'visaImmigration':
      return <VisaImmigrationIcon />
    case 'criminalLaw':
      return <CriminalLawIcon />
    default:
      return <OtherIcon />
  }
}

const CaseCardHeader = ({
  caseId,
  name,
  image,
  deadline,
  type,
  status,
}: ICaseCardHeader) => {
  const [daysLeft, setDaysLeft] = useState<number>(0)
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const [typeDescription, setTypeDescription] = useState<string>('Other')

  useEffect(() => {
    const typeItem = expertise.find((exp) => exp.value === type)
    if (typeItem) {
      setTypeDescription(typeItem.label)
    }
  }, [])

  useEffect(() => {
    setDaysLeft(moment(deadline).diff(moment(), 'days'))
  }, [deadline])

  useEffect(() => {
    setTimeLeft(moment(deadline).diff(moment(), 'hours'))
  }, [daysLeft])

  return (
    <div className={`a-caseCardHeader -${type}`}>
      <header className='a-caseCardHeader__header'>
        {status === 'active' && (
          <div className='a-caseCardHeader__header--timeLeft'>
            {daysLeft > 0 ? (
              `${daysLeft} days left`
            ) : (
              <>{timeLeft > 0 ? `${timeLeft} hours left` : 'OVERDUE'}</>
            )}
          </div>
        )}
        <div className='a-caseCardHeader__header--info'>
          <div className='a-caseCardHeader__header--info-icon'>
            {getIcon(type)}
          </div>
          <span>{typeDescription}</span>
        </div>
        {caseId && (
          <div className='a-caseCardHeader__header--link'>
            <Link to={`/panel/cases/${caseId}`}>View Case</Link>
          </div>
        )}
      </header>
      <main className='a-caseCardHeader__main'>
        <div className='a-caseCardHeader__main--image'>
          <Avatar
            size='60'
            maxInitials={2}
            round='100%'
            color='#26395A'
            textSizeRatio={2.5}
            src={image}
            name={name}
          />
        </div>
        <div className='a-caseCardHeader__main--info'>
          <strong>{name}</strong>
          <span>
            <CalendarIcon /> {moment(deadline).format('DD/MM/YYYY')}
          </span>
        </div>
      </main>
    </div>
  )
}

export default memo(CaseCardHeader)
