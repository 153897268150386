import React, { useState } from 'react'

import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as HeaderIcon } from 'images/icon-languages.svg'
import { ReactComponent as EditIcon } from 'images/icon-pencil.svg'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'
import { languages } from 'utils/constants'

import './Languages.scss'

const Languages = ({ handleEditing }) => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.languages)

  const handleEdit = () => {
    if (edit) {
      if (value) {
        doUpdateUserProfile({
          languages: value,
        })
      }
      setEdit(false)
      handleEditing(null)
    } else {
      setEdit(true)
      handleEditing('languages')
    }
  }

  const handleChange = async (event, items) => {
    setValue(items)
  }

  return (
    <div className={`m-languages ${edit ? '-editing' : ''}`}>
      <div className='m-languages__header'>
        <HeaderIcon />
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <CheckIcon /> : <EditIcon />}
        </button>
      </div>
      <div className='m-languages__main'>
        {edit ? (
          <>
            <Autocomplete
              multiple
              id='tags-standard'
              options={languages}
              getOptionLabel={(option) => option.name}
              defaultValue={value}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  placeholder='Search'
                />
              )}
            />
            {/* {languages.map((item) => {
              const isChecked =
                value.findIndex(
                  (selected) => item.initials === selected.initials
                ) > -1
              return (
                <button
                  className='chip'
                  onClick={() => handleChange(item, isChecked)}
                  key={`${item.value}`}
                >
                  <Checkbox color='primary' checked={isChecked} />
                  <span>{item.name}</span>
                </button>
              )
            })}*/}
          </>
        ) : (
          value && value.map((item) => item.name).join(', ')
        )}
      </div>
    </div>
  )
}

export default Languages
