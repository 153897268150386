import React, { useEffect, useState } from 'react'

import OwlCarousel from 'react-owl-carousel2'
import { Link } from 'react-router-dom'

import CaseFilters from 'components/atoms/CaseFilters'
import Loader from 'components/atoms/Loader'
import PanelContainer from 'components/atoms/PanelContainer'
import WhiteBox from 'components/atoms/WhiteBox'
import CaseCard from 'components/molecules/CaseCard'
import PanelSidebar from 'components/molecules/PanelSidebar'
import { useCases } from 'context/cases'
import { ReactComponent as CompletedCasesIcon } from 'images/icons/cases-completed.svg'
import { ReactComponent as InProgressCasesIcon } from 'images/icons/cases-in-progress.svg'
import { ReactComponent as FolderIcon } from 'images/icons/folder.svg'
import { ReactComponent as GlobeIcon } from 'images/icons/globe.svg'
import { ReactComponent as DashboardIcon } from 'images/icons/mobile-dashboard.svg'

import 'react-owl-carousel2/src/owl.carousel.css'
import './ClientDashboard.scss'

const ClientDashboard = () => {
  const { fetchingCases, getCases, cases, casesWithoutFilter } = useCases()
  const [activeCases, setActiveCases] = useState([])
  const [completedCases, setCompletedCases] = useState([])

  useEffect(() => {
    getCases()
  }, [])

  useEffect(() => {
    const currentActiveCases = casesWithoutFilter.filter(
      (item) => item.status === 'active'
    )
    const currentCompletedCases = casesWithoutFilter.filter(
      (item) => item.status === 'complete'
    )
    setActiveCases(currentActiveCases)
    setCompletedCases(currentCompletedCases)
  }, [casesWithoutFilter])

  return (
    <PanelContainer>
      <div className='o-clientDashboard'>
        <div className='o-clientDashboard__container'>
          <div className='o-clientDashboard__main'>
            <div className='o-clientDashboard__main--boxes'>
              <WhiteBox icon={<DashboardIcon />} title='Total Cases'>
                <div className='o-clientDashboard__casesSummary'>
                  <div className='o-clientDashboard__casesSummary--item -total'>
                    <strong>
                      {activeCases.length + completedCases.length}
                    </strong>
                    <span>Cases</span>
                  </div>
                  <div className='o-clientDashboard__casesSummary--item -completed'>
                    <CompletedCasesIcon />
                    <span>{completedCases.length} completed</span>
                  </div>
                  <div className='o-clientDashboard__casesSummary--item -inProgress'>
                    <InProgressCasesIcon />
                    <span>{activeCases.length} in progress</span>
                  </div>
                </div>
              </WhiteBox>
              <WhiteBox title={null} icon={null}>
                <div className='o-clientDashboard__searchService'>
                  <GlobeIcon />
                  <h3>
                    <span>What Service are</span> you looking for?
                  </h3>
                  <strong>Receive offers from Qualified Lawyers</strong>
                  <Link to='/'>Search</Link>
                </div>
              </WhiteBox>
            </div>
            <div className='o-clientDashboard__cases'>
              <WhiteBox
                withOpacity
                icon={<FolderIcon className='o-clientDashboard__cases--icon' />}
                title='Cases'
                actions={<CaseFilters />}
              >
                {fetchingCases ? (
                  <Loader darker />
                ) : cases.length > 0 ? (
                  <div className='o-clientDashboard__cases--carousel'>
                    <OwlCarousel
                      options={{
                        items: 3,
                        margin: 15,
                        stagePadding: 15,
                        responsive: {
                          0: {
                            items: 1,
                          },
                          640: {
                            items: 2,
                          },
                          768: {
                            items: 1,
                          },
                          900: {
                            items: 2,
                          },
                          1200: {
                            items: 3,
                          },
                        },
                      }}
                    >
                      {cases.map((item, key) => (
                        <CaseCard key={`case-${key}`} data={item} />
                      ))}
                    </OwlCarousel>
                  </div>
                ) : (
                  <div className='p-cases__items--empty'>No cases found</div>
                )}
              </WhiteBox>
            </div>
          </div>
          <PanelSidebar isClient />
        </div>
      </div>
    </PanelContainer>
  )
}

export default ClientDashboard
