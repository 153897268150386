import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from 'react'

import { Link } from '@reach/router'
import moment from 'moment'
import Avatar from 'react-avatar'
import { Scrollbars } from 'react-custom-scrollbars'
import { RiContactsBook2Fill, RiMapPinLine } from 'react-icons/ri'
import { withRouter } from 'react-router-dom'
import StarRatings from 'react-star-ratings'

import Loader from 'components/atoms/Loader'
import WhiteBox from 'components/atoms/WhiteBox'
import ChatMessage from 'components/molecules/ChatMessage'
import ChatForm from 'components/organisms/ChatForm'
import ChatReport from 'components/organisms/ChatReport'
import VideoCall from 'components/organisms/VideoCall'
import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as BackIcon } from 'images/icon-back.svg'
import { ReactComponent as IconClose } from 'images/icon-close-modal.svg'
import { ReactComponent as CloseIcon } from 'images/icons/circle-close.svg'
import { ReactComponent as VideoIcon } from 'images/icons/video-call.svg'
import { auth, db } from 'services/firebase'

import './ChatConversation.scss'
import { isMobile } from '../../../utils'

const ChatConversation = ({
  chatId,
  handleCloseChat,
  contactUid,
  handleMessagesRef,
}) => {
  const { uid } = auth.currentUser

  let currentDay = null

  const scrollRef = useRef(null)
  const mobileScrollRef = useRef(null)
  const [messages, setMessages] = useState(null)
  const [showProfile, setShowProfile] = useState(false)
  const [selectedChat, setSelectedChat] = useState(false)
  const [openVideoCall, setOpenVideoCall] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [avatar, setAvatar] = useState(null)
  const [name, setName] = useState(null)
  const [contactInfo, setContactInfo] = useState(null)
  const [status, setStatus] = useState('')
  const [messagesRef, setMessagesRef] = useState(null)
  const [reportMessage, setReportMessage] = useState(false)
  const [hideChatNotice, setHideChatNotice] = useState(
    localStorage.getItem('hideChatNotice')
  )

  const renderMessages = async () => {
    const ref = db.ref(`/chatMessages/${chatId}`).orderByChild('timestamp')
    setMessagesRef(ref)
    await db.ref(`chats/${chatId}/notifications/${uid}`).set(false)
    db.ref(`users/${contactUid}`).once('value', function (snap) {
      const userSnap = snap.val()
      setContactInfo(userSnap)
      setName(userSnap.displayName || `${userSnap.name} ${userSnap.surname}`)
      setAvatar(`${userSnap.photoURL}?size=small`)
    })
    return ref.on('value', function (snap) {
      const messages = snap.val()
      setMessages(messages)
    })
  }

  useEffect(() => {
    renderMessages()
  }, [chatId])

  useEffect(() => {
    handleMessagesRef(messagesRef)
  }, [messagesRef])

  useEffect(() => {
    /**
     * Change user active chat
     **/
    db.ref(`/users/${uid}`).child('activeChat').set(chatId)
    return () => {
      db.ref(`/users/${uid}`).child('activeChat').set('null')
    }
  }, [])

  const handleGetContact = useCallback(async (contactUid) => {
    return db.ref(`users/${contactUid}`).on('value', function (snap) {
      const userSnap = snap.val()
      setStatus(userSnap.status)
    })
  }, [])

  const handleScrollCasesMessages = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom({ behavior: 'smooth' })
    }
    if (mobileScrollRef.current) {
      console.log(
        'mobileScrollRef.current.scrollHeight',
        mobileScrollRef.current.scrollHeight
      )
      mobileScrollRef.current.scrollTop = mobileScrollRef.current.scrollHeight
    }
  }

  useEffect(() => {
    if (chatId) {
      setSelectedChat(chatId)
      // handleChatMessages(chatId)
      handleGetContact(contactUid)
      setTimeout(() => {
        setIsLoading(false)
        handleScrollCasesMessages()
      }, 1000)
    }
  }, [chatId]) // eslint-disable-line

  const handleSendMessage = (message) => {
    db.ref(`chatMessages/${chatId}`)
      .push({
        message,
        sender: uid,
        timestamp: moment().unix(),
      })
      .then(() => {
        db.ref(`users/${contactUid}/activeChat`).once('value', function (snap) {
          const activeChat = snap.val()
          if (activeChat !== chatId) {
            db.ref(`/chats/${chatId}/newMessages`).child(contactUid).set(true)
            db.ref(`/users/${contactUid}/unreadChats`).child(chatId).set(true)
          }
        })
      })
    db.ref(`chats/${chatId}`).child('lastMessage').set(message)
    db.ref(`chats/${chatId}`).child('timestamp').set(moment().unix())
  }

  useEffect(() => {
    setTimeout(() => {
      handleScrollCasesMessages()
    }, 100)
  }, [messages])

  const [user] = useUserInfo()

  const handleHideChatNotice = (param) => {
    setHideChatNotice(param)
    localStorage.setItem('hideChatNotice', param)
  }

  const renderChatMessages = () => {
    return (
      messages &&
      Object.keys(messages).map((messageId) => {
        if (
          currentDay !==
          moment.unix(messages[messageId].timestamp).format('DD/MM/YYYY')
        ) {
          currentDay = moment
            .unix(messages[messageId].timestamp)
            .format('DD/MM/YYYY')

          return (
            <Fragment key={`message-${messageId}`}>
              <div className='o-chatConversation__messages--day'>
                <span>
                  {moment(currentDay).calendar(null, {
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    lastDay: '[Yesterday]',
                    lastWeek: 'DD/MM/YYYY',
                    sameElse: 'DD/MM/YYYY',
                  })}
                </span>
              </div>
              <ChatMessage
                chatId={chatId}
                messageId={messageId}
                {...messages[messageId]}
                handleScroll={handleScrollCasesMessages}
                handleOpenVideo={setOpenVideoCall}
                handleShowReportModal={setReportMessage}
              />
            </Fragment>
          )
        }
        return (
          <ChatMessage
            chatId={chatId}
            messageId={messageId}
            {...messages[messageId]}
            key={`message-${messageId}`}
            handleScroll={handleScrollCasesMessages}
            handleOpenVideo={setOpenVideoCall}
            handleShowReportModal={setReportMessage}
          />
        )
      })
    )
  }

  return (
    <div className='o-chatConversation'>
      <ChatReport
        reportMessage={reportMessage}
        handleShowModal={setReportMessage}
      />
      {selectedChat && !isLoading ? (
        <Fragment>
          <div className='o-chatConversation__header'>
            <button
              onClick={() => handleCloseChat(false)}
              className='o-chatConversation__header--close'
            >
              <BackIcon />
            </button>
            <div className={`o-chatConversation__header--avatar -${status}`}>
              <Avatar
                size='60'
                maxInitials={2}
                round='100%'
                color='#26395A'
                textSizeRatio={2.5}
                src={avatar}
                name={name}
              />
            </div>
            <div className='o-chatConversation__header--info'>
              <strong>{name}</strong>
              <button onClick={() => setShowProfile(true)}>View Profile</button>
            </div>
            <div className='o-chatConversation__header--actions'>
              <button onClick={() => setOpenVideoCall(true)} type='button'>
                <VideoIcon />
              </button>
            </div>
          </div>
          {!hideChatNotice && (
            <div className='o-chatConversation__notice'>
              {user.role === 'lawyer' ? (
                <>
                  <div>
                    <span>
                      Agree a delivery time and price for the legal services
                      your client requires, then send an offer from the{' '}
                      <b>Cases</b> section.
                    </span>
                    <span>
                      Never ask clients for payments directly to you or your law
                      firm.
                    </span>
                  </div>
                  <IconClose
                    onClick={() => {
                      handleHideChatNotice(true)
                    }}
                  />
                </>
              ) : (
                <>
                  <div>
                    <span>
                      Discuss your case with unlimited lawyers for free. Once
                      you&apos;ve agreed your work and delivery date, accept
                      their offer and pay upfront. We hold these funds until you
                      agree the case is complete, then payment is released to
                      your lawyer.
                    </span>
                  </div>
                  <IconClose
                    onClick={() => {
                      handleHideChatNotice(true)
                    }}
                  />
                </>
              )}
            </div>
          )}
          <div className='o-chatConversation__messages'>
            {isMobile ? (
              <div
                ref={mobileScrollRef}
                className='o-chatConversation__messages--scrollArea'
              >
                {renderChatMessages()}
              </div>
            ) : (
              <Scrollbars
                universal
                ref={scrollRef}
                autoHide
                autoHeight
                autoHeightMin='100%'
              >
                {renderChatMessages()}
              </Scrollbars>
            )}
          </div>
          <div className='o-chatConversation__form'>
            <ChatForm chatId={chatId} handleSendMessage={handleSendMessage} />
          </div>
        </Fragment>
      ) : (
        <Loader />
      )}
      {openVideoCall && (
        <VideoCall
          handleOpenVideoCall={setOpenVideoCall}
          chatId={chatId}
          name={name}
          avatar={avatar}
          contactUid={contactUid}
        />
      )}
      <div
        className={`o-chatConversation__profile ${
          showProfile ? '-show' : '-hide'
        }`}
      >
        <button
          onClick={() => setShowProfile(false)}
          className='o-chatConversation__profile--close'
        >
          <CloseIcon />
        </button>
        <div className={`o-chatConversation__profile--avatar -${status}`}>
          <Avatar
            size='140'
            maxInitials={2}
            round='100%'
            color='#26395A'
            textSizeRatio={2.5}
            src={avatar}
            name={name}
          />
        </div>
        <div className='o-chatConversation__profile--name'>{name}</div>

        {contactInfo && (
          <>
            {contactInfo.role === 'lawyer' && (
              <>
                <div className='o-chatConversation__profile--rating'>
                  <StarRatings
                    svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
                    starDimension={'15px'}
                    starEmptyColor='#fff'
                    starRatedColor='#499FD7'
                    rating={contactInfo.rating}
                    numberOfStars={5}
                    starSpacing={'3px'}
                  />
                </div>
                <div className='o-chatConversation__profile--viewProfile'>
                  <Link to={`/profile/${contactInfo.uid}`}>Go to Profile</Link>
                </div>
              </>
            )}

            {contactInfo.address && (
              <div className='o-chatConversation__profile--box'>
                <WhiteBox withOpacity title='Address' icon={<RiMapPinLine />}>
                  <span>{contactInfo.address}</span>
                </WhiteBox>
              </div>
            )}

            {(contactInfo.email || contactInfo.phone) && (
              <div className='o-chatConversation__profile--box'>
                <WhiteBox
                  withOpacity
                  title='Contact'
                  icon={<RiContactsBook2Fill />}
                >
                  <span>{contactInfo.email}</span>
                  <span>{contactInfo.phone}</span>
                </WhiteBox>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default withRouter(ChatConversation)
