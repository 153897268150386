import React, { useState } from 'react'

import useRegisterInviteToTeam from 'services/firebase/hooks/lawyers/useRegisterInviteToTeam'
import './InviteYourFirm.scss'

const InviteYourFirm = () => {
  const doRegisterInviteToTeam = useRegisterInviteToTeam()
  const [email, setEmail] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    doRegisterInviteToTeam(email).then(() => {
      setEmail('')
    })
  }

  return (
    <div className='m-inviteYourFirm'>
      <p>Improve your profile by adding your team</p>
      <form className='m-inviteYourFirm__form' onSubmit={handleSubmit}>
        <input
          required
          type='email'
          value={email}
          placeholder='Email here'
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type='submit'>Send</button>
      </form>
    </div>
  )
}

export default InviteYourFirm
