import React, { useEffect, useState } from 'react'

import CaseFilters from 'components/atoms/CaseFilters'
import Loader from 'components/atoms/Loader'
import PanelContainer from 'components/atoms/PanelContainer'
import WhiteBox from 'components/atoms/WhiteBox'
import CaseCard from 'components/molecules/CaseCard'
import OfferVideo from 'components/molecules/OfferVideo'
import { useCases } from 'context/cases'
import imageVideo from 'images/cases/how-to-send-an-offer.png'
import { ReactComponent as ClockIcon } from 'images/cases/icons/clock.svg'
import { ReactComponent as GavelIcon } from 'images/cases/icons/gavel.svg'
import { ReactComponent as PlayIcon } from 'images/cases/icons/play-video.svg'
import { ReactComponent as ThumbIcon } from 'images/cases/icons/thumb-up.svg'
import { ReactComponent as FolderIcon } from 'images/icons/folder.svg'

import './Cases.scss'

const Cases = () => {
  const { fetchingCases, getCases, cases } = useCases()
  const [offerVideo, setShowOfferVideo] = useState(false)

  useEffect(() => {
    getCases()
  }, [])

  return (
    <PanelContainer>
      <div className='p-cases'>
        <div className='p-cases__container'>
          <div className='p-cases__header'>
            <button
              className='p-cases__header--howToSendAnOffer'
              onClick={() => setShowOfferVideo(true)}
            >
              <img src={imageVideo} alt='' />
              <div className='p-cases__header--howToSendAnOffer-text'>
                <PlayIcon />
                <strong>How to Send an offer</strong>
              </div>
            </button>
            <div className='p-cases__header--help'>
              <div className='p-cases__header--help-item'>
                <ThumbIcon />
                <span>Mark as complete</span>
              </div>
              <div className='p-cases__header--help-item'>
                <GavelIcon />
                <span>Report a dispute</span>
              </div>
              <div className='p-cases__header--help-item'>
                <ClockIcon />
                <span>Extend delivery time</span>
              </div>
            </div>
          </div>
          <div className='p-cases__main'>
            <WhiteBox
              withOpacity
              icon={<FolderIcon className='p-cases__items--icon' />}
              title='Cases'
              actions={<CaseFilters />}
            >
              {fetchingCases ? (
                <Loader darker />
              ) : cases.length > 0 ? (
                cases.map((item, key) => (
                  <CaseCard key={`case-${key}`} data={item} />
                ))
              ) : (
                <div className='p-cases__items--empty'>No cases found</div>
              )}
            </WhiteBox>
          </div>
        </div>
      </div>

      {offerVideo && <OfferVideo handleCloseModal={setShowOfferVideo} />}
    </PanelContainer>
  )
}

export default Cases
