import { useCallback } from 'react'

import { toast } from 'react-toastify'

import { firestore } from 'services/firebase'

const useHandleTeamInvite = () => {
  return useCallback(async (invite, accepted) => {
    firestore.collection('invites_to_team').doc(invite.id).delete()
    if (accepted) {
      firestore
        .collection('users')
        .doc(invite.firmUid)
        .collection('team')
        .doc()
        .set({
          uid: invite.invitedUid,
          email: invite.invitedEmail,
        })
    }
    toast.success(`Invitation ${accepted ? 'accepted' : 'declined'}`)
  }, [])
}

export default useHandleTeamInvite
