import { useCallback } from 'react'

import { firestore } from 'services/firebase'

const useGetCaseTimeline = () => {
  return useCallback(async (caseId) => {
    return firestore
      .collection('cases')
      .doc(caseId)
      .collection('timeline')
      .orderBy('createdAt', 'desc')
      .get()
      .then(async (snap) => {
        if (!snap.empty) {
          return snap.docs.map((doc) => doc.data())
        }
      })
  }, [])
}

export default useGetCaseTimeline
