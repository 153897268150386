import React, { useState } from 'react'

import StarRatings from 'react-star-ratings'
import { toast } from 'react-toastify'

import { auth } from 'services/firebase'
import useRegisterReview from 'services/firebase/hooks/cases/useRegisterReview'
import useRegisterTimelineItem from 'services/firebase/hooks/cases/useRegisterTimelineItem'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'

const ReviewCase = ({ caseData, handleCloseModal }) => {
  const { uid } = auth.currentUser

  const doRegisterUserNotification = useRegisterUserNotification()
  const doUpdateCaseById = useUpdateCaseById()

  const doRegisterReview = useRegisterReview()
  const doRegisterTimelineItem = useRegisterTimelineItem()
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const handleChangeRating = async (newRating) => {
    setRating(newRating)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (rating === 0) {
      toast.error('Rating must be between 1 and 5 stars')
      return
    }
    if (comment.length <= 0) {
      toast.error('The review field needs to be filled')
      return
    }

    await doUpdateCaseById(caseData.caseId, {
      rated: true,
    })

    await doRegisterReview(
      caseData.caseId,
      { comment, rating },
      uid,
      uid === caseData.senderUid ? caseData.receiverUid : caseData.senderUid
    )

    await doRegisterUserNotification(caseData.senderUid, {
      message: `${caseData.receiverName || 'The client'} gave you feedback`,
      type: 'review',
      link: `/panel/cases/${caseData.caseId}`,
    })

    await doRegisterTimelineItem(caseData.caseId, {
      type: 'review',
      messages: {
        lawyer: `${caseData.receiverName || 'The client'} gave you feedback`,
        client: `You gave your feedback about the lawyer`,
      },
      createdAt: new Date(),
    })
    toast.success('Review sent successfully!')
    handleCloseModal(null, null)
  }

  return (
    <div className='a-modal__reviewCase'>
      <form className='a-modal__reviewCase--form' onSubmit={handleSubmit}>
        <header>
          <label>What did you think of this lawyer</label>
        </header>
        <textarea
          required
          placeholder='Tell us about your experience'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className='a-modal__reviewCase--form-stars'>
          <span>Give a note to the lawyer</span>
          <StarRatings
            changeRating={handleChangeRating}
            svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
            starDimension={'24px'}
            starRatedColor='#ead42c'
            starHoverColor='#ead42c'
            rating={rating}
            numberOfStars={5}
            starSpacing={'3px'}
          />
        </div>
        <div className='a-modal__actions'>
          <button
            className='a-modal__actions--cancelButton'
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button className='a-modal__actions--submitButton' type='submit'>
            Review
          </button>
        </div>
      </form>
    </div>
  )
}

export default ReviewCase
