import { useCallback } from 'react'

import { useHistory } from 'react-router-dom'

import { auth, firestore, db } from 'services/firebase'

const useStartChatRoom = () => {
  const history = useHistory()

  return useCallback(async (lawyerUid) => {
    const { uid } = auth.currentUser
    return firestore
      .collection('chats')
      .where('clientUid', '==', uid)
      .where('lawyerUid', '==', lawyerUid)
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          snapshot.docs.map((documentSnapshot) => {
            const chatData = documentSnapshot.data()
            history.push(`/panel/messages/${chatData.chatId}`)
          })
        } else {
          const chatDoc = firestore.collection('chats').doc()
          const chatData = {
            clientUid: uid,
            lawyerUid: lawyerUid,
            chatId: chatDoc.id,
          }
          db.ref(`chats/${chatDoc.id}/members`).set({
            [uid]: true,
            [lawyerUid]: true,
          })
          chatDoc.set(chatData, { merge: true }).then(() => {
            history.push(`/panel/messages/${chatDoc.id}`)
          })
        }
      })
  }, []) // eslint-disable-line
}

export default useStartChatRoom
