import React from 'react'

import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { GiShare } from 'react-icons/gi'

import WhiteBox from 'components/atoms/WhiteBox'

import Generic from '../Generic'

const SocialLinks = () => {
  return (
    <WhiteBox title='Social Links' modifier='-socialLinks' icon={<GiShare />}>
      <main>
        <Generic
          icon={<FaFacebookF />}
          type='facebook'
          title='Facebook'
          emptyMessage='Link not informed'
        />
        <Generic
          icon={<FaLinkedinIn />}
          type='linkedin'
          title='LinkedIn'
          emptyMessage='Link not informed'
        />
        <Generic
          icon={<FaTwitter />}
          type='twitter'
          title='Twitter'
          emptyMessage='Link not informed'
        />
      </main>
    </WhiteBox>
  )
}

export default SocialLinks
