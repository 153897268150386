import React, { useState } from 'react'

import { Checkbox } from '@material-ui/core'
import { FaPoundSign } from 'react-icons/fa'
import { RiCheckFill, RiPencilFill } from 'react-icons/ri'

import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

import './FixedFee.scss'

const FixedFee = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.fixedFee)

  const handleEdit = () => {
    if (edit) {
      doUpdateUserProfile({
        fixedFee: value,
      })
      setEdit(false)
    } else {
      setEdit(true)
    }
  }
  return (
    <div className='m-fixedFee'>
      <div className='m-fixedFee__box'>
        <div className='m-fixedFee__box--icon'>
          <FaPoundSign />
        </div>
        {edit ? (
          <div className='m-fixedFee__form'>
            <span>Accept fixed fee?</span>
            <label onClick={() => setValue(true)}>
              <Checkbox color='primary' checked={value} value={true} />
              <small>Yes</small>
            </label>
            <label onClick={() => setValue(false)}>
              <Checkbox color='primary' checked={!value} value={false} />
              <small>No</small>
            </label>
          </div>
        ) : (
          <span>
            {value ? 'Fixed offer available' : 'Fixed offer not available'}
          </span>
        )}
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <RiCheckFill /> : <RiPencilFill />}
        </button>
      </div>
    </div>
  )
}

export default FixedFee
