import React from 'react'

import firebase from 'firebase'
import moment from 'moment'
import {
  IoCalendar,
  BsClockFill,
  GiRoundStar,
  IoIosFlag,
  IoIosRocket,
} from 'react-icons/all'

import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as CancelIcon } from 'images/cases/icons/cancel.svg'
import { ReactComponent as GavelIcon } from 'images/cases/icons/gavel.svg'
import { ReactComponent as ThumbIcon } from 'images/cases/icons/thumb-up.svg'

import './TimelineItem.scss'

type IProps = {
  data: {
    type: string
    messages: {
      lawyer: string
      client: string
    }
    createdAt: firebase.firestore.Timestamp
  }
}

const TimelineItem = ({ data }: IProps) => {
  const [userInfo] = useUserInfo()

  return (
    <div className={`a-timelineItem`}>
      <div className='a-timelineItem__bullet' />
      <div className={`a-timelineItem__icon -${data.type}`}>
        {data.type === 'started' && <IoIosFlag />}
        {data.type === 'canceled' && <CancelIcon />}
        {data.type === 'completedByLawyer' && <ThumbIcon />}
        {data.type === 'requestExtension' && <BsClockFill />}
        {data.type === 'requestExtensionAccepted' && <IoCalendar />}
        {data.type === 'dispute' && <GavelIcon />}
        {data.type === 'completed' && <IoIosRocket />}
        {data.type === 'review' && <GiRoundStar />}
      </div>
      <div className='a-timelineItem__info'>
        <div className='a-timelineItem__info--text'>
          <small>{moment(data.createdAt.toDate()).format('MMM Do')}</small>
          <p>{data.messages[userInfo.role]}</p>
        </div>
        <div className='a-timelineItem__info--time'>
          {moment(data.createdAt.toDate()).fromNow()}
        </div>
      </div>
    </div>
  )
}

export default TimelineItem
