export const firebaseBaseURL =
  process.env.REACT_APP_ENV === 'development'
    ? 'https://us-central1-lawyerup---dev.cloudfunctions.net/'
    : 'https://us-central1-api-project-619151099122.cloudfunctions.net/'

export const expertise = [
  {
    value: 'family',
    label: 'Family',
    options: [
      {
        placeholder: true,
        value: 'family',
        label: 'Family',
      },
      {
        label: 'Legal Separation',
        value: 'family_legalSeparation',
      },
      {
        label: 'Child Maintenance',
        value: 'family_childMaintenance',
      },
      {
        label: 'Dispute Resolution',
        value: 'family_disputeResolution',
      },
      {
        label: 'Marital Agreements & Property',
        value: 'family_maritalAgreementsProperty',
      },
      {
        label: 'Adoption',
        value: 'family_adoption',
      },
      {
        label: 'Parental Rights',
        value: 'family_parentalRights',
      },
      {
        label: 'Divorce',
        value: 'family_divorce',
      },
      {
        label: 'Domestic Violence',
        value: 'family_domesticViolence',
      },
      {
        label: 'Pre-nuptial Agreements',
        value: 'family_preNuptialAgreements',
      },
      {
        label: 'Child Custody',
        value: 'family_childCustody',
      },
    ],
  },
  {
    label: 'Property',
    value: 'property',
    options: [
      {
        placeholder: true,
        label: 'Property',
        value: 'property',
      },
      {
        label: 'Property Litigation',
        value: 'property_propertyLitigation',
      },
      {
        label: 'Property Dispute',
        value: 'property_propertyDispute',
      },
      {
        label: 'Landlord & Tenant',
        value: 'property_landlordTenant',
      },
      {
        label: 'Mortgage',
        value: 'property_mortgage',
      },
      {
        label: 'Property Lease',
        value: 'property_propertyLease',
      },
      {
        label: 'Property Construction',
        value: 'property_propertyConstruction',
      },
      {
        label: 'Repossession',
        value: 'property_repossession',
      },
      {
        label: 'Insurance Claims',
        value: 'property_insuranceClaims',
      },
      {
        label: 'Buying a Property',
        value: 'property_buyingProperty',
      },
      {
        label: 'Selling a Property',
        value: 'property_sellingProperty',
      },
    ],
  },
  {
    label: 'No win no fee',
    value: 'noWinNoFee',
    options: [
      {
        placeholder: true,
        label: 'No win no fee',
        value: 'noWinNoFee',
      },
      {
        label: 'Accident at Work',
        value: 'noWinNoFee_accidentAtWork',
      },
      {
        label: 'Road Traffic Accident',
        value: 'noWinNoFee_roadTrafficAccident',
      },
      {
        label: 'Slip Trip or Fall',
        value: 'noWinNoFee_slipTripOrFall',
      },
      {
        label: 'Medical Negligence',
        value: 'noWinNoFee_medicalNegligence',
      },
      {
        label: 'Vacation Injury or Incidental',
        value: 'noWinNoFee_vacationInjuryOrIncidental',
      },
      {
        label: 'Pet Injury',
        value: 'noWinNoFee_petInjury',
      },
      {
        label: 'Abuse Claims',
        value: 'noWinNoFee_abuseClaims',
      },
      {
        label: 'Criminal Injuries',
        value: 'noWinNoFee_criminalInjuries',
      },
    ],
  },
  {
    label: 'Wills & Probate',
    value: 'willsProbate',
    options: [
      {
        placeholder: true,
        label: 'Wills & Probate',
        value: 'willsProbate',
      },
      {
        label: 'Create a Will',
        value: 'willsProbate_createWill',
      },
      {
        label: 'Contest a Will',
        value: 'willsProbate_contestWill',
      },
      {
        label: 'Administration of Estate',
        value: 'willsProbate_administrationOfEstate',
      },
      {
        label: 'Power of Attorney',
        value: 'willsProbate_powerOfAttorney',
      },
      {
        label: 'Court of Protection',
        value: 'willsProbate_courtOfProtection',
      },
      {
        label: 'Declaration of Trust',
        value: 'willsProbate_declarationOfTrust',
      },
      {
        label: 'Inheritance Tax & Estate Planning',
        value: 'willsProbate_inheritanceTaxEstatePlanning',
      },
      {
        label: 'Notary Work',
        value: 'willsProbate_notaryWork',
      },
      {
        label: 'Trust & Trustees',
        value: 'willsProbate_trustTrustees',
      },
    ],
  },
  {
    label: 'Business & Employment',
    value: 'businessEmployment',
    options: [
      {
        placeholder: true,
        label: 'Business & Employment',
        value: 'businessEmployment',
      },
      {
        label: 'Commercial Law',
        value: 'businessEmployment_commercialLaw',
      },
      {
        label: 'Business Dispute & Litigation',
        value: 'businessEmployment_businessDisputeLitigation',
      },
      {
        label: 'Franchises',
        value: 'businessEmployment_franchises',
      },
      {
        label: 'Company formation & Dissolution',
        value: 'businessEmployment_companyFormationDissolution',
      },
      {
        label: 'Buying & Selling a Business',
        value: 'businessEmployment_buyingSellingBusiness',
      },
      {
        label: 'Corporations LLC & Partnerships',
        value: 'businessEmployment_corporationsLlcPartnerships',
      },
      {
        label: 'Non-profits / Charities',
        value: 'businessEmployment_nonProfitsCharities',
      },
      {
        label: 'Business Investments',
        value: 'businessEmployment_businessInvestments',
      },
      {
        label: 'VAT',
        value: 'businessEmployment_vat',
      },
      {
        label: 'Employment Law',
        value: 'businessEmployment_employmentLaw',
      },
      {
        label: 'Discrimination',
        value: 'businessEmployment_discrimination',
      },
      {
        label: 'Financial Claim',
        value: 'businessEmployment_financialClaim',
      },
      {
        label: 'Insolvency',
        value: 'businessEmployment_insolvency',
      },
      {
        label: 'Professional Negligence',
        value: 'businessEmployment_professionalNegligence',
      },
      {
        label: 'Unfair dismissal / Wrongful termination',
        value: 'businessEmployment_unfairDismissalWrongfulTermination',
      },
    ],
  },
  {
    label: 'Visa & Immigration',
    value: 'visaImmigration',
    options: [
      {
        placeholder: true,
        label: 'Visa & Immigration',
        value: 'visaImmigration',
      },
      {
        label: 'Asylum',
        value: 'visaImmigration_asylum',
      },
      {
        label: 'EU EEA & Commonwealth',
        value: 'visaImmigration_euEeaCommonwealth',
      },
      {
        label: 'Family Visas',
        value: 'visaImmigration_familyVisas',
      },
      {
        label: 'Immigration Appeals',
        value: 'visaImmigration_immigrationAppeals',
      },
      {
        label: 'Settle in the UK',
        value: 'visaImmigration_settleInTheUK',
      },
      {
        label: 'Sponsor workers or students',
        value: 'visaImmigration_sponsorWorkersOrStudents',
      },
      {
        label: 'Student Visas',
        value: 'visaImmigration_studentVisas',
      },
      {
        label: 'Tourist & Short stay Visas',
        value: 'visaImmigration_touristShortStayVisas',
      },
    ],
  },
  {
    label: 'Criminal Law',
    value: 'criminalLaw',
    options: [
      {
        placeholder: true,
        label: 'Criminal Law',
        value: 'criminalLaw',
      },
      {
        label: 'Serious Offence',
        value: 'criminalLaw_seriousOffence',
      },
      {
        label: 'Minor Offence',
        value: 'criminalLaw_minorOffence',
      },
      {
        label: 'Traffic / Road Violations',
        value: 'criminalLaw_trafficRoadViolations',
      },
      {
        label: 'Falsely Accused',
        value: 'criminalLaw_falselyAccused',
      },
      {
        label: 'Legal Aid',
        value: 'criminalLaw_legalAid',
      },
      {
        label: 'Action against the Police',
        value: 'criminalLaw_actionAgainstThePolice',
      },
      {
        label: 'Claim Compensation',
        value: 'criminalLaw_claimCompensation',
      },
      {
        label: 'Getting bail after an arrest',
        value: 'criminalLaw_gettingBailAfterAnArrest',
      },
      {
        label: 'Appeal a judgement',
        value: 'criminalLaw_appealJudgement',
      },
      {
        label: 'Other',
        value: 'criminalLaw_other',
      },
    ],
  },
  {
    label: 'Other',
    value: 'other',
    options: [
      {
        placeholder: true,
        label: 'Other',
        value: 'other',
      },
      {
        label: 'High profile',
        value: 'other_highProfile',
      },
    ],
  },
]

export const languages = [
  {
    initials: 'ab',
    name: 'Abkhaz',
    nativeName: 'аҧсуа',
  },
  {
    initials: 'aa',
    name: 'Afar',
    nativeName: 'Afaraf',
  },
  {
    initials: 'af',
    name: 'Afrikaans',
    nativeName: 'Afrikaans',
  },
  {
    initials: 'ak',
    name: 'Akan',
    nativeName: 'Akan',
  },
  {
    initials: 'sq',
    name: 'Albanian',
    nativeName: 'Shqip',
  },
  {
    initials: 'am',
    name: 'Amharic',
    nativeName: 'አማርኛ',
  },
  {
    initials: 'ar',
    name: 'Arabic',
    nativeName: 'العربية',
  },
  {
    initials: 'an',
    name: 'Aragonese',
    nativeName: 'Aragonés',
  },
  {
    initials: 'hy',
    name: 'Armenian',
    nativeName: 'Հայերեն',
  },
  {
    initials: 'as',
    name: 'Assamese',
    nativeName: 'অসমীয়া',
  },
  {
    initials: 'av',
    name: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ',
  },
  {
    initials: 'ae',
    name: 'Avestan',
    nativeName: 'avesta',
  },
  {
    initials: 'ay',
    name: 'Aymara',
    nativeName: 'aymar aru',
  },
  {
    initials: 'az',
    name: 'Azerbaijani',
    nativeName: 'azərbaycan dili',
  },
  {
    initials: 'bm',
    name: 'Bambara',
    nativeName: 'bamanankan',
  },
  {
    initials: 'ba',
    name: 'Bashkir',
    nativeName: 'башҡорт теле',
  },
  {
    initials: 'eu',
    name: 'Basque',
    nativeName: 'euskara, euskera',
  },
  {
    initials: 'be',
    name: 'Belarusian',
    nativeName: 'Беларуская',
  },
  {
    initials: 'bn',
    name: 'Bengali',
    nativeName: 'বাংলা',
  },
  {
    initials: 'bh',
    name: 'Bihari',
    nativeName: 'भोजपुरी',
  },
  {
    initials: 'bi',
    name: 'Bislama',
    nativeName: 'Bislama',
  },
  {
    initials: 'bs',
    name: 'Bosnian',
    nativeName: 'bosanski jezik',
  },
  {
    initials: 'br',
    name: 'Breton',
    nativeName: 'brezhoneg',
  },
  {
    initials: 'bg',
    name: 'Bulgarian',
    nativeName: 'български език',
  },
  {
    initials: 'my',
    name: 'Burmese',
    nativeName: 'ဗမာစာ',
  },
  {
    initials: 'ca',
    name: 'Catalan; Valencian',
    nativeName: 'Català',
  },
  {
    initials: 'ch',
    name: 'Chamorro',
    nativeName: 'Chamoru',
  },
  {
    initials: 'ce',
    name: 'Chechen',
    nativeName: 'нохчийн мотт',
  },
  {
    initials: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  {
    initials: 'zh',
    name: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
  },
  {
    initials: 'cv',
    name: 'Chuvash',
    nativeName: 'чӑваш чӗлхи',
  },
  {
    initials: 'kw',
    name: 'Cornish',
    nativeName: 'Kernewek',
  },
  {
    initials: 'co',
    name: 'Corsican',
    nativeName: 'corsu, lingua corsa',
  },
  {
    initials: 'cr',
    name: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
  },
  {
    initials: 'hr',
    name: 'Croatian',
    nativeName: 'hrvatski',
  },
  {
    initials: 'cs',
    name: 'Czech',
    nativeName: 'česky, čeština',
  },
  {
    initials: 'da',
    name: 'Danish',
    nativeName: 'dansk',
  },
  {
    initials: 'dv',
    name: 'Divehi; Dhivehi; Maldivian;',
    nativeName: 'ދިވެހި',
  },
  {
    initials: 'nl',
    name: 'Dutch',
    nativeName: 'Nederlands, Vlaams',
  },
  {
    initials: 'en',
    name: 'English',
    nativeName: 'English',
  },
  {
    initials: 'eo',
    name: 'Esperanto',
    nativeName: 'Esperanto',
  },
  {
    initials: 'et',
    name: 'Estonian',
    nativeName: 'eesti, eesti keel',
  },
  {
    initials: 'ee',
    name: 'Ewe',
    nativeName: 'Eʋegbe',
  },
  {
    initials: 'fo',
    name: 'Faroese',
    nativeName: 'føroyskt',
  },
  {
    initials: 'fj',
    name: 'Fijian',
    nativeName: 'vosa Vakaviti',
  },
  {
    initials: 'fi',
    name: 'Finnish',
    nativeName: 'suomi, suomen kieli',
  },
  {
    initials: 'fr',
    name: 'French',
    nativeName: 'français, langue française',
  },
  {
    initials: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  {
    initials: 'gl',
    name: 'Galician',
    nativeName: 'Galego',
  },
  {
    initials: 'ka',
    name: 'Georgian',
    nativeName: 'ქართული',
  },
  {
    initials: 'de',
    name: 'German',
    nativeName: 'Deutsch',
  },
  {
    initials: 'el',
    name: 'Greek, Modern',
    nativeName: 'Ελληνικά',
  },
  {
    initials: 'gn',
    name: 'Guaraní',
    nativeName: 'Avañeẽ',
  },
  {
    initials: 'gu',
    name: 'Gujarati',
    nativeName: 'ગુજરાતી',
  },
  {
    initials: 'ht',
    name: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen',
  },
  {
    initials: 'ha',
    name: 'Hausa',
    nativeName: 'Hausa, هَوُسَ',
  },
  {
    initials: 'he',
    name: 'Hebrew (modern)',
    nativeName: 'עברית',
  },
  {
    initials: 'hz',
    name: 'Herero',
    nativeName: 'Otjiherero',
  },
  {
    initials: 'hi',
    name: 'Hindi',
    nativeName: 'हिन्दी, हिंदी',
  },
  {
    initials: 'ho',
    name: 'Hiri Motu',
    nativeName: 'Hiri Motu',
  },
  {
    initials: 'hu',
    name: 'Hungarian',
    nativeName: 'Magyar',
  },
  {
    initials: 'ia',
    name: 'Interlingua',
    nativeName: 'Interlingua',
  },
  {
    initials: 'id',
    name: 'Indonesian',
    nativeName: 'Bahasa Indonesia',
  },
  {
    initials: 'ie',
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  {
    initials: 'ga',
    name: 'Irish',
    nativeName: 'Gaeilge',
  },
  {
    initials: 'ig',
    name: 'Igbo',
    nativeName: 'Asụsụ Igbo',
  },
  {
    initials: 'ik',
    name: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun',
  },
  {
    initials: 'io',
    name: 'Ido',
    nativeName: 'Ido',
  },
  {
    initials: 'is',
    name: 'Icelandic',
    nativeName: 'Íslenska',
  },
  {
    initials: 'it',
    name: 'Italian',
    nativeName: 'Italiano',
  },
  {
    initials: 'iu',
    name: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ',
  },
  {
    initials: 'ja',
    name: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)',
  },
  {
    initials: 'jv',
    name: 'Javanese',
    nativeName: 'basa Jawa',
  },
  {
    initials: 'kl',
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  {
    initials: 'kn',
    name: 'Kannada',
    nativeName: 'ಕನ್ನಡ',
  },
  {
    initials: 'kr',
    name: 'Kanuri',
    nativeName: 'Kanuri',
  },
  {
    initials: 'ks',
    name: 'Kashmiri',
    nativeName: 'कश्मीरी, كشميري‎',
  },
  {
    initials: 'kk',
    name: 'Kazakh',
    nativeName: 'Қазақ тілі',
  },
  {
    initials: 'km',
    name: 'Khmer',
    nativeName: 'ភាសាខ្មែរ',
  },
  {
    initials: 'ki',
    name: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ',
  },
  {
    initials: 'rw',
    name: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda',
  },
  {
    initials: 'ky',
    name: 'Kirghiz, Kyrgyz',
    nativeName: 'кыргыз тили',
  },
  {
    initials: 'kv',
    name: 'Komi',
    nativeName: 'коми кыв',
  },
  {
    initials: 'kg',
    name: 'Kongo',
    nativeName: 'KiKongo',
  },
  {
    initials: 'ko',
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  {
    initials: 'ku',
    name: 'Kurdish',
    nativeName: 'Kurdî, كوردی‎',
  },
  {
    initials: 'kj',
    name: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama',
  },
  {
    initials: 'la',
    name: 'Latin',
    nativeName: 'latine, lingua latina',
  },
  {
    initials: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  {
    initials: 'lg',
    name: 'Luganda',
    nativeName: 'Luganda',
  },
  {
    initials: 'li',
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  {
    initials: 'ln',
    name: 'Lingala',
    nativeName: 'Lingála',
  },
  {
    initials: 'lo',
    name: 'Lao',
    nativeName: 'ພາສາລາວ',
  },
  {
    initials: 'lt',
    name: 'Lithuanian',
    nativeName: 'lietuvių kalba',
  },
  {
    initials: 'lu',
    name: 'Luba-Katanga',
    nativeName: '',
  },
  {
    initials: 'lv',
    name: 'Latvian',
    nativeName: 'latviešu valoda',
  },
  {
    initials: 'gv',
    name: 'Manx',
    nativeName: 'Gaelg, Gailck',
  },
  {
    initials: 'mk',
    name: 'Macedonian',
    nativeName: 'македонски јазик',
  },
  {
    initials: 'mg',
    name: 'Malagasy',
    nativeName: 'Malagasy fiteny',
  },
  {
    initials: 'ms',
    name: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو‎',
  },
  {
    initials: 'ml',
    name: 'Malayalam',
    nativeName: 'മലയാളം',
  },
  {
    initials: 'mt',
    name: 'Maltese',
    nativeName: 'Malti',
  },
  {
    initials: 'mi',
    name: 'Māori',
    nativeName: 'te reo Māori',
  },
  {
    initials: 'mr',
    name: 'Marathi (Marāṭhī)',
    nativeName: 'मराठी',
  },
  {
    initials: 'mh',
    name: 'Marshallese',
    nativeName: 'Kajin M̧ajeļ',
  },
  {
    initials: 'mn',
    name: 'Mongolian',
    nativeName: 'монгол',
  },
  {
    initials: 'na',
    name: 'Nauru',
    nativeName: 'Ekakairũ Naoero',
  },
  {
    initials: 'nv',
    name: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí',
  },
  {
    initials: 'nb',
    name: 'Norwegian Bokmål',
    nativeName: 'Norsk bokmål',
  },
  {
    initials: 'nd',
    name: 'North Ndebele',
    nativeName: 'isiNdebele',
  },
  {
    initials: 'ne',
    name: 'Nepali',
    nativeName: 'नेपाली',
  },
  {
    initials: 'ng',
    name: 'Ndonga',
    nativeName: 'Owambo',
  },
  {
    initials: 'nn',
    name: 'Norwegian Nynorsk',
    nativeName: 'Norsk nynorsk',
  },
  {
    initials: 'no',
    name: 'Norwegian',
    nativeName: 'Norsk',
  },
  {
    initials: 'ii',
    name: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop',
  },
  {
    initials: 'nr',
    name: 'South Ndebele',
    nativeName: 'isiNdebele',
  },
  {
    initials: 'oc',
    name: 'Occitan',
    nativeName: 'Occitan',
  },
  {
    initials: 'oj',
    name: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  },
  {
    initials: 'cu',
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  {
    initials: 'om',
    name: 'Oromo',
    nativeName: 'Afaan Oromoo',
  },
  {
    initials: 'or',
    name: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ',
  },
  {
    initials: 'os',
    name: 'Ossetian, Ossetic',
    nativeName: 'ирон æвзаг',
  },
  {
    initials: 'pa',
    name: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
  },
  {
    initials: 'pi',
    name: 'Pāli',
    nativeName: 'पाऴि',
  },
  {
    initials: 'fa',
    name: 'Persian',
    nativeName: 'فارسی',
  },
  {
    initials: 'pl',
    name: 'Polish',
    nativeName: 'polski',
  },
  {
    initials: 'ps',
    name: 'Pashto, Pushto',
    nativeName: 'پښتو',
  },
  {
    initials: 'pt',
    name: 'Portuguese',
    nativeName: 'Português',
  },
  {
    initials: 'qu',
    name: 'Quechua',
    nativeName: 'Runa Simi, Kichwa',
  },
  {
    initials: 'rm',
    name: 'Romansh',
    nativeName: 'rumantsch grischun',
  },
  {
    initials: 'rn',
    name: 'Kirundi',
    nativeName: 'kiRundi',
  },
  {
    initials: 'ro',
    name: 'Romanian, Moldavian, Moldovan',
    nativeName: 'română',
  },
  {
    initials: 'ru',
    name: 'Russian',
    nativeName: 'русский язык',
  },
  {
    initials: 'sa',
    name: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्',
  },
  {
    initials: 'sc',
    name: 'Sardinian',
    nativeName: 'sardu',
  },
  {
    initials: 'sd',
    name: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎',
  },
  {
    initials: 'se',
    name: 'Northern Sami',
    nativeName: 'Davvisámegiella',
  },
  {
    initials: 'sm',
    name: 'Samoan',
    nativeName: 'gagana faa Samoa',
  },
  {
    initials: 'sg',
    name: 'Sango',
    nativeName: 'yângâ tî sängö',
  },
  {
    initials: 'sr',
    name: 'Serbian',
    nativeName: 'српски језик',
  },
  {
    initials: 'gd',
    name: 'Scottish Gaelic; Gaelic',
    nativeName: 'Gàidhlig',
  },
  {
    initials: 'sn',
    name: 'Shona',
    nativeName: 'chiShona',
  },
  {
    initials: 'si',
    name: 'Sinhala, Sinhalese',
    nativeName: 'සිංහල',
  },
  {
    initials: 'sk',
    name: 'Slovak',
    nativeName: 'slovenčina',
  },
  {
    initials: 'sl',
    name: 'Slovene',
    nativeName: 'slovenščina',
  },
  {
    initials: 'so',
    name: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali',
  },
  {
    initials: 'st',
    name: 'Southern Sotho',
    nativeName: 'Sesotho',
  },
  {
    initials: 'es',
    name: 'Spanish; Castilian',
    nativeName: 'español, castellano',
  },
  {
    initials: 'su',
    name: 'Sundanese',
    nativeName: 'Basa Sunda',
  },
  {
    initials: 'sw',
    name: 'Swahili',
    nativeName: 'Kiswahili',
  },
  {
    initials: 'ss',
    name: 'Swati',
    nativeName: 'SiSwati',
  },
  {
    initials: 'sv',
    name: 'Swedish',
    nativeName: 'svenska',
  },
  {
    initials: 'ta',
    name: 'Tamil',
    nativeName: 'தமிழ்',
  },
  {
    initials: 'te',
    name: 'Telugu',
    nativeName: 'తెలుగు',
  },
  {
    initials: 'tg',
    name: 'Tajik',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
  },
  {
    initials: 'th',
    name: 'Thai',
    nativeName: 'ไทย',
  },
  {
    initials: 'ti',
    name: 'Tigrinya',
    nativeName: 'ትግርኛ',
  },
  {
    initials: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  {
    initials: 'tk',
    name: 'Turkmen',
    nativeName: 'Türkmen, Түркмен',
  },
  {
    initials: 'tl',
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
  },
  {
    initials: 'tn',
    name: 'Tswana',
    nativeName: 'Setswana',
  },
  {
    initials: 'to',
    name: 'Tonga (Tonga Islands)',
    nativeName: 'faka Tonga',
  },
  {
    initials: 'tr',
    name: 'Turkish',
    nativeName: 'Türkçe',
  },
  {
    initials: 'ts',
    name: 'Tsonga',
    nativeName: 'Xitsonga',
  },
  {
    initials: 'tt',
    name: 'Tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎',
  },
  {
    initials: 'tw',
    name: 'Twi',
    nativeName: 'Twi',
  },
  {
    initials: 'ty',
    name: 'Tahitian',
    nativeName: 'Reo Tahiti',
  },
  {
    initials: 'ug',
    name: 'Uighur, Uyghur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
  },
  {
    initials: 'uk',
    name: 'Ukrainian',
    nativeName: 'українська',
  },
  {
    initials: 'ur',
    name: 'Urdu',
    nativeName: 'اردو',
  },
  {
    initials: 'uz',
    name: 'Uzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎',
  },
  {
    initials: 've',
    name: 'Venda',
    nativeName: 'Tshivenḓa',
  },
  {
    initials: 'vi',
    name: 'Vietnamese',
    nativeName: 'Tiếng Việt',
  },
  {
    initials: 'vo',
    name: 'Volapük',
    nativeName: 'Volapük',
  },
  {
    initials: 'wa',
    name: 'Walloon',
    nativeName: 'Walon',
  },
  {
    initials: 'cy',
    name: 'Welsh',
    nativeName: 'Cymraeg',
  },
  {
    initials: 'wo',
    name: 'Wolof',
    nativeName: 'Wollof',
  },
  {
    initials: 'fy',
    name: 'Western Frisian',
    nativeName: 'Frysk',
  },
  {
    initials: 'xh',
    name: 'Xhosa',
    nativeName: 'isiXhosa',
  },
  {
    initials: 'yi',
    name: 'Yiddish',
    nativeName: 'ייִדיש',
  },
  {
    initials: 'yo',
    name: 'Yoruba',
    nativeName: 'Yorùbá',
  },
  {
    initials: 'za',
    name: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh',
  },
]
