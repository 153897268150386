import React, { useEffect, useState } from 'react'

import CaseCardContent from 'components/atoms/CaseCard/Content'
import CaseCardHeader from 'components/atoms/CaseCard/Header'
import emptyAvatar from 'images/empty-avatar.jpg'
import { auth } from 'services/firebase'

import './CaseCard.scss'

const CaseCard = ({ data }) => {
  const { uid } = auth.currentUser
  const [image, setImage] = useState(emptyAvatar)
  const [name, setName] = useState('')

  useEffect(() => {
    if (uid === data.senderUid) {
      setImage(data.receiverImage)
      setName(data.receiverName)
    } else {
      setImage(data.senderImage)
      setName(data.senderName)
    }
  }, [])
  return (
    <div className='m-caseCard'>
      <CaseCardHeader
        caseId={data.caseId}
        status={data.status}
        name={name}
        image={image}
        type={data.for}
        deadline={data.deadline.toDate()}
      />
      <CaseCardContent caseData={data} />
    </div>
  )
}

export default CaseCard
