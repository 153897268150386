import React from 'react'

import { Link } from 'react-router-dom'
import StarRatings from 'react-star-ratings'

import LawyerStatus from 'components/atoms/LawyerStatus/LawyerStatus'
import emptyAvatar from 'images/empty-avatar.jpg'
import BadgeIcon from 'images/icon-badge.png'
import CheckIcon from 'images/icon-check.png'
import MarkerIcon from 'images/icon-marker.png'
import TranslateIcon from 'images/icon-translate.png'
import { ReactComponent as VerifiedIcon } from 'images/icon-verified.svg'
import { expertise } from 'utils/constants'

import './LawyerCard.scss'

const LawyerCard = ({ data }) => {
  return (
    <Link
      to={`/profile/${data.validatedAccount ? '' : 'not-validated/'}${
        data.uid
      }`}
      className='m-lawyerCard'
    >
      <div className='m-lawyerCard__content'>
        <div className='m-lawyerCard__image'>
          <img
            src={data.photoURL || emptyAvatar}
            alt={`${data.name} ${data.surname}`}
          />
        </div>
        <div className='m-lawyerCard__info'>
          <div className='m-lawyerCard__info--title'>
            <strong>
              {data.name} {data.surname}
            </strong>
            {data.validatedAccount && <VerifiedIcon />}
            <LawyerStatus
              status={data.onlineNow === true ? 'online' : 'offline'}
            />
          </div>
          {expertise && (
            <div className='m-lawyerCard__info--segment'>
              <span>
                {data.expertise &&
                  data.expertise.map((item, key) => {
                    return `${
                      expertise.find((exp) => exp.value === item).label
                    }${data.expertise.length - 1 > key ? ', ' : ''}`
                  })}
              </span>
            </div>
          )}
          <div className='m-lawyerCard__info--rating'>
            <StarRatings
              svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
              starDimension={'18px'}
              starRatedColor='#ead42b'
              rating={data.rating || 0}
              numberOfStars={5}
              starSpacing={'3px'}
            />
            <span>{data.rating || ''}</span>
          </div>
          <div className='m-lawyerCard__info--list'>
            <div>
              <div className='m-lawyerCard__info--list-icon'>
                <img src={MarkerIcon} alt='' />
              </div>
              <b>{`${data.distance} Miles from you`}</b>
            </div>
            {data.practisingSince && (
              <div>
                <div className='m-lawyerCard__info--list-icon'>
                  <img src={BadgeIcon} alt='' />
                </div>

                <span>{data.practisingSince} Years Experience</span>
              </div>
            )}
            <div>
              <div className='m-lawyerCard__info--list-icon'>
                <img src={TranslateIcon} alt='' />
              </div>
              <span>
                {data.languages &&
                  data.languages.map(
                    (lang, key) =>
                      `${lang.name}${
                        data.languages.length - 1 > key ? ', ' : ''
                      }`
                  )}
              </span>
            </div>
          </div>
        </div>
      </div>

      {(data.fixedFee || data.hourPrice) && (
        <div className='m-lawyerCard__extraInfo'>
          {data.fixedFee && (
            <>
              <div className='m-lawyerCard__extraInfo--fee'>
                <div className='m-lawyerCard__extraInfo--feeIcon'>
                  <img src={CheckIcon} alt='Check' />
                </div>
                <span>
                  I offer Fixed <br />
                  Fee services
                </span>
              </div>
              <div className='m-lawyerCard__extraInfo--divider'>or</div>
            </>
          )}
          {data.hourPrice && (
            <div className='m-lawyerCard__extraInfo--hourRate'>
              <span>Hourly Rate</span>
              <strong>£{data.hourPrice}</strong>
            </div>
          )}
        </div>
      )}
    </Link>
  )
}

export default LawyerCard
