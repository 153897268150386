import { useCallback } from 'react'

import { db, auth, firestore } from 'services/firebase'

const useChangeStatus = () => {
  return useCallback(async () => {
    const { uid } = auth.currentUser
    const userStatusDatabaseRef = db.ref(`/users/${uid}`).child('status')
    const userActiveChat = db.ref(`/users/${uid}`).child('activeChat')

    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        firestore.collection('users').doc(uid).update({ onlineNow: false })
        userStatusDatabaseRef.set('away')
        userActiveChat.set('null')
      } else {
        userStatusDatabaseRef.set('online')
        firestore.collection('users').doc(uid).update({ onlineNow: true })
      }
    }

    db.ref('.info/connected').on('value', function (snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        return
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.

      firestore.collection('users').doc(uid).update({ onlineNow: false })
      userStatusDatabaseRef
        .onDisconnect()
        .set('offline')
        .then(function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.set('online')
          firestore.collection('users').doc(uid).update({ onlineNow: true })
        })
      userActiveChat.set('null')
    })
  }, [])
}

export default useChangeStatus
