import React, { useState } from 'react'

import Button from '@material-ui/core/Button'

import BarclaysLogo from 'images/barclays-logo.png'
import Shareen from 'images/shareen.jpeg'
import Avatar from 'images/temp/carousel-avatar.png'
import GoogleLogo from 'images/temp/google-logo.png'
import './SponsorsCarousel.scss'

const dummyDataCarousel = [
  {
    text: "Working at Google in London - I face many new challenges that haven't been overcome before.. and simplifying them is key. Supporting Lawyerup has been great. I think it is so important to help people choose a lawyer who is experienced in exactly what you need. Of course price, location and client feedback are great too, and all of this is what I think makes Lawyerup brilliant.",
    avatar: Avatar,
    authorName: 'Emma McNicol',
    authorRole: 'Associate Legal Counsel',
    authorBusinessImage: GoogleLogo,
  },
  {
    text: 'Lawyerup is an asset to law growth and undoubtedly have my support. By helping clients and partners in the legal industry it is exactly what the future of legal needs, our Barclays law partners would all agree. We will be using Lawyerup for all our teams legal jobs.',
    avatar: Shareen,
    authorName: 'Shareen Qureshi',
    authorRole: 'LawTech Director',
    authorBusinessImage: BarclaysLogo,
  },
]

const SponsorsCarousel = () => {
  const [activeCarousel, setActiveCarousel] = useState(0)
  const [carouselData] = useState(dummyDataCarousel)

  const handleChangeItem = (active) => {
    setActiveCarousel(active)
  }

  const pager = () => {
    const items = []
    for (let count = 0; count < carouselData.length; count++) {
      items.push(
        <Button
          key={`pagerButton-${count}`}
          onClick={() => handleChangeItem(count)}
          className={`${count === activeCarousel ? '-active' : ''}`}
        />
      )
    }
    return items
  }

  return (
    <div className='o-sponsorsCarousel'>
      {carouselData.map((item, key) => {
        return (
          <div
            key={`carouselItem${key}`}
            className={`o-sponsorsCarousel__item ${
              activeCarousel === key ? '-active' : ''
            }`}
          >
            <div className='o-sponsorsCarousel__item--content'>
              <div className='o-sponsorsCarousel__text'>{item.text}</div>
              <div className='o-sponsorsCarousel__info'>
                <div className='o-sponsorsCarousel__author'>
                  <div className='o-sponsorsCarousel__authorAvatar'>
                    <img src={item.avatar} alt='' />
                  </div>
                  <div className='o-sponsorsCarousel__authorInfo'>
                    <b>{item.authorName}</b>
                    <span>{item.authorRole}</span>
                    <div className='o-sponsorsCarousel__authorInfo--business'>
                      <img src={item.authorBusinessImage} alt='' />
                    </div>
                  </div>
                  <div className='o-sponsorsCarousel__authorBusiness'>
                    <img src={item.authorBusinessImage} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}

      <div className='o-sponsorsCarousel__pager'>{pager()}</div>
    </div>
  )
}

export default SponsorsCarousel
