import React, { useState } from 'react'

import axios from 'axios'

import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as IconClose } from 'images/icon-close-modal.svg'
import { ReactComponent as IconChevronRight } from 'images/right-thin-chevron.svg'
import { firebaseBaseURL } from 'utils/constants'

import './ChatReport.scss'
import Loader from '../../atoms/Loader'

const ChatReport = ({ reportMessage, handleShowModal }) => {
  const [user] = useUserInfo()
  const [isLoading, setIsLoading] = useState(false)
  const [reportedStatus, setReportedStatus] = useState(false)
  const [showOtherInput, setShowOtherInput] = useState(false)
  const [anotherReason, setAnotherReason] = useState('')

  const handleReport = async (message) => {
    setIsLoading(true)
    const problem = message ? message : anotherReason

    await axios
      .post(`${firebaseBaseURL}reportChatMessage`, {
        ...reportMessage,
        role: user.role,
        problem,
      })
      .catch(() => {
        setIsLoading(false)
      })

    setIsLoading(false)
    setAnotherReason('')
    setShowOtherInput(false)
    setReportedStatus(true)
  }

  return (
    reportMessage && (
      <div className='modalReport'>
        <div className='box'>
          <div className='head'>
            <span>Report</span>
            <IconClose
              className='close'
              onClick={() => {
                handleShowModal(false)
                setReportedStatus(false)
              }}
            />
          </div>
          <div className='body'>
            {isLoading && (
              <div className='modalReport__loader'>
                <Loader />
              </div>
            )}
            {showOtherInput ? (
              <>
                <div className='reported'>
                  <div className='title'>Another Reason</div>
                  <div className='message'>
                    <form>
                      <textarea
                        placeholder='What would you like to report?'
                        onChange={(e) => {
                          setAnotherReason(e.target.value)
                        }}
                      />
                      <button
                        type='button'
                        disabled={anotherReason.length === 0}
                        onClick={() => handleReport()}
                      >
                        Report
                      </button>
                    </form>
                  </div>
                </div>
              </>
            ) : !reportedStatus ? (
              <>
                <div className='reason'>
                  Why are you reporting this message?
                </div>
                <ul className='options'>
                  <li onClick={() => handleReport("It's spam")}>
                    <span>It&apos;s spam</span> <IconChevronRight />
                  </li>
                  <li onClick={() => handleReport("It's inappropriate")}>
                    <span>It&apos;s inappropriate</span> <IconChevronRight />
                  </li>
                  <li
                    onClick={() =>
                      handleReport('Asked to pay directly via bank')
                    }
                  >
                    <span>Asked to pay directly via bank</span>{' '}
                    <IconChevronRight />
                  </li>
                  <li onClick={() => setShowOtherInput(true)}>
                    <span>Another reason...</span> <IconChevronRight />
                  </li>
                </ul>
              </>
            ) : (
              <div className='reported'>
                <div className='title'>Message Reported!</div>
                <div className='message'>
                  Thanks for your collaboration, we&apos;ll look for
                  inappropriate activities.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default ChatReport
