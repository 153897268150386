import React, { useRef, useEffect, useState } from 'react'

import { styled } from '@material-ui/core/styles'
import { Track } from 'twilio-video'

import useMediaStreamTrack from 'hooks/useMediaStreamTrack/useMediaStreamTrack'
import useVideoTrackDimensions from 'hooks/useVideoTrackDimensions/useVideoTrackDimensions'
import { IVideoTrack } from 'types'

const Video = styled('video')({
  height: 'auto',
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  margin: 'auto',
})

interface VideoTrackProps {
  track: IVideoTrack
  isLocal?: boolean
  priority: Track.Priority | null
}

interface SizeProps {
  width: number | undefined
  height: number | undefined
}

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<SizeProps>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export default function VideoTrack({
  track,
  isLocal,
  priority,
}: VideoTrackProps) {
  const windowSize = useWindowSize()
  const ref = useRef<HTMLVideoElement>(null!)
  const mediaStreamTrack = useMediaStreamTrack(track)
  const dimensions = useVideoTrackDimensions(track)
  // @ts-ignore
  const isPortrait = (dimensions.height ?? 0) > (dimensions.width ?? 0)

  useEffect(() => {
    const el = ref.current
    el.muted = true
    if (track.setPriority && priority) {
      track.setPriority(priority)
    }
    track.attach(el)
    return () => {
      track.detach(el)
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        //@ts-ignore
        track.setPriority(null)
      }
    }
  }, [track, priority])

  // The local video track is mirrored if it is not facing the environment.
  // @ts-ignore
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== 'environment'
  const style = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
    [windowSize.width !== undefined &&
    windowSize.height !== undefined &&
    windowSize.width > windowSize.height
      ? 'minHeight'
      : 'minWidth']: '100%',
    objectFit:
      isPortrait || track.name.includes('screen')
        ? ('contain' as const)
        : ('cover' as const),
  }

  return <Video ref={ref} style={style} />
}
