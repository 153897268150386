import React from 'react'

import ClientDashboard from 'components/organisms/ClientDashboard'
import LawyerDashboard from 'components/organisms/LawyerDashboard'
import useUserInfo from 'hooks/useUserInfo'

const Dashboard = () => {
  const [user] = useUserInfo()
  return user.role === 'lawyer' ? <LawyerDashboard /> : <ClientDashboard />
}

export default Dashboard
