import React from 'react'

import { Button, FormControl, makeStyles } from '@material-ui/core'

import Section from 'components/atoms/Section'
import Title from 'components/atoms/Title'
import whatIsLawyerup from 'images/what-is-lawyerup.gif'

import './WhatIsLawyerup.scss'

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'transparent',
    margin: theme.spacing(2, 0),
    minWidth: 120,
    border: 'none',
  },
  button: {
    marginTop: 30,
    padding: theme.spacing(2, 3),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
}))

const WhatIsLawyerup = () => {
  const classes = useStyles()
  return (
    <Section className='o-whatIsLawyerup'>
      <div className='o-whatIsLawyerup__header'>
        <Title>What is Lawyerup?</Title>
      </div>
      <div className='o-whatIsLawyerup__content'>
        <div className='o-whatIsLawyerup__content--text'>
          <p>
            <strong>Lawyerup</strong> is a registry of lawyers that you can
            contact instantly and safely pay for their legal services.
          </p>
          <div className='o-whatIsLawyerup__content--text-button'>
            <FormControl variant='outlined' className={classes.formControl}>
              <Button
                className={classes.button}
                size='large'
                variant='contained'
                color='primary'
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
              >
                Search
              </Button>
            </FormControl>
          </div>
        </div>
        <div className='o-whatIsLawyerup__content--image'>
          <img src={whatIsLawyerup} alt='' />
          <div className='o-whatIsLawyerup__content--image-button'>
            <FormControl variant='outlined' className={classes.formControl}>
              <Button
                className={classes.button}
                size='large'
                variant='contained'
                color='primary'
                onClick={() => {
                  window.scrollTo(0, 0)
                }}
              >
                Search
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default WhatIsLawyerup
