import React, { useState } from 'react'

import MomentUtils from '@date-io/moment'
import { makeStyles } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import { toast } from 'react-toastify'

import { useCases } from 'context/cases'
import { useNotifications } from 'context/notifications'
import { auth } from 'services/firebase'
import useRegisterTimelineItem from 'services/firebase/hooks/cases/useRegisterTimelineItem'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
    minWidth: 120,
    border: 'none',
  },
  date: {
    marginRight: 0,
    outline: 'none',
  },
}))

const RequestExtension = ({ caseData, handleCloseModal }) => {
  const { uid } = auth.currentUser
  const classes = useStyles()

  const { updateCases } = useCases()

  const { updateNotificationsStates } = useNotifications()
  const doUpdateCaseById = useUpdateCaseById()
  const doAddCaseSystemMessage = useAddCaseSystemMessage()
  const doRegisterUserNotification = useRegisterUserNotification()
  const doRegisterTimelineItem = useRegisterTimelineItem()

  const [newDate, setNewDate] = useState<any>(new Date())

  console.log('caseData', caseData)

  const handleRequestExtension = () => {
    doUpdateCaseById(caseData.caseId, {
      newDeadline: newDate.toDate(),
    }).then(async () => {
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `${
          caseData.senderName || 'The lawyer'
        } asked for more time to complete the case.`,
        type: 'extend-time',
        link: `/panel/cases/${caseData.caseId}`,
      })

      await doAddCaseSystemMessage(
        caseData.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        'The lawyer asked for more time to complete the case.',
        'link'
      )

      await doRegisterTimelineItem(caseData.caseId, {
        type: 'requestExtension',
        messages: {
          lawyer: `You asked until ${moment(newDate.toDate()).format(
            'DD/MM/YYYY'
          )} to complete the case`,
          client: `The lawyer asked until ${moment(newDate.toDate()).format(
            'DD/MM/YYYY'
          )} to complete the case`,
        },
        createdAt: new Date(),
      })

      toast.success('Time extension requested!')
      handleCloseModal(null, null)
    })
  }

  const handleAcceptRequestExtension = () => {
    doUpdateCaseById(caseData.caseId, {
      newDeadline: null,
      deadline: caseData.newDeadline,
    }).then(async () => {
      /**
       * Lawyer notification
       */
      await doRegisterUserNotification(caseData.senderUid, {
        message: `Delivery extension for ${moment(
          caseData.newDeadline.toDate()
        ).format('DD/MM/YYYY')} was accepted.`,
        type: 'extend-time-accepted',
        link: `/panel/cases/${caseData.caseId}`,
      })

      /**
       * Client notification
       */
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `Delivery extension for ${moment(
          caseData.newDeadline.toDate()
        ).format('DD/MM/YYYY')} was accepted.`,
        type: 'extend-time-accepted',
        link: `/panel/cases/${caseData.caseId}`,
      })

      await updateNotificationsStates()

      await doAddCaseSystemMessage(
        caseData.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        `Delivery extension for ${moment(caseData.newDeadline.toDate()).format(
          'DD/MM/YYYY'
        )} was accepted.`,
        'message'
      )

      await doRegisterTimelineItem(caseData.caseId, {
        type: 'requestExtensionAccepted',
        messages: {
          lawyer: `Delivery extension for ${moment(
            caseData.newDeadline.toDate()
          ).format('DD/MM/YYYY')} was accepted.`,
          client: `Delivery extension for ${moment(
            caseData.newDeadline.toDate()
          ).format('DD/MM/YYYY')} was accepted.`,
        },
        createdAt: new Date(),
      })

      toast.success('Delivery change accepted successfully!')
      await updateCases({
        caseId: caseData.caseId,
        deadline: caseData.newDeadline,
        newDeadline: null,
      })
      handleCloseModal(null, null)
    })
  }

  const handleChangeData = (date) => {
    setNewDate(date)
  }

  return (
    <div className='a-modal__requestExtension'>
      {caseData.senderUid === uid ? (
        <div className='-form'>
          <p>Extend the completion deadline to</p>
          <div className='-field'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disablePast
                fullWidth
                placeholder='DD/MM/YYYY'
                className={classes.date}
                inputVariant='outlined'
                format='DD/MM/YYYY'
                value={newDate}
                onChange={handleChangeData}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      ) : (
        <p className='-message'>
          The lawyer wants to change the delivery date of the case to{' '}
          <b>{moment(caseData.newDeadline.toDate()).format('DD/MM/YYYY')}</b>
        </p>
      )}

      <div className='a-modal__actions'>
        <button
          className='a-modal__actions--cancelButton'
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          className='a-modal__actions--submitButton'
          onClick={
            caseData.senderUid === uid
              ? handleRequestExtension
              : handleAcceptRequestExtension
          }
        >
          {caseData.senderUid === uid ? 'Request' : 'Accept'}
        </button>
      </div>
    </div>
  )
}

export default RequestExtension
