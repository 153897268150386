import React, { useEffect } from 'react'

import NotificationItem from 'components/molecules/NotificationItem'
import { useNotifications } from 'context/notifications'
import { ReactComponent as IconBell } from 'images/icons/bell.svg'
import { ReactComponent as IconClose } from 'images/icons/circle-close.svg'

import './Notifications.scss'

const Notifications = ({ show, handleCloseModal }) => {
  const { notifications, markAllAsRead, updateNotificationsStates } =
    useNotifications()

  useEffect(() => {
    updateNotificationsStates()
  }, [])

  return (
    <div className={`p-notifications ${show ? '-show' : '-hide'}`}>
      <button
        className='p-notifications__closeTrigger'
        onClick={() => handleCloseModal(false)}
      />
      <div className='p-notifications__container'>
        <div className='p-notifications__box'>
          <div className='p-notifications__items'>
            <header>
              <div className='-icon'>
                <IconBell />
              </div>
              <div className='-title'>
                <strong>Latest Notifications</strong>
                <button onClick={markAllAsRead}>Mark all as read</button>
              </div>
              <button onClick={() => handleCloseModal(false)}>
                <IconClose />
              </button>
            </header>
            <main>
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <NotificationItem
                    handleCloseModal={handleCloseModal}
                    key={notification.id}
                    notification={notification}
                  />
                ))
              ) : (
                <div className='p-notifications__items--noItems'>
                  <p>You have no notifications</p>
                </div>
              )}
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
