import React, { useState } from 'react'

import { RiCheckFill, RiContactsBook2Fill, RiPencilFill } from 'react-icons/ri'

import WhiteBox from 'components/atoms/WhiteBox'
import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

import './Contact.scss'

const Contact = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [displayName, setDisplayName] = useState(userInfo.displayName)
  const [phone, setPhone] = useState(userInfo.phone)
  const handleEdit = () => {
    if (edit) {
      doUpdateUserProfile({
        phone,
        displayName,
      })
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  return (
    <WhiteBox
      withOpacity
      title='Contact'
      icon={<RiContactsBook2Fill />}
      actions={
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <RiCheckFill /> : <RiPencilFill />}
        </button>
      }
    >
      {edit ? (
        <input
          type='text'
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          className='-space'
        />
      ) : (
        <span>{displayName}</span>
      )}

      {edit ? (
        <input
          type='text'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      ) : (
        <span>{phone}</span>
      )}
    </WhiteBox>
  )
}

export default Contact
