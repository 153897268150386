import React, { useState, useEffect } from 'react'

import { Button, makeStyles } from '@material-ui/core'
import { Spinner } from 'react-activity'
import { RiBankFill, RiPencilFill, RiCheckFill } from 'react-icons/ri'
import InputMask from 'react-input-mask'
import { withRouter } from 'react-router-dom'

import WhiteBox from 'components/atoms/WhiteBox'
import useUserInfo from 'hooks/useUserInfo'
import useGetUserBankDetails from 'services/firebase/hooks/users/useGetUserBankDetails'
import useUpdateBankDetails from 'services/firebase/hooks/users/useUpdateBankDetails'

import './BankAccount.scss'

const initialFormValues = {
  accountHolderName: '',
  routingNumber: '',
  accountNumber: '',
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2, 0, 0),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    outline: 'none',
    zIndex: 1000,
    color: '#fff',
  },
}))

const BankAccount = ({ history }) => {
  const [userInfo] = useUserInfo()
  const classes = useStyles()
  const doGetUserBankDetails = useGetUserBankDetails()
  const [edit, setEdit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const doUpdateBankDetails = useUpdateBankDetails()
  const [values, setValues] = useState(initialFormValues)
  const [routingNumberFormatted, setRoutingNumberFormatted] = useState(null)

  const getUserData = async () => {
    const [bankDetails] = await doGetUserBankDetails(userInfo.uid)
    if (bankDetails) {
      setValues(bankDetails)
      if (bankDetails.routingNumber) {
        const parts = bankDetails.routingNumber.match(/.{1,2}/g)
        setRoutingNumberFormatted(parts.join('-'))
      }
    }
    if (history.location.hash === '#bank-details') {
      setEdit(true)
    }
  }

  useEffect(() => {
    if (edit) {
      document.getElementById('bank-details').scrollIntoView()
    }
  }, [edit])

  useEffect(() => {
    getUserData()
  }, [])

  const handleEdit = async () => {
    if (edit) {
      setIsSubmitting(true)
      doUpdateBankDetails({ ...values })
        .then(() => {
          const parts = values.routingNumber.match(/.{1,2}/g)
          setRoutingNumberFormatted(parts.join('-'))
          setEdit(false)
          window.location.hash = ''
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      setEdit(true)
    }
  }

  const handleChangeValues = (e) => {
    const val =
      e.target.name === 'routingNumber'
        ? e.target.value.replace(/\D/g, '')
        : e.target.value
    setValues({ ...values, [e.target.name]: val })
  }

  return (
    <div id='bank-details'>
      <WhiteBox
        withOpacity
        modifier='-bankAccount'
        title='Bank Account Details'
        icon={<RiBankFill />}
        actions={
          <button
            onClick={handleEdit}
            type='button'
            className={edit ? '-editing' : ''}
          >
            {edit ? <RiCheckFill /> : <RiPencilFill />}
          </button>
        }
      >
        <small>
          Your payment from clients will be sent to this account within 5 days
          of completing a case.
        </small>
        <small>Your account will never be charged without consent.</small>
        {edit && (
          <div className='flex flex-col p-4 mt-2 bg-red-400 rounded'>
            <small className='mb-2 text-xs'>
              Please check the account details are correct.
            </small>
            <small className='text-xs font-semibold'>
              Lawyerup is not responsible for withdrawals made to bank accounts
              with incorrect details.
            </small>
          </div>
        )}
        <ul>
          <li>
            <span>Account holder name</span>
            {edit ? (
              <input
                required
                name='accountHolderName'
                type='text'
                value={values.accountHolderName}
                onChange={handleChangeValues}
              />
            ) : (
              <b>{values.accountHolderName || '--'}</b>
            )}
          </li>
          <li>
            <span>Account number</span>
            {edit ? (
              <>
                <input
                  required
                  name='accountNumber'
                  type='text'
                  value={values.accountNumber}
                  onChange={handleChangeValues}
                />
                <small>
                  The account number for the bank account, in string form. Must
                  be a checking account.
                </small>
              </>
            ) : (
              <b>{values.accountNumber || '--'}</b>
            )}
          </li>
          <li>
            <span>Sort code</span>
            {edit ? (
              <>
                <InputMask
                  mask='99-99-99'
                  maskChar={null}
                  required
                  name='routingNumber'
                  type='text'
                  value={values.routingNumber}
                  onChange={handleChangeValues}
                />
                <small>
                  The routing number, sort code, or other country-appropriate
                  institution number for the bank account.{' '}
                </small>
              </>
            ) : (
              <b>{routingNumberFormatted || '--'}</b>
            )}
          </li>
        </ul>
        {edit && (
          <Button
            size='large'
            variant='contained'
            color='primary'
            type='button'
            onClick={handleEdit}
            className={classes.button}
          >
            {isSubmitting ? <Spinner color='#fff' /> : 'Save'}
          </Button>
        )}
      </WhiteBox>
    </div>
  )
}

export default withRouter(BankAccount)
