import React from 'react'

import OwlCarousel from 'react-owl-carousel2'

import ReviewItem from './reviewItem'
import './Reviews.scss'

const Reviews = ({ reviews, subtractItems = 0, dots = false, nav = true }) => {
  return (
    <div className='m-reviews'>
      <OwlCarousel
        options={{
          nav: nav,
          loop: false,
          autoplay: false,
          dots: dots,
          navText: [
            '<i class="m-reviews__chevron--left"/>',
            '<i class="m-reviews__chevron--right"/>',
          ],
          responsive: {
            0: {
              items: 1,
            },
            860: {
              items: 2,
            },
            1200: {
              items: 3 - subtractItems,
            },
          },
        }}
      >
        {reviews.map((item, key) => (
          <ReviewItem review={item} key={`reviews-member-carousel-${key}`} />
        ))}
      </OwlCarousel>
    </div>
  )
}

export default Reviews
