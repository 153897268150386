import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import Video from 'twilio-video'

import { useAppState } from 'context/state'
import useVideoContext from 'hooks/useVideoContext/useVideoContext'

import IntroContainer from '../IntroContainer/IntroContainer'
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen'
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar'
import PreflightTest from './PreflightTest/PreflightTest'

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens({
  chatId,
  handleOpenVideoCall,
  name,
  avatar,
}) {
  const { user } = useAppState()
  const { getAudioAndVideoTracks } = useVideoContext()
  const { URLRoomName } = useParams<{ URLRoomName: string }>()
  const [step, setStep] = useState(Steps.roomNameStep)

  // const [name, setName] = useState<string>(user?.displayName || '')
  const [roomName, setRoomName] = useState<string>('')

  const [mediaError, setMediaError] = useState<Error>()

  useEffect(() => {
    window.history.replaceState(
      null,
      '',
      window.encodeURI(`/room/${chatId}${window.location.search || ''}`)
    )
    setStep(Steps.deviceSelectionStep)
    setRoomName(chatId)
  }, [])

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName)
      if (user && user.displayName) {
        setStep(Steps.deviceSelectionStep)
      }
    }
  }, [user, URLRoomName])

  useEffect(() => {
    if (step === Steps.deviceSelectionStep) {
      getAudioAndVideoTracks().catch((error) => {
        setMediaError(error)
      })
    }
  }, [getAudioAndVideoTracks, step])

  const SubContent = (
    <>
      {Video && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  )

  return (
    <IntroContainer
      subContent={step === Steps.deviceSelectionStep && SubContent}
      name={name}
      avatar={avatar}
    >
      <DeviceSelectionScreen
        name={name}
        avatar={avatar}
        roomName={roomName}
        handleOpenVideoCall={handleOpenVideoCall}
      />
    </IntroContainer>
  )
}
