import React, { useEffect, useState } from 'react'

import {
  RadioGroup,
  createTheme,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MuiThemeProvider,
  Radio,
  TextField,
} from '@material-ui/core'

import useLawyerRegistration from 'hooks/useLawyerRegistration'
import { ReactComponent as EuroIcon } from 'images/icon-lawyer-euro.svg'
import { ReactComponent as ReferralIcon } from 'images/icon-lawyer-referral.svg'

const theme = createTheme({
  overrides: {
    MuiFormGroup: {
      root: {
        marginTop: 20,
        marginBottom: 20,
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#18a1db',
      },
    },
  },
})

const ProfilePrice = ({ classes, errors }) => {
  const [formValues, setFormValues] = useLawyerRegistration()
  const [stepErros, setStepErros] = useState([])

  useEffect(() => {
    setStepErros(errors)
  }, [errors])

  const handleFormValues = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
    if (stepErros.includes(e.target.name)) {
      const tempErr = stepErros
      const errIndex = stepErros.indexOf(e.target.name)
      tempErr.splice(errIndex, 1)
      setStepErros(tempErr)
    }
  }

  const handleFixedFees = (e) => {
    setFormValues({ ...formValues, fixedFee: e.target.value === 'true' })

    if (stepErros.includes(e.target.name)) {
      const tempErr = stepErros
      const errIndex = stepErros.indexOf(e.target.name)
      tempErr.splice(errIndex, 1)
      setStepErros(tempErr)
    }
  }

  return (
    <div>
      <FormControl
        component='div'
        fullWidth
        variant='filled'
        className={classes.lastFormControl}
      >
        <TextField
          required
          error={stepErros.includes('hourPrice')}
          label='Price per hour '
          variant='filled'
          name='hourPrice'
          value={formValues.hourPrice}
          onChange={handleFormValues}
          type='number'
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <EuroIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl component='div' fullWidth>
        <FormHelperText style={{ width: '100%', textAlign: 'left' }}>
          <b style={{ color: '#18a1db' }}>Note</b>: If you can offer certain
          ﬁxed fee services, please tick below
        </FormHelperText>
      </FormControl>

      <MuiThemeProvider theme={theme}>
        <RadioGroup
          aria-label='fixedFee'
          name='fixedFee'
          value={formValues.fixedFee}
          onChange={handleFixedFees}
        >
          <FormControlLabel
            className={classes.leftFormControlLabel}
            classes={{ label: classes.checkboxLabel }}
            control={
              <Radio
                checked={formValues.fixedFee}
                value='true'
                color='primary'
              />
            }
            label='Yes, I also offer ﬁxed fee options'
          />
          <FormControlLabel
            className={classes.leftFormControlLabel}
            classes={{ label: classes.checkboxLabel }}
            control={
              <Radio
                checked={!formValues.fixedFee}
                value='false'
                color='primary'
              />
            }
            label='No, I only work at my hourly rate'
          />
        </RadioGroup>
      </MuiThemeProvider>
      <FormControl
        component='div'
        fullWidth
        variant='filled'
        className={classes.lastFormControl}
      >
        <TextField
          label='Referral ID'
          variant='filled'
          name='referralId'
          value={formValues.referralId}
          onChange={handleFormValues}
          helperText='If none, click next'
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <ReferralIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </div>
  )
}

export default ProfilePrice
