import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as IconCheck } from 'images/icon-check-circle.svg'
import { ReactComponent as AddIcon } from 'images/icons/circle-plus.svg'
import useGenerateOnBoardingLink from 'services/firebase/hooks/lawyers/useGenerateOnBoardingLink'
import useGetUserBankDetails from 'services/firebase/hooks/users/useGetUserBankDetails'
import useRetrieveUserAccount from 'services/firebase/hooks/users/useRetrieveUserAccount'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

const CompleteAccountValidations = () => {
  const [userInfo] = useUserInfo()

  const history = useHistory()

  const doGetUserBankDetails = useGetUserBankDetails()
  const doGenerateOnBoardingLink = useGenerateOnBoardingLink()
  const doUpdateUserProfile = useUpdateUserProfile()

  const doRetrieveUserAccount = useRetrieveUserAccount()
  const [showAccountSteps, setShowAccountSteps] = useState(false)
  const [hasInitialInformation, setHasInitialInformation] = useState(false)
  const [onBoardingLink, setOnBoardingLink] = useState(undefined)
  const [hasBankAccount, setHasBankAccount] = useState(false)
  const [extraInformation, setExtraInformation] = useState(false)
  const [barWidth, setBarWidth] = useState(25)

  const handleAddBankDetails = () => {
    history.push('/panel/profile#bank-details')
  }

  const getOnBoardingLink = async () => {
    if (userInfo) {
      if (!userInfo.validatedAccount && userInfo.role === 'lawyer') {
        const userStripeAccount = await doRetrieveUserAccount()
        if (userStripeAccount) {
          const [bankDetails] = await doGetUserBankDetails(userInfo.uid)

          if (bankDetails) {
            setHasBankAccount(true)
          }

          if (userStripeAccount.charges_enabled) {
            setHasInitialInformation(true)
          }

          if (
            !userStripeAccount.charges_enabled ||
            userStripeAccount.requirements.errors.length > 0
          ) {
            const accountLink = await doGenerateOnBoardingLink()
            setOnBoardingLink(accountLink.url)
            if (
              userStripeAccount.charges_enabled &&
              userStripeAccount.requirements.errors.length > 0
            ) {
              setExtraInformation(true)
            }
          }
          if (
            userStripeAccount.charges_enabled &&
            userStripeAccount.payouts_enabled
          ) {
            doUpdateUserProfile({ validatedAccount: true }, false)
          } else {
            setShowAccountSteps(true)
          }
        }
      }
    }
  }

  useEffect(() => {
    getOnBoardingLink()
  }, [userInfo])

  useEffect(() => {
    let total = 0

    if (hasBankAccount) total = total + 25
    if (hasInitialInformation) total = total + 25
    if (extraInformation) total = total + 25

    setBarWidth(total)
  }, [hasBankAccount, onBoardingLink, hasInitialInformation, extraInformation])

  return (
    <>
      {!userInfo.validatedAccount && showAccountSteps && (
        <div className='o-lawyerDashboard__accountActivation'>
          <div className='content'>
            <header>
              <h2>
                {userInfo.displayName || `${userInfo.name} ${userInfo.surname}`}
              </h2>
              <p>Your profile is almost complete</p>
            </header>
            <div className='box'>
              <ul>
                {!hasInitialInformation && (
                  <li>
                    <IconCheck />
                    <p>Verify my identity</p>
                    <a href={onBoardingLink}>
                      <span>Add info</span>
                      <AddIcon />
                    </a>
                  </li>
                )}
                {!hasBankAccount && (
                  <li>
                    <IconCheck />
                    <p>Bank details</p>
                    <button onClick={handleAddBankDetails}>
                      <span>Add info</span>
                      <AddIcon />
                    </button>
                  </li>
                )}
                {onBoardingLink && (
                  <li
                    className={`${
                      !extraInformation && !hasInitialInformation && '-disabled'
                    }`}
                  >
                    <IconCheck />
                    <p>Extra information</p>
                    {extraInformation && onBoardingLink ? (
                      <a href={onBoardingLink}>
                        <span>Add info</span>
                        <AddIcon />
                      </a>
                    ) : (
                      <button disabled>
                        <span>Add info</span>
                        <AddIcon />
                      </button>
                    )}
                  </li>
                )}
              </ul>
              <div className='statusBar'>
                <div className='bar'>
                  <span style={{ width: `${barWidth}%` }} />
                </div>
                <span className='percentage'>{barWidth}%</span>
              </div>
            </div>
            <footer>
              <small>
                * Stripe is a 3rd party secure payment gateway which allows you
                to accept funds directly to your bank. Your bank details should
                be the account that you want to receive payments for your legal
                services into. Note: This account will never be charged without
                your consent.
              </small>
            </footer>
          </div>
        </div>
      )}
    </>
  )
}

export default CompleteAccountValidations
