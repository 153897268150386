import React, { useEffect } from 'react'

import contactLawyersImg from 'images/contact-lawyers.gif'
import contactLawyers from 'images/contact-lawyers.mp4'

import Section from '../../atoms/Section'
import Title from '../../atoms/Title'
import './PopularPosts.scss'

const PopularPosts = () => {
  useEffect(() => {
    const videoRef = document.getElementById('contact-lawyers-video')
    if (videoRef) {
      videoRef.play()
    }
  }, [])

  return (
    <Section className='o-popularPosts'>
      <div className='o-popularPosts__header'>
        <Title>Contact lawyers for free advice</Title>
        <p>
          Family, Property, No win no fee, Wills & probate, Business &
          employment, Visa & immigration, Criminal & more
        </p>
      </div>
      <div className='o-popularPosts__content'>
        <div className='o-popularPosts__content--video'>
          <video width='100%' autoPlay loop muted id='contact-lawyers-video'>
            <source src={contactLawyers} type='video/mp4' />
          </video>
        </div>

        <div className='o-popularPosts__content--image'>
          <img src={contactLawyersImg} alt='' />
        </div>
      </div>
    </Section>
  )
}

export default PopularPosts
