import React from 'react'

import { Button } from '@material-ui/core'
import { Link } from '@reach/router'
import { withRouter } from 'react-router-dom'

import Section from 'components/atoms/Section'
import Title from 'components/atoms/Title'
import AppStoreButton from 'images/app-store.png'
import beFeaturedVideo from 'images/bg-featured-video.png'
import GooglePlayButton from 'images/google-play.png'
import { ReactComponent as MoneyIcon } from 'images/icon-banknotes.svg'
import { ReactComponent as CheckIcon } from 'images/icon-check.svg'
import { ReactComponent as PlayIcon } from 'images/icon-play.svg'
import { ReactComponent as OfferIcon } from 'images/icon-tag.svg'
import { ReactComponent as TasksIcon } from 'images/icon-task.svg'
import seeResults from 'images/img-see-results.png'
import unlimitedClients from 'images/img-unlimited-clients.png'

import './BeFeaturedPage.scss'

const BeFeaturedPage = ({ history }) => {
  const handleButtonClick = () => {
    history.push('/lawyer-registration')
  }

  return (
    <div className='p-beFeatured'>
      <div className='p-beFeatured__header'>
        <h2>Let people find you</h2>
        <p>
          Your online presence is so important - now more than ever. Create the
          profile you want, control what type of cases you handle and choose how
          you prefer to be contacted. You can even provide clients directions
          straight to your office from wherever they are.
        </p>
      </div>
      <Section className='p-beFeatured__become'>
        <Title>Become a featured lawyer</Title>
        <p>
          Increase your business and show off your capabilities as a legal
          professional! Bring on new clients in the law sectors you dominate.
          It’s fast, easy to use, and smart.
        </p>
        <Button
          size='large'
          variant='contained'
          color='primary'
          onClick={handleButtonClick}
        >
          Get started
        </Button>
        <div className='p-beFeatured__become--video'>
          <img src={beFeaturedVideo} alt='' />
          <div className='p-beFeatured__become--videoButton'>
            <PlayIcon />
          </div>
        </div>
      </Section>
      <Section container={false} className='p-beFeatured__info'>
        <div className='p-beFeatured__info--row'>
          <div className='p-beFeatured__info--image'>
            <img src={seeResults} alt='' />
          </div>
          <div className='p-beFeatured__info--text'>
            <Title>See Results</Title>
            <p>
              See how many clients contacted you each month, how they contacted
              you, new client reviews of your work and how much business has
              increased. Yes, it’s so easy.
            </p>
            <Button
              size='large'
              variant='contained'
              color='primary'
              onClick={handleButtonClick}
            >
              Join
            </Button>
          </div>
        </div>

        <div className='p-beFeatured__info--row -right'>
          <div className='p-beFeatured__info--text'>
            <Title>Unlimited Clients</Title>
            <p>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              That's right. There's no limit. You can activate your profile and
              deactivate when you want, because business is about to get busy.
            </p>
            <ul>
              <li>
                <CheckIcon /> Only 10% + Vat of client fees
              </li>
              <li>
                <CheckIcon /> Cancel anytime
              </li>
              <li>
                <CheckIcon /> Criminal & no win no fee lawyers are free
              </li>
            </ul>
            <Button
              size='large'
              variant='contained'
              color='primary'
              onClick={handleButtonClick}
            >
              Get Started
            </Button>
          </div>
          <div className='p-beFeatured__info--image'>
            <img src={unlimitedClients} alt='' />
          </div>
        </div>
      </Section>
      <Section className='p-beFeatured__payments'>
        <Title>Wondering how payments work?</Title>

        <div className='p-beFeatured__payments--items'>
          <div className='p-beFeatured__payments--item'>
            <div className='p-beFeatured__payments--itemIcon'>
              <OfferIcon />
            </div>
            <div className='p-beFeatured__payments--itemTitle'>
              Send an offer
            </div>
            <div className='p-beFeatured__payments--itemBullet' />
            <div className='p-beFeatured__payments--itemText'>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Agree your price and send an offer to your new client. If you bill
              hourly, don&apos;t worry you can send more offers as you go.
            </div>
          </div>

          <div className='p-beFeatured__payments--item'>
            <div className='p-beFeatured__payments--itemIcon'>
              <MoneyIcon />
            </div>
            <div className='p-beFeatured__payments--itemTitle'>
              Client accepts
            </div>
            <div className='p-beFeatured__payments--itemBullet' />
            <div className='p-beFeatured__payments--itemText'>
              Clients pay Lawyerup for your legal services in advance. Lawyerup
              holds this for safekeeping.
            </div>
          </div>

          <div className='p-beFeatured__payments--item'>
            <div className='p-beFeatured__payments--itemIcon'>
              <TasksIcon />
            </div>
            <div className='p-beFeatured__payments--itemTitle'>
              Complete the work
            </div>
            <div className='p-beFeatured__payments--itemBullet' />
            <div className='p-beFeatured__payments--itemText'>
              Once you and the client agree its finished, the funds are paid
              directly to your bank account.
            </div>
          </div>
        </div>
      </Section>
      <Section className='p-beFeatured__appStores'>
        <Title>Download our app to use full features</Title>
        <p>
          To use our full features download and register on the app for free
        </p>
        <div className='p-beFeatured__appStores--buttons'>
          <Link to={'#'}>
            <img src={AppStoreButton} alt='' />
          </Link>
          <Link to={'#'}>
            <img src={GooglePlayButton} alt='' />
          </Link>
        </div>
      </Section>
    </div>
  )
}

export default withRouter(BeFeaturedPage)
