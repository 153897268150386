import { useCallback } from 'react'

import { firestore } from 'services/firebase'

const useGetLawyerStripeAccountId = () => {
  return useCallback(async (uid) => {
    return firestore
      .collection('stripe_connected_accounts')
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data()
        }
        return null
      })
  }, [])
}

export default useGetLawyerStripeAccountId
