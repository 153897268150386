import React, { useRef, useState, useEffect } from 'react'

import Edit from '@material-ui/icons/Edit'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Avatar from 'react-avatar'
import { Link, withRouter, useHistory } from 'react-router-dom'

import Notifications from 'components/pages/Panel/Notifications'
import { useCases } from 'context/cases'
import { useChat } from 'context/chat'
import { useNotifications } from 'context/notifications'
import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as IconBell } from 'images/icons/bell.svg'
import { ReactComponent as IconAdd } from 'images/icons/circle-plus.svg'
import { ReactComponent as IconClose } from 'images/icons/close.svg'
import { ReactComponent as IconDashboard } from 'images/icons/dashboard.svg'
import { ReactComponent as IconFolder } from 'images/icons/folder.svg'
import { ReactComponent as IconMenu } from 'images/icons/menu.svg'
import { ReactComponent as IconMessage } from 'images/icons/message.svg'
import Logo from 'images/logo.png'
import { db } from 'services/firebase'
import useLogout from 'services/firebase/hooks/auth/useLogout'
import useGetInvitesToTeam from 'services/firebase/hooks/lawyers/useGetInvitesToTeam'
import useHandleTeamInvite from 'services/firebase/hooks/lawyers/useHandleTeamInvite'

import './Header.scss'
import MobileMenu from './MobileMenu'

const PanelHeader = ({ location }) => {
  const { handleModal } = useCases()

  const [userInfo] = useUserInfo()
  const history = useHistory()

  const { hasNewNotifications } = useNotifications()
  const doHandleTeamInvite = useHandleTeamInvite()
  const doGetInvitesToTeam = useGetInvitesToTeam()
  const { hasUnreadChat, setHasUnreadChat } = useChat()
  const dropdownRef = useRef()
  const doLogout = useLogout()
  const [showNav, setShowNav] = useState(false)
  const [showNotificationsModal, setShowNotificationsModal] = useState(false)
  const [fullName, setFullName] = useState(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [invites, setInvites] = useState([])

  const handleLogout = () => {
    doLogout().then(() => {
      history.push('/login')
    })
  }

  useEffect(() => {
    doGetInvitesToTeam().then((result) => {
      setInvites(result)
    })
    if (userInfo) {
      if (userInfo.role === 'lawyer') {
        setFullName(`${userInfo.name} ${userInfo.surname}`)
      } else {
        setFullName(userInfo.displayName)
      }
    }
  }, [userInfo])

  useEffect(() => {
    db.ref(`/users/${userInfo.uid}/unreadChats/`).on('value', function (snap) {
      setHasUnreadChat(snap.numChildren())
    })
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown)
    }
  })

  const handleClickOutsideDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  const handleNavigation = (to) => {
    setShowNav(false)
    history.push(to)
  }

  const handleAcceptInvite = (invite, accepted) => {
    const tempInvites = [...invites]

    const index = tempInvites.indexOf(invite)
    if (typeof index !== 'undefined') {
      tempInvites.splice(index, 1)
      setInvites(tempInvites)
      doHandleTeamInvite(invite, accepted)
    }
  }

  return (
    <>
      <header className='t-mainHeader -panel'>
        <div className='flex items-center justify-start'>
          <Link to='/' className='t-mainHeader__logo'>
            <img src={Logo} alt='Lawyerup' />
          </Link>
          {userInfo.role === 'lawyer' && (
            <button
              onClick={() =>
                handleModal({
                  caseData: null,
                  type: 'createOffer',
                  icon: <IconFolder />,
                })
              }
              className='mr-auto t-mainHeader__newOffer'
            >
              <span>New Offer</span>
              <IconAdd />
            </button>
          )}
          <nav className={`t-mainHeader__nav`}>
            <a
              onClick={() => handleNavigation('/panel/dashboard')}
              className={`t-mainHeader__nav--link ${
                location.pathname === '/panel/dashboard' ? '-current' : ''
              }`}
            >
              <span>Dashboard</span>
              <IconDashboard />
            </a>
            <a
              onClick={() => handleNavigation('/panel/cases')}
              className={`t-mainHeader__nav--link ${
                location.pathname.includes('/panel/cases') ? '-current' : ''
              }`}
            >
              <span>Cases</span>
              <IconFolder />
            </a>
            <a
              onClick={() => handleNavigation('/panel/messages')}
              className={`t-mainHeader__nav--link ${
                location.pathname === '/panel/messages' ? '-current' : ''
              }`}
            >
              <span>Messages</span>
              <IconMessage />
              {hasUnreadChat > 0 && (
                <i className='t-mainHeader__nav--link-notification'>
                  {hasUnreadChat}
                </i>
              )}
            </a>
            <button
              onClick={() => setShowNotificationsModal(true)}
              className='t-mainHeader__nav--link'
            >
              <span>Notifications</span>
              <IconBell />
              {hasNewNotifications > 0 && (
                <i className='t-mainHeader__nav--link-notification'>
                  {hasNewNotifications}
                </i>
              )}
            </button>
          </nav>
          <div ref={dropdownRef} className='t-mainHeader__dropdown'>
            <div className='t-mainHeader__dropdown--trigger'>
              <Avatar
                size='48'
                maxInitials={2}
                round='100%'
                color='#26395A'
                textSizeRatio={2.5}
                src={userInfo && userInfo.photoURL ? userInfo.photoURL : null}
                name={userInfo ? fullName : '--'}
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </div>
            <div
              className={`t-mainHeader__dropdown--content  ${
                showDropdown ? '-open' : '-close'
              }`}
            >
              <div className='t-mainHeader__dropdown--box'>
                <div className='t-mainHeader__dropdown--header'>
                  <div className='t-mainHeader__dropdown--avatar'>
                    <Avatar
                      size='80'
                      maxInitials={2}
                      round='100%'
                      color='#26395A'
                      textSizeRatio={2.5}
                      src={
                        userInfo && userInfo.photoURL ? userInfo.photoURL : null
                      }
                      name={fullName}
                      onClick={() => setShowDropdown(!showDropdown)}
                    />
                  </div>
                  <span className='t-mainHeader__dropdown--welcome'>
                    {fullName}
                  </span>
                  <Link to='/panel/profile'>
                    <small>Edit profile</small>
                    <Edit />
                  </Link>
                </div>
                <button type='button' onClick={handleLogout}>
                  <span>Logout</span>
                  <ExitToApp />
                </button>
              </div>
            </div>
          </div>
          <button
            onClick={() => setShowNav(!showNav)}
            className='t-mainHeader__toggleNav'
          >
            <IconMenu />
          </button>
        </div>
      </header>
      {userInfo.role === 'lawyer' && (
        <header className='t-mainHeader__subHeader'>
          <button
            onClick={() =>
              handleModal({
                caseData: null,
                type: 'createOffer',
                icon: <IconFolder />,
              })
            }
            className='t-mainHeader__subHeader--newOffer'
          >
            <span>New Offer</span>
            <IconAdd />
          </button>
          <button
            onClick={() => setShowNotificationsModal(true)}
            className='t-mainHeader__subHeader--link'
          >
            <span>Notifications</span>
            <IconBell />
            {hasNewNotifications > 0 && (
              <i className='t-mainHeader__subHeader--link-notification'>
                {hasNewNotifications}
              </i>
            )}
          </button>
        </header>
      )}

      {invites && (
        <div className='t-teamInvites'>
          {invites.map((invite) => (
            <div key={invite.id} className='t-teamInvites__item'>
              <strong>
                <Link to={`/profile/${invite.firmUid}`}>
                  {invite.firmName.trim()}
                </Link>{' '}
                wants to add you to his firm&apos;s team
              </strong>
              <div className='t-teamInvites__item--actions'>
                <button
                  className='-decline'
                  onClick={() => handleAcceptInvite(invite, false)}
                >
                  Decline
                </button>
                <button onClick={() => handleAcceptInvite(invite, true)}>
                  Accept
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {showNav && (
        <div className='t-mainHeader__menuModal'>
          <button
            className='t-mainHeader__menuModal--close'
            onClick={() => setShowNav(false)}
          >
            <IconClose />
          </button>
          <MobileMenu
            show={showNotificationsModal}
            handleShowNotifications={setShowNotificationsModal}
            handleCloseNav={setShowNav}
          />
        </div>
      )}
      <div
        className={`t-mainHeader__notificationsModal ${
          showNotificationsModal ? '-show' : '-hide'
        }`}
      >
        <Notifications
          show={showNotificationsModal}
          handleCloseModal={setShowNotificationsModal}
        />
      </div>
    </>
  )
}

export default withRouter(PanelHeader)
