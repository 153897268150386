import { useCallback } from 'react'

import axios from 'axios'

import { auth, firestore } from 'services/firebase'
import { firebaseBaseURL } from 'utils/constants'

const useRetrieveUserAccount = () => {
  return useCallback(async () => {
    const { uid } = auth.currentUser

    const axiosConfig = {
      method: 'post',
      url: `${firebaseBaseURL}stripe/retrieve-account`,
      data: { uid },
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(axiosConfig)
      .then((result) => {
        if (result) {
          return result.data
        }
      })
      .catch((err) => {
        throw 'Error'
      })
  }, [])
}

export default useRetrieveUserAccount
