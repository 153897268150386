import React from 'react'

import { Slider, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#18a1db',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 66,
    height: 20,
    top: '100%',
    borderRadius: 2,
    color: '#fff',
    position: 'absolute',
    marginLeft: -33,
  },
  arrow: {
    content: '',
    borderBottom: '5px solid #18a1db',
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    position: 'absolute',
    bottom: '100%',
    display: 'block',
  },
}))

const DistanceFilter = ({ handleChange }) => {
  const classes = useStyles()

  const valueLabelComponent = (props) => {
    const { children, value } = props
    // console.log(children.props.style)
    return (
      <span>
        {children}
        <span className={classes.tooltip} style={{ ...children.props.style }}>
          <i className={classes.arrow} />
          {value}
        </span>
      </span>
    )
  }

  return (
    <Slider
      min={5}
      max={200}
      defaultValue={50}
      step={5}
      valueLabelFormat={(v) => v + ' km'}
      ValueLabelComponent={valueLabelComponent}
      marks={[
        { value: 5, label: '5 km' },
        { value: 200, label: '200 km' },
      ]}
      valueLabelDisplay='on'
      onChange={handleChange}
    />
  )
}

export default DistanceFilter
