import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import useGetLawyerByUid from 'services/firebase/hooks/lawyers/useGetLawyerByUid'

const MemberItem = ({ data }) => {
  const doGetLawyerByUid = useGetLawyerByUid()
  const [member, setMember] = useState()

  useEffect(() => {
    if (data) {
      doGetLawyerByUid(data.uid).then((lawyer) => {
        if (lawyer) {
          setMember(lawyer[0])
        }
      })
    }
  }, [data])

  if (!member) return false

  return (
    <div className={`m-team__item`}>
      <Link
        key={`team-member-carousel-${data.uid}`}
        to={`/profile/${data.uid}`}
      >
        <div className='m-team__item--image'>
          <img src={member.photoURL} alt='' />
        </div>
        <div className='m-team__item--info'>
          <strong>{`${member.name} ${member.surname}`}</strong>
          <span>{member.lawFirmName}</span>
        </div>
      </Link>
    </div>
  )
}

export default MemberItem
