import { useCallback } from 'react'

import { firestore } from 'services/firebase'

const useRegisterTimelineItem = () => {
  return useCallback(async (caseId, data) => {
    return firestore
      .collection('cases')
      .doc(caseId)
      .collection('timeline')
      .doc()
      .set(data)
  }, [])
}

export default useRegisterTimelineItem
