import { useCallback } from 'react'

import { toast } from 'react-toastify'

import { auth, firestore } from 'services/firebase'
import useGetUserByEmail from 'services/firebase/hooks/lawyers/useGetLawyerByEmail'

const useRegisterInviteToTeam = () => {
  const { uid, displayName } = auth.currentUser
  const doGetUserByEmail = useGetUserByEmail()

  return useCallback(async (email) => {
    return doGetUserByEmail(email)
      .then(async (lawyer) => {
        const lawyerUser = lawyer.length > 0 ? lawyer[0] : null
        if (!lawyerUser) {
          toast.error("We didn't find any lawyer with the email.")
          return false
        }
        if (lawyerUser && lawyerUser.uid === uid) {
          toast.error('You cannot invite yourself to your own team.')
          return false
        }

        if (lawyerUser && lawyerUser.uid !== uid) {
          return await firestore
            .collection('invites_to_team')
            .where('firmUid', '==', uid)
            .where('invitedUid', '==', lawyerUser.uid)
            .get()
            .then((snap) => {
              if (!snap.empty) {
                toast.warn(
                  'You have already sent an invitation to this lawyer.'
                )
                throw new Error('failed')
              } else {
                firestore.collection('invites_to_team').doc().set({
                  firmUid: uid,
                  firmName: displayName,
                  invitedUid: lawyerUser.uid,
                  invitedEmail: email,
                })
                toast.success('Invitation sent successfully.')
              }
            })
        }
      })
      .catch(() => {
        toast.error('An error occurred while trying to send the invitation.')
      })
  }, [])
}

export default useRegisterInviteToTeam
