import { useCallback } from 'react'

import { toast } from 'react-toastify'

import { auth, db, firestore } from 'services/firebase'

const useUpdateUserProfile = () => {
  return useCallback(async (values, showToast = true) => {
    const { uid } = auth.currentUser
    await db.ref(`/users/${uid}`).update(values)
    firestore
      .collection('users')
      .doc(uid)
      .update(values)
      .then((result) => {
        if (showToast) {
          toast.success('Data updated successfully.')
        }
      })
  }, [])
}

export default useUpdateUserProfile
