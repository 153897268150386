import React, { useEffect } from 'react'

import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import BeFeaturedPage from './components/pages/BeFeaturedPage'
import FaqPage from './components/pages/FaqPage'
import ForgotPasswordPage from './components/pages/ForgotPasswordPage'
import HomePage from './components/pages/HomePage'
import JoinPage from './components/pages/JoinPage'
import LawyerRegistration from './components/pages/LawyerRegistration'
import LoginPage from './components/pages/LoginPage'
import PanelCases from './components/pages/Panel/Cases'
import PanelDashboard from './components/pages/Panel/Dashboard'
import PanelManageCase from './components/pages/Panel/ManageCase'
import PanelMessagesPage from './components/pages/Panel/MessagesPage'
import PanelNotifications from './components/pages/Panel/Notifications'
import PanelProfile from './components/pages/Panel/Profile'
import PrivacyAndPolicy from './components/pages/PrivacyAndPolicy'
import ProfileNotValidatedPage from './components/pages/ProfileNotValidatedPage'
import ProfilePage from './components/pages/ProfilePage'
import ResultsPage from './components/pages/ResultsPage'
import TermsAndConditions from './components/pages/TermsAndConditions'
import TermsAndConditionsLawyer from './components/pages/TermsAndConditionsLawyer'
import PrivateRoute from './components/template/PrivateRoute'

const Routes = ({ history }) => {
  useEffect(() => {
    if (history.location.hash === '#bank-details') {
      const bankDetailsOffsetTop = document.getElementById('bank-details')
      if (bankDetailsOffsetTop) {
        window.scrollTo(0, bankDetailsOffsetTop.offsetTop)
      }
    } else {
      window.scrollTo(0, 0)
    }
  }, [history.location.pathname])
  return (
    <Switch>
      <Route exact path='/'>
        <HomePage />
      </Route>
      <Route exact path='/login'>
        <LoginPage />
      </Route>
      <Route exact path='/forgot-password'>
        <ForgotPasswordPage />
      </Route>
      <Route exact path='/lawyer-registration'>
        <LawyerRegistration />
      </Route>
      <Route exact path='/search/:expertise/:practiceArea/:postcode'>
        <ResultsPage />
      </Route>
      <Route exact path='/be-featured'>
        <BeFeaturedPage />
      </Route>
      <Route exact path='/profile/:slug'>
        <ProfilePage />
      </Route>
      <Route exact path='/profile/not-validated/:slug'>
        <ProfileNotValidatedPage />
      </Route>
      <Route exact path='/faq'>
        <FaqPage />
      </Route>
      <Route exact path='/join'>
        <JoinPage />
      </Route>
      <Route exact path='/terms-and-conditions'>
        <TermsAndConditions />
      </Route>
      <Route exact path='/lawyer-terms-and-conditions'>
        <TermsAndConditionsLawyer />
      </Route>
      <Route exact path='/privacy-policy'>
        <PrivacyAndPolicy />
      </Route>
      <PrivateRoute exact path='/panel/dashboard' component={PanelDashboard} />
      <PrivateRoute exact path='/panel/profile' component={PanelProfile} />
      <PrivateRoute
        exact
        path='/panel/notifications'
        component={PanelNotifications}
      />
      <PrivateRoute
        exact
        path='/panel/messages/:chatId?'
        component={PanelMessagesPage}
      />
      <PrivateRoute exact path='/panel/cases' component={PanelCases} />
      <PrivateRoute
        exact
        path='/panel/cases/:caseId'
        component={PanelManageCase}
      />
      <Route render={() => <Redirect to={{ pathname: '/' }} />} />
    </Switch>
  )
}
export default withRouter(Routes)
