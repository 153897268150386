import React from 'react'

import { toast } from 'react-toastify'

import { useNotifications } from 'context/notifications'
import useRegisterTimelineItem from 'services/firebase/hooks/cases/useRegisterTimelineItem'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'

const CancelCase = ({ caseData, handleCloseModal }) => {
  const { updateNotificationsStates } = useNotifications()

  const doUpdateCaseById = useUpdateCaseById()
  const doAddCaseSystemMessage = useAddCaseSystemMessage()
  const doRegisterUserNotification = useRegisterUserNotification()
  const doRegisterTimelineItem = useRegisterTimelineItem()

  const handleCaseCancellation = () => {
    doUpdateCaseById(caseData.caseId, { status: 'canceled' }).then(async () => {
      /**
       * Lawyer notification
       */
      await doRegisterUserNotification(caseData.senderUid, {
        message:
          'You canceled the case and the payment will be returned to the client.',
        type: 'canceled',
        link: `/panel/cases/${caseData.caseId}`,
      })

      /**
       * Client notification
       */
      await doRegisterUserNotification(caseData.receiverUid, {
        message: `${
          caseData.senderName || 'The lawyer'
        } canceled the case and the payment will be returned to you.`,
        type: 'canceled',
        link: `/panel/cases/${caseData.caseId}`,
      })

      await doRegisterTimelineItem(caseData.caseId, {
        type: 'canceled',
        messages: {
          lawyer: 'You canceled the case',
          client: 'The lawyer canceled the case',
        },
        createdAt: new Date(),
      })

      await updateNotificationsStates()

      await doAddCaseSystemMessage(
        caseData.caseId,
        caseData.senderUid,
        caseData.receiverUid,
        'The case has been cancelled and the payment will be refunded back to the client.',
        'message'
      )

      toast.success('Cancellation request sent successfully!')
      handleCloseModal(null, null)
    })
  }

  return (
    <div className='a-modal__reportADispute'>
      <p>
        I would like to cancel the case, please refund all money back to the
        client.
      </p>

      <div className='a-modal__actions'>
        <button
          className='a-modal__actions--cancelButton'
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          className='a-modal__actions--submitButton'
          onClick={handleCaseCancellation}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}

export default CancelCase
