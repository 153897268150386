import React, { useState, useEffect, useCallback } from 'react'

import { Scrollbars } from 'react-custom-scrollbars'
import { FiMessageSquare, FiSearch } from 'react-icons/all'
import { useParams, useHistory } from 'react-router-dom'

import PanelContainer from 'components/atoms/PanelContainer'
import ChatContact from 'components/molecules/ChatContact'
import ChatConversation from 'components/organisms/ChatConversation'
import useUserInfo from 'hooks/useUserInfo'
import { auth, db } from 'services/firebase'

import './MessagesPage.scss'

const PanelMessagesPage = () => {
  const { uid } = auth.currentUser
  const [user] = useUserInfo()
  const { chatId } = useParams()
  const history = useHistory()

  const [search, setSearch] = useState(null)

  const [status, setStatus] = useState('offline')

  const handleUserStatus = useCallback(() => {
    db.ref(`users/${uid}`).on('value', function (snap) {
      const userData = snap.val()
      setStatus(userData.status)
    })
  }, [])

  useEffect(() => {
    handleUserStatus()
  }, [])

  const [rooms, setRooms] = useState([])
  const [activeChat, setActiveChat] = useState(null)
  const [contactUid, setContactUid] = useState(null)
  const [messagesRef, setMessagesRef] = useState(null)

  const handleChatRooms = useCallback(async () => {
    db.ref('chats')
      .orderByChild(`members/${uid}`)
      .equalTo(true)
      .on('value', function (snap) {
        setRooms(snap.val())
      })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (chatId) {
      setActiveChat(chatId)
    }
    handleChatRooms()
  }, []) // eslint-disable-line

  const handleChangeChat = (chat, contactId) => {
    setActiveChat(chat)
    setContactUid(contactId)
    history.push(`/panel/messages/${chat}`)
    db.ref(`/chats/${chat}/newMessages`).child(uid).set(false)
    db.ref(`/users/${uid}/unreadChats/${chat}`).remove()
    if (messagesRef) messagesRef.off('value')
  }

  return (
    <PanelContainer>
      <div className='p-panelMessagesPage'>
        <div className='p-panelMessagesPage__container'>
          <div className='p-panelMessagesPage__contacts'>
            <header className='p-panelMessagesPage__contacts--header'>
              <FiMessageSquare />
              <h3>Messages</h3>
              <span className={`-status -${status}`}>{status}</span>
            </header>
            <div className='p-panelMessagesPage__contacts--search'>
              <div className='p-panelMessagesPage__contacts--search-field'>
                <input
                  type='text'
                  placeholder='Search people'
                  onChange={(e) => setSearch(e.target.value)}
                />
                <FiSearch />
              </div>
            </div>
            {rooms ? (
              <Scrollbars
                autoHide
                autoHeight
                autoHeightMin='calc(100vh - 184px)'
              >
                {Object.keys(rooms).map((roomId) => {
                  return (
                    <ChatContact
                      search={search}
                      roomId={roomId}
                      key={`chat-${roomId}`}
                      {...rooms[roomId]}
                      handleOnClick={handleChangeChat}
                      isActive={activeChat === roomId}
                    />
                  )
                })}
              </Scrollbars>
            ) : (
              <div className='p-panelMessagesPage__contacts--empty'>
                <span>No contacts found</span>
              </div>
            )}
          </div>
          <div
            className={`p-panelMessagesPage__content ${
              activeChat ? '-active' : ''
            }`}
          >
            {contactUid ? (
              <ChatConversation
                handleMessagesRef={setMessagesRef}
                chatId={activeChat}
                contactUid={contactUid}
                handleCloseChat={handleChangeChat}
              />
            ) : (
              <div className='o-chatConversation'>
                <div className='o-chatConversation__empty'>
                  {rooms && rooms.length > 0
                    ? 'No conversations selected'
                    : `You have no conversations with ${
                        user.role === 'lawyer' ? 'clients' : 'lawyers'
                      } yet. ${
                        user.role === 'lawyer'
                          ? 'As soon as a client contacts you their chat will appear here.'
                          : ''
                      }`}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PanelContainer>
  )
}

export default PanelMessagesPage
