import React from 'react'

import './TermsAndPrivacy.scss'

const TermsAndConditions = () => {
  const email = 'ku.gro.p*ur|*eyw*al@of*n|i'

  const formatT = (txt) => {
    return txt
      .replace(new RegExp(/\|/, 'g'), '')
      .replace(new RegExp(/\*/, 'g'), '')
      .split('')
      .reverse()
      .join('')
  }

  return (
    <div className='p-termsAndPrivacy'>
      <div className='container'>
        <div className='content'>
          <p>
            <b className='center'>ECLATECH LIMITED T/A LAWYERUP</b>
            <b className='center'>TERMS AND CONDITIONS OF USE FOR USERS</b>
          </p>
          <p>
            <b>
              THESE TERMS AND CONDITIONS (&quot;TERMS&quot;) SET OUT THE TERMS
              AND CONDITIONS ON WHICH ECLATECH LIMITED T/A LAWYERUP
              (&quot;ECLATECH&quot;; &quot;WE&quot;; &quot;US&quot;) WITH
              COMPANY NUMBER 11777022 AND HAVING ITS REGISTERED ADDRESS AT 4 OLD
              PARK LANE, MAYFAIR, LONDON, UNITED KINGDOM, W1K 1QW SHALL OFFER
              ACCESS TO ITS WEBSITE WWW.LAWYERUP.ORG.UK (&quot;WEBSITE&quot;) TO
              YOU THE USER OR CLIENT (&quot;YOU&quot; OR USER&quot;) SEEKING TO
              CONNECT WITH LAWYERS TO OBTAIN SUCH LEGAL SERVICES
              (&quot;LAWYERS&quot;).
            </b>
          </p>
          <p>
            <b>
              PLEASE READ THESE TERMS VERY CAREFULLY BEFORE USING THE WEBSITE.
              YOU ACKNOWLEDGE THAT BY ACCESSING THIS WEBSITE YOU AGREE TO BE
              BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS AND
              CONDITIONS, DO NOT CONTINUE WITH YOUR USE OF THE WEBSITE.
            </b>
          </p>
          <p className='gray'>
            IMPORTANT NOTICE: For the actual provision of services listed on
            this Website by a Lawyer, Users (which includes any Lawyer acting in
            the capacity as a recipient of services) will enter into a contract
            directly with the Lawyer providing the services. Eclatech is not a
            party to that contract, and Users and Lawyers will be separately
            responsible and liable to one another under the terms of that
            contract. Eclatech simply connects Users and Lawyers through the
            Website.
          </p>
          <p>
            <b>
              Please note that these Terms only apply to our Users; if you are a
              Lawyer, please refer to terms set out in the lawyer registration
              or contact us at info@lawyerup.org.uk.
            </b>
          </p>

          <p>
            <b>A. DEFINITIONS</b>
          </p>
          <ul>
            <li>
              <b>&quot;User(s)&quot;</b> means a person who uses
              www.lawyerup.org.uk website or Lawyerup IOS or Andriod app.
            </li>
            <li>
              <b>&quot;Services&quot;</b> include but are not limited to:
              <ul>
                <li>Search</li>
                <li>Call</li>
                <li>Video Chat</li>
                <li>Message</li>
                <li>Send files (pictures, documents, voicenotes..etc)</li>
                <li>Booking / Scheduling meetings</li>
                <li>Receiving contact from clients</li>
                <li>Responding to Users</li>
                <li>Using maps</li>
              </ul>
            </li>
            <li>
              <b>&quot;Profile&quot;</b> means a lawyer’s public page on
              Lawyerup containing:
              <ul>
                <li>Their Picture</li>
                <li>Their name</li>
                <li>Their law firm name</li>
                <li>Their law firm address</li>
                <li>Their practise areas of expertise</li>
                <li>Their availability for meetings</li>
                <li>Their hourly rates</li>
                <li>A map showing the location of their office</li>
                <li>Users rating and feedback</li>
                <li>
                  Contact functions for Users to get in touch (Call, Video,
                  Message, Meeting)
                </li>
                <li>
                  Other documents, and/or information about the Lawyers’ quotes
                  for the provision of service
                </li>
                <li>
                  And any other such information as we see relevant from time to
                  time
                </li>
              </ul>
            </li>
            <li>
              <b>&quot;Contact Request&quot;</b> is when a user clicks one of
              the contact functions on www.lawyerup.org.uk website or Lawyerup
              IOS or Andriod app:
              <ul>
                <li>Call</li>
                <li>Video</li>
                <li>Message</li>
                <li>Meeting</li>
                <li>
                  And any other function that may be made available from time to
                  time
                </li>
              </ul>
            </li>
            <li>
              <b>&quot;Login Details&quot;</b> means:
              <ul>
                <li>Username (the email you registered)</li>
                <li>Password (the password you chose when you registered)</li>
              </ul>
            </li>
            <li>
              <b>&quot;Converted Client&quot;</b> means a user that has been
              provided legal services for and received an invoice for the legal
              services provided.
            </li>
            <li>
              <b>&quot;Intellectual Property Rights&quot;</b> means all
              registered or unregistered intellectual property rights throughout
              the world, including: rights in patents rights, copyrights,
              trademarks rights, rights in trade secrets, designs, databases
              rights, domain names, designs, and moral rights, and any other
              intellectual property rights (registered or unregistered)
              throughout the world.
            </li>
            <li>
              <b>&quot;Force Majeure Events&quot;</b> means one or more of the
              following causes which renders the Services impossible, extremely
              impracticable, or unsafe: fire; threat(s) or act(s) of terrorism;
              riot(s) or other form(s) of civil disorder in, around, or near the
              performance of the Services; strike, lockout, or other forms of
              labour difficulties; any act, order, rule, or regulation of any
              court, government agency, or public authority; act of God; absence
              of power or other essential services; failure of technical
              facilities; and/or any similar or dissimilar cause beyond
              Eclatech&apos;s reasonable control.
            </li>
            <li>
              <b>&quot;Subsequent Request(s)&quot;</b> means a request for
              advice or assistance with Legal Services which may include (a)
              further advice in relation to the same matter as the Contact
              Request; or (b) relate to any new matter.
            </li>
            <li>
              <b>&quot;Payment Portal&quot;</b> is the online gateway where
              payment information is stored, and payment functions are enabled.
              This is located after Login, within the Menu, on Payments.
            </li>
          </ul>
          <ol>
            <li>
              <b>INFORMATION ABOUT ECLATECH AND THE WEBSITE</b>
              <ol>
                <li>
                  The Website is owned and managed, operated and maintained by
                  Eclatech LIMITED.
                </li>
                <li>
                  Eclatech may be contacted at 4 Old Park Lane, Mayfair, London,
                  United Kingdom, W1K 1QW or by email at {formatT(email)}.
                </li>
                <li>
                  The Website is an online network that provides a means for
                  Lawyers to connect with Users who could potentially become
                  Converted Clients. The features and functionality of
                  Lawyerup&apos;s platform offer Services to lawyers, Users and
                  Converted Clients.
                  <ol>
                    <li>
                      Lawyers can create a Profile to describe themselves and
                      their business, which is accessible by Users.
                    </li>
                    <li>
                      Users can contact Lawyers via a Contact Request in order
                      to seek advice or assistance with a case or any other
                      Legal Services (&quot;Legal Services&quot;) in respect of
                      which they are looking to engage a Lawyer.
                    </li>
                    <li>
                      Lawyers can then respond to the User by answering their
                      Contact Request and agreeing to perform the legal services
                      for the User.
                    </li>
                    <li>
                      Following a Contact Request, Users or Converted Clients
                      can continue to contact the Lawyer via their office
                      telephone number, messaging, scheduling a meeting or
                      otherwise on lawyerup’s platform or directly in order to
                      request advice or assistance with legal services which may
                      include (a) further advice in relation to the same matter
                      as the Contact Request; or (b) relate to any new matter
                      (each, a &quot;Subsequent Request&quot;).
                    </li>
                    <li>
                      Lawyers can make payments to Eclatech for Fees via the
                      Payment Portal; and
                    </li>
                    <li>
                      Users can provide feedback and rate the performance of
                      Lawyers by providing reviews by email.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <b>PRELIMINARY INFORMATION</b>
              <ol>
                <li>
                  By using the Website, you warrant that:
                  <ol>
                    <li>
                      you are legally capable of entering into binding
                      contracts;
                    </li>
                    <li>
                      you are not in any way prohibited by the applicable law in
                      the jurisdiction in which you are currently located to
                      enter into these Terms; and
                    </li>
                    <li>
                      if an individual, you are at least 18 years old. If you
                      are under the age of 18, you should ask a parent or
                      guardian over the age of 18 to enter into these Terms on
                      your behalf. As a parent or guardian of someone under the
                      age of 18, you are responsible for ensuring that
                      person&apos;s use of the Website and/or Services is in
                      accordance with these Terms.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <b>USE OF THE WEBSITE</b>
              <ol>
                <li>
                  You agree that at all times, you shall:
                  <ol>
                    <li>
                      not use the information presented on the Website and/or
                      App derived from the Services for any purposes other than
                      those expressly set out in these Terms;
                    </li>
                    <li>
                      not do anything likely to impair, interfere with or damage
                      or cause harm or distress to any persons using the Website
                      and/or App in respect of the network;
                    </li>
                    <li>
                      not use the Website, the content therein and/or do
                      anything that will infringe anyone’s Intellectual Property
                      Rights as defined in clause 9 or other rights of any third
                      parties;
                    </li>
                    <li>
                      comply with all instructions and policies from Eclatech
                      from time to time in respect of the Services and the
                      Website;
                    </li>
                    <li>
                      co-operate with any reasonable security or other checks or
                      requests for information made by Eclatech from time to
                      time; and
                    </li>
                    <li>
                      use the information made available to you on the Website
                      and/or App through the Services at your own risk.
                    </li>
                  </ol>
                </li>
                <li>
                  Notwithstanding the foregoing, Eclatech reserves the right to
                  refuse you access to the Services and/or Website and/or App
                  (partly or wholly) if you breach any of the provisions of
                  these Terms.
                </li>
              </ol>
            </li>
            <li>
              <b>YOUR OBLIGATIONS</b>
              <ol>
                <li>
                  You are solely responsible and liable for all activities You
                  undertake on the Website and/or App.
                </li>

                <li>
                  You must comply with all applicable law in respect of your use
                  of the Website and/or App and the Services including, without
                  limitation, compliance with all applicable e-commerce
                  directives, consumer protection legislation and data
                  protection and privacy directives.
                </li>

                <li>
                  When submitting a Contact Request, you must ensure the Contact
                  Request contains all information that is reasonably necessary
                  for a Lawyer to fully and accurately respond to the Request,
                  including:
                  <ol>
                    <li>your identity and contact details;</li>
                    <li>
                      the Lawyer you are seeking, including without limitation
                      the nature of the work and duties, the location and the
                      duration or likely duration for the work;
                    </li>
                    <li>
                      any health and safety risks known to you and the steps
                      which you and the steps which you have taken to either
                      prevent or control such risks; and
                    </li>
                    <li>
                      the payment and expenses (if any) you will be paying to
                      the Lawyer.
                    </li>
                  </ol>
                </li>

                <li>
                  You warrant and represent that the information provided by you
                  pursuant to clause 4.3 and in respect of any Request shall be
                  correct, complete, accurate and up to date.
                </li>

                <li>
                  As a User, you are solely responsible for:
                  <ol>
                    <li>the management of your search for a Lawyer;</li>
                    <li>ascertaining the identity of the Lawyer(s);</li>
                    <li>
                      ensuring that a Lawyer has the qualification, skills,
                      training and experience required by you, whether or not
                      such details are listed by you on any Request;
                    </li>
                    <li>
                      ascertaining specific professional requirements, if any,
                      you may require a Lawyer to possess as set out in a
                      Request;
                    </li>
                    <li>
                      verification of all information provided by any Lawyer
                      including without limitation any Lawyer Profile and all
                      supporting Documentation, including to confirm the
                      authenticity of the Lawyer’s professional indemnity
                      insurance documents or any other documentation that may be
                      uploaded to the Website and/or App; and
                    </li>
                    <li>
                      ensuring that a Lawyer has the right to work in the
                      country in which the Request will be carried out, when
                      deciding to engage with a Lawyer found through the Website
                      or App, and it is your responsibility to carry out
                      whatever enquiries you deem to be necessary.
                    </li>
                  </ol>
                </li>
                <li>
                  In using the Services, you must:
                  <ol>
                    <li>
                      not provide information (including in any Request, reviews
                      and/or any communication with Lawyers) which you know to
                      be inaccurate, false, incomplete, untrue or is or may be
                      deemed to be a misrepresentation of the facts;
                    </li>
                    <li>
                      immediately notify Eclatech in the event you have any
                      reason to believe or suspect that a Lawyer or another User
                      has breached any of its Terms or that Lawyer Profile is
                      not genuine, or is false, inaccurate and/or incomplete.
                    </li>
                  </ol>
                </li>
                <li>
                  You acknowledge and agree that Requests will be viewable by
                  any users of the Website and/or App (including all Lawyers).
                </li>
                <li>
                  You shall not submit to appear on the Website or otherwise
                  through your use of the Services any information, comments,
                  images, third party URL links or other material whatsoever in
                  any format (&quot;User Submissions&quot;), such term to
                  include information, details, descriptions or materials
                  submitted by Lawyers in relation to discussions or projects
                  and information, details, descriptions and materials submitted
                  by Users posting Requests, that may reasonably be deemed to:
                  <ol>
                    <li>be offensive, illegal, inappropriate or in any way;</li>
                    <li>
                      promote racism, bigotry, hatred or physical harm of any
                      kind against any group or individual;
                    </li>
                    <li>harass or advocate harassment of another person;</li>
                    <li>display pornographic or sexually explicit material;</li>
                    <li>
                      promote any conduct that is abusive, threatening, obscene,
                      defamatory or libellous;
                    </li>
                    <li>promote any illegal activities;</li>
                    <li>
                      provide instructional information about illegal
                      activities, including violating someone else’s privacy or
                      providing or creating computer viruses;
                    </li>
                    <li>
                      promote or contain information that you know or believe to
                      be inaccurate, false or misleading;
                    </li>
                    <li>
                      engage in or promote commercial activities and/or sales
                      not related to the Lawyer Services, including but not
                      limited to contests, sweepstakes, barter, advertising and
                      pyramid schemes, without the prior written consent of
                      Eclatech; or
                    </li>
                    <li>
                      infringe any rights of any third party; <br />
                      and YOU HEREBY INDEMNIFY ECLATECH FOR ANY BREACH BY YOU OF
                      THIS CLAUSE 4.6
                    </li>
                  </ol>
                </li>
                <li>
                  Unless otherwise explicitly stated by us, Eclatech does not
                  vet, verify the accuracy, correctness and completeness, edit
                  or modify any User Submissions or any other information, data
                  and materials created, used and/or published by you to
                  determine whether they may result in any liability to any
                  third party. You hereby warrant that you have the rights to
                  use all such information, data and material in the manner set
                  out in these Terms. WE EXPRESSLY EXCLUDE OUR LIABILITY FOR ANY
                  LOSS OR DAMAGE ARISING FROM THE USE OF OUR SERVICE BY A USER
                  OR A LAWYER IN CONTRAVENTION OF THIS CLAUSE 4.
                </li>
                <li>
                  Regardless of clause 4.7 above, you acknowledge that making a
                  User Submission does not guarantee that such User Submission,
                  or any part thereof, shall appear on the Website and/or App,
                  whether or not the submission of such User Submission is part
                  of the Services. You agree that Eclatech may, at its sole
                  discretion, choose to display any User Submission or any part
                  of the same that you make on the Website and/or App, or to
                  remove them as we deem necessary or appropriate.
                </li>
                <li>
                  If you have reason to believe that another user of the Website
                  has not followed or complied with their obligations in these
                  Terms, or you have a complaint to make, please e-mail us at:
                  info@lawyerup.org.uk or write to: Eclatech Limited, 4 Old Park
                  Lane, Mayfair, London, W1K1QW, or by using the &quot;Contact
                  Us&quot; form available on the Website. Please provide full
                  details of the nature of your complaint and the materials to
                  which the complaint relates. We will use our reasonable
                  endeavours to respond to all complaints within a reasonable
                  time and to take reasonable action which we deem appropriate
                  to resolve or rectify the subject matter of such complaints.
                </li>
                <li>
                  In the event that Eclatech, in its sole and absolute
                  discretion, considers that there has been a breach or
                  threatened breach of any of the terms set out in this clause
                  4, Eclatech reserves the right to take any action that it
                  deems to be necessary, including without limitation, temporary
                  suspension, or termination forthwith and without notice of
                  your use of and access to the Services and the Website and/or
                  App; and in the case of illegal use, the instigation of legal
                  proceedings as appropriate.
                </li>
              </ol>
            </li>
            <li>
              <b>TERMINATION</b>
              <ol>
                <li>
                  You or Eclatech may terminate these Terms at any time and for
                  any reason whatsoever by giving the other party not less than
                  fourteen (14) days’ notice in writing.
                </li>
                <li>
                  Notwithstanding the provisions of this clause 5, these Terms
                  may be terminated by either you or us immediately, on written
                  notice to the other party:
                  <ol>
                    <li>
                      if the other party is in material breach of its
                      contractual obligations and has not remedied such breach
                      within fourteen (14) days after receipt of a written
                      notice of default from the other party. For the avoidance
                      of doubt, and without limitation, breach of any provision
                      under clause 4 is a material breach for these purposes; or
                    </li>
                    <li>
                      by either party if the other is declared bankrupt, or if a
                      body corporate, has a liquidator, receiver or
                      administrator appointed or enters into any form of
                      arrangement with its creditors, or is otherwise insolvent.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <b>EXCLUSION OF WARRANTIES & LIABILITY</b>
              <ol>
                <li>
                  All warranties, representations, guarantees, conditions and
                  terms, other than those expressly set out in these Terms
                  whether express or implied by statute, common law, trade usage
                  or otherwise and whether written or oral are hereby expressly
                  excluded to the fullest extent permissible by law.
                </li>
                <li>
                  You acknowledge and agree that the Services provided by
                  Eclatech are limited to providing you with a facility to
                  attempt to source and connect with Lawyers. When you use the
                  User Services, Eclatech does not guarantee that you will:
                  <ol>
                    <li>
                      find Lawyers suitable to your specific requirements;
                    </li>
                    <li>
                      secure responses or any communication from Lawyers; or
                    </li>
                    <li>achieve any specific results whatsoever.</li>
                  </ol>
                </li>
                <li>
                  Eclatech makes no warranties and assumes no responsibility for
                  verifying the credentials, standards or reputation of the
                  Lawyers and you acknowledge and agree that it is the entire
                  responsibility of you, the User, to carry out whatever
                  enquiries you deem necessary to so verify that any information
                  provided by the Lawyer or listed on the Website (including the
                  Lawyers’ Profile and User Submissions, Responses or any
                  information provided or comments made by Lawyers in their
                  communication with you) is true, accurate, complete and
                  up-to-date. In particular, Eclatech does not warrant or
                  guarantee:
                  <ol>
                    <li>
                      the currentness, completeness, correctness and accuracy of
                      any Lawyer Profile;
                    </li>
                    <li>
                      that any information or documentation made available on
                      Lawyer’s messaging chat or in a Request Response is
                      authentic, valid, accurate or otherwise complete; Or
                    </li>
                    <li>
                      the identity of the Lawyers using the Website and/or App.
                    </li>
                  </ol>
                </li>

                <li>
                  Eclatech shall bear no responsibility or liability for
                  inspecting or verifying any material posted by any User.
                  Consequently, Eclatech does not warrant or guarantee the
                  accuracy, correctness, reliability, suitability or at all in
                  respect of any such User Submission. Commentary and other
                  materials posted on the Website are not intended to amount to
                  advice on which reliance should be placed. We therefore
                  disclaim all liability and responsibility arising from any
                  reliance placed on such materials by any visitor to our site
                  and/or App, or by anyone who may be informed of any of its
                  contents.
                </li>
                <li>
                  Subject to clause 6.7, liability of which shall remain
                  unlimited, the maximum aggregate liability of Eclatech
                  (including its respective agents and sub-contractors) under,
                  arising from or in connection with these Terms, whether
                  arising in contract, tort (including negligence) or otherwise,
                  shall not exceed £100.
                </li>
                <li>
                  We shall not be responsible for any:
                  <ol>
                    <li>
                      losses, damages, costs and expenses which were not
                      reasonably foreseeable to be incurred by you and could not
                      have been reasonably foreseeable by you or us on entering
                      these Terms; or
                    </li>
                    <li>
                      loss of profits, contracts or business, loss of an
                      anticipated outcome or anticipated savings.
                    </li>
                  </ol>
                </li>
                <li>
                  We accept and do not limit Our liability to you for the
                  following categories:
                  <ol>
                    <li>death or personal injury;</li>
                    <li>
                      negligent misrepresentation, fraud or fraudulent
                      misrepresentation;
                    </li>
                    <li>
                      any breach of the obligations implied by section 12 of the
                      Sale of Goods Act 1979 or section 2 of the Supply of Goods
                      and Services Act 1982.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <b>SUSPENSION AND CHANGES TO TERMS & SERVICES</b>
              <ol>
                <li>
                  Eclatech shall be entitled at its own discretion and upon
                  notice to you, to suspend the Services and/or the Website
                  whether wholly or partly for any reason whatsoever including
                  but not limited to repairs, planned maintenance or upgrades
                  and shall not be liable to you for any such suspension or
                  non-availability of the Services and/or the Website (whether
                  wholly or partly).
                </li>
                <li>
                  Eclatech reserves the right to make any changes to the
                  Services and/or the Website including any functionalities and
                  content therein or to discontinue a specific feature from time
                  to time subject always that:
                  <ol>
                    <li>
                      Eclatech notifies you in writing through a message
                      displayed on the Website and/or App; and
                    </li>
                    <li>
                      such changes have no material adverse effects to you.
                    </li>
                  </ol>
                </li>
                <li>
                  Eclatech may update or revise these Terms from time to time
                  and shall notify you of any such updates and revisions through
                  a message displayed on the Website and/or App. Your continued
                  use of the Services and/or the Website (whether wholly or
                  partly) shall be deemed your acceptance of such change(s) in
                  respect of the updated or revised Terms. If you do not agree
                  with the changes to these Terms, you may terminate your
                  Contract with us in accordance with clause 5.
                </li>
              </ol>
            </li>
            <li>
              <b>PROBLEMS & DISPUTES</b>
              <ol>
                <li>
                  Eclatech is not responsible in any way for the provision of
                  the Lawyer’s services, which are to be provided by the Lawyer
                  on terms and conditions as may be agreed between you and the
                  Lawyer.
                </li>
                <li>
                  In the event there is a dispute between you and the Lawyer, or
                  the Lawyer’s services are not provided to a satisfactory
                  standard, you agree Eclatech is not liable for any loss or
                  damage suffered by you in the course of receiving the Lawyer’s
                  Services. TO THE EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE
                  AND HOLD HARMLESS ECLATECH FROM ANY SUCH LOSS OR DAMAGE OR ANY
                  LIABILITY IN RELATION TO SUCH DISPUTE.
                </li>
              </ol>
            </li>
            <li>
              <b>INTELLECTUAL PROPERTY RIGHTS</b>
              <ol>
                <li>
                  Eclatech and its licensors own all the Intellectual Property
                  Rights relating to the Services and the Website and/or App,
                  save for those that fall under clause 9.3 below. Nothing in
                  these Terms shall serve to transfer from Eclatech to any User
                  or Lawyer any of the Intellectual Property Rights in the
                  Website.
                </li>
                <li>
                  You are expressly prohibited from:
                  <ol>
                    <li>
                      reproducing, copying, editing, transmitting, uploading or
                      incorporating into any other materials, any of the Website
                      and App; and
                    </li>
                    <li>
                      removing, modifying, altering or using any registered or
                      unregistered marks/logos/designs owned by us or our
                      licensors, and doing anything which may be seen to take
                      unfair advantage of the reputation and goodwill of
                      Eclatech or could be considered an infringement of any of
                      the rights in the Intellectual Property Rights owned by
                      and/or licensed to Eclatech.
                    </li>
                  </ol>
                </li>
                <li>
                  All rights, title and interest in any User Submissions shall
                  remain exclusively with the relevant user who posted such User
                  Submission.
                </li>
                <li>
                  You hereby grant Eclatech a non-exclusive, perpetual,
                  irrevocable, worldwide licence to make User Submissions
                  available on the Website, and to use such User Submissions for
                  our promotional and marketing purposes.
                </li>
                <li>
                  Where the Website and App contain links to other sites and
                  resources provided by third parties, these links are provided
                  for your information only. We have no control over the
                  contents of those sites or resources, and accept no
                  responsibility for them or for any loss or damage that may
                  arise from your use of them.
                </li>
              </ol>
            </li>
            <li>
              <b>CONFIDENTIALITY</b>
              <p>
                You shall not, during the term in which these Terms apply or
                after such term has ended, (except as required by law), disclose
                to any person any confidential information or trade secrets
                relating to Eclatech’s business. Such matters include, without
                limitation, information or secrets relating to: corporate and
                marketing strategy, business development and plans, sales
                reports and research results, business methods and processes,
                technical information and know-how relating to Eclatech’s
                business and which is not in the public domain, including
                inventions, designs, programs, techniques, database systems,
                formulae and ideas, business contacts, lists of Users or
                Lawyers, Users, Lawyers and companies and details of contracts
                with them, and any document marked &quot;confidential&quot;.
                This restriction shall not apply during or after these Terms
                have terminated to information which has become available to the
                public generally otherwise than through unauthorised disclosure.
              </p>
            </li>
            <li>
              <b>PRIVACY</b>
              <p>
                Eclatech complies with the EU General Data Protection Regulation
                2016/679 and the UK Data Protection Act 2018 (as applicable) and
                all other successor legislation and regulations in the
                performance of its obligations under these Terms. Eclatech
                collects and processes your personal data in accordance with its
                Privacy Policy which is available by clicking
                https://www.lawyerup.org.uk/privacy_policy. The terms of the
                Privacy Policy form part of these Terms and you agree to be
                bound by them.
              </p>
            </li>
            <li>
              <b>GENERAL</b>
              <ol>
                <li>
                  If Eclatech fails at any time to insist upon strict
                  performance of its obligations under these Terms, or if it
                  fails to exercise any of the rights or remedies to which it is
                  entitled under these Terms, this will not constitute a waiver
                  of any such rights or remedies and shall not relieve you from
                  compliance with such obligations. No waiver by Eclatech of any
                  of these Terms shall be effective unless it is expressly
                  stated to be a waiver and is communicated to you in writing.
                </li>
                <li>
                  Any requirement for written notice to be provided under these
                  Terms may be satisfied by sending the notice by registered
                  post or email to, in the case of Eclatech, the postal address
                  or email address as set out in these Terms, or in the case of
                  a User, to the email address provided by that User.
                </li>
                <li>
                  If any of these Terms is determined by any competent authority
                  to be invalid, unlawful or unenforceable to any extent, such
                  term, condition or provision will to that extent be severed
                  from the remaining terms, conditions and provisions which
                  shall continue to be valid to the fullest extent permitted by
                  law.
                </li>
                <li>
                  Nothing in these Terms shall give, directly or indirectly, any
                  third party any enforceable benefit or any right of action
                  against Eclatech and such third parties shall not be entitled
                  to enforce any of these Terms against Eclatech.
                </li>
                <li>
                  We will not be liable or responsible for any failure to
                  perform, or delay in performance of, any of our obligations
                  under these Terms that is caused by Force Majeure Events. We
                  will use our reasonable endeavours to bring the Force Majeure
                  Event to a close or to find a solution by which our
                  obligations under these Terms may be performed despite the
                  Force Majeure Event.
                </li>
                <li>
                  These Terms and any document expressly referred to in them
                  represent the entire agreement between you and Eclatech in
                  respect of your use of the Website and/or App, and shall
                  supersede any prior agreement, understanding or arrangement
                  between you and Eclatech, whether oral or in writing.
                </li>
                <li>
                  These Terms are governed by and construed in accordance with
                  English law. The Courts of England and Wales shall have
                  exclusive jurisdiction over any disputes arising out of these
                  Terms.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
