import React, { useEffect, useState } from 'react'

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import Geocode from 'react-geocode'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from 'react-google-maps'

import useDebounce from 'hooks/useDebounce'
import useLawyerRegistration from 'hooks/useLawyerRegistration'
import { ReactComponent as AddressIcon } from 'images/icon-lawyer-address.svg'
import { ReactComponent as EmployeesIcon } from 'images/icon-lawyer-employees.svg'
import { ReactComponent as FirmIcon } from 'images/icon-lawyer-firm.svg'
import mapMarker from 'images/map-marker.png'

Geocode.setApiKey('AIzaSyDhUB5zHmeVBAaVeizpfFhCgeoAWQ0Vi9s')

const MapWithAMarker = withScriptjs(
  withGoogleMap((props) => {
    const [formValues, setFormValues] = useLawyerRegistration()

    const handleDragEnd = ({ latLng }) => {
      setFormValues({
        ...formValues,
        latitude: latLng.lat(),
        longitude: latLng.lng(),
      })
    }

    return (
      <GoogleMap
        defaultOptions={{
          mapTypeControl: false,
          streetViewControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
        defaultZoom={18}
        defaultCenter={{ lat: formValues.latitude, lng: formValues.longitude }}
        center={{ lat: formValues.latitude, lng: formValues.longitude }}
      >
        <Marker
          draggable
          position={{ lat: formValues.latitude, lng: formValues.longitude }}
          onDragEnd={handleDragEnd}
          options={{ icon: mapMarker }}
        />
      </GoogleMap>
    )
  })
)

const ProfileAddress = ({ errors }) => {
  const [formValues, setFormValues] = useLawyerRegistration()
  const debounceAddress = useDebounce(formValues.address, 1000)
  const [stepErros, setStepErros] = useState([])

  useEffect(() => {
    setStepErros(errors)
  }, [errors])

  const handleFormValues = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
    if (stepErros.includes(e.target.name)) {
      const tempErr = stepErros
      const errIndex = stepErros.indexOf(e.target.name)
      tempErr.splice(errIndex, 1)
      setStepErros(tempErr)
    }
  }

  const handleGetLatLng = async () => {
    if (formValues.address) {
      Geocode.fromAddress(formValues.address).then((response) => {
        const { lat, lng } = response.results[0].geometry.location
        setFormValues({ ...formValues, latitude: lat, longitude: lng })
      })
    }
  }

  useEffect(() => {
    handleGetLatLng()
  }, [debounceAddress]) // eslint-disable-line

  return (
    <div>
      <div className='-half'>
        <FormControl component='div' fullWidth variant='filled'>
          <TextField
            required
            error={stepErros.includes('lawFirmName')}
            label='Law ﬁrm name'
            variant='filled'
            name='lawFirmName'
            value={formValues.lawFirmName}
            onChange={handleFormValues}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <FirmIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl component='div' fullWidth variant='filled'>
          <TextField
            required
            error={stepErros.includes('numberOfEmployees')}
            label='How many people work at the ﬁrm'
            variant='filled'
            name='numberOfEmployees'
            value={formValues.numberOfEmployees}
            onChange={handleFormValues}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <EmployeesIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <FormControl component='div' fullWidth variant='filled'>
        <TextField
          required
          error={stepErros.includes('address')}
          label='Enter your Address'
          variant='filled'
          name='address'
          value={formValues.address}
          onChange={handleFormValues}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position='end'>
                <AddressIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl component='div' fullWidth>
        <div style={{ borderRadius: 5, overflow: 'hidden' }}>
          <MapWithAMarker
            googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDhUB5zHmeVBAaVeizpfFhCgeoAWQ0Vi9s&v=3.exp&libraries=geometry,drawing,places'
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '300px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        </div>
        <FormHelperText>
          You can move the marker on the map to define a closer location
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default ProfileAddress
