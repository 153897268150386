import React from 'react'

import Avatar from 'react-avatar'

import PanelContainer from 'components/atoms/PanelContainer'
import Contact from 'components/molecules/ClientProfileItems/Contact'
import Notifications from 'components/molecules/ClientProfileItems/Notifications'
import RemoveAccount from 'components/molecules/ClientProfileItems/RemoveAccount'
import SocialLinks from 'components/molecules/ClientProfileItems/SocialLinks'
import useUserInfo from 'hooks/useUserInfo'

import './ClientProfile.scss'

const ClientProfile = () => {
  const [userInfo] = useUserInfo()

  return (
    <PanelContainer>
      <div className='o-clientProfile'>
        <div className='o-clientProfile__container'>
          <div className='o-clientProfile__aside'>
            <div className='o-clientProfile__aside--box -center'>
              <div className='o-clientProfile__aside--box-avatar'>
                <span className='-indicator' />
                <Avatar
                  size='150'
                  maxInitials={2}
                  round='100%'
                  color='#26395A'
                  textSizeRatio={2.5}
                  src={userInfo.photoURL}
                  name={userInfo.displayName}
                />
              </div>
              <strong className='o-clientProfile__aside--box-name'>
                {`${userInfo.displayName}`}
              </strong>
            </div>
            <Contact />
          </div>
          <div className='o-clientProfile__content'>
            <SocialLinks />
            <Notifications />
            <RemoveAccount />
          </div>
        </div>
      </div>
    </PanelContainer>
  )
}

export default ClientProfile
