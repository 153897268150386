import React from 'react'

import { ReactComponent as CloseIcon } from 'images/icon-close-modal.svg'
import offerVideo from 'images/send-an-offer.mp4'

import './offerVideo.scss'

const OfferVideo = ({ handleCloseModal }) => {
  return (
    <div className='m-offerVideo'>
      <div className='m-offerVideo__content'>
        <div className='m-offerVideo__content--video'>
          <button onClick={() => handleCloseModal(false)}>
            <CloseIcon />
          </button>
          <video
            autostart
            autoPlay
            src={offerVideo}
            type='video/mp4'
            width='100%'
            height='100%'
            controls
            controlsList='nodownload'
            disablePictureInPicture
          />
        </div>
      </div>
    </div>
  )
}

export default OfferVideo
