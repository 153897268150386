import React from 'react'

import { toast } from 'react-toastify'

import { useCases } from 'context/cases'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'

const RemoveOffer = ({ caseData, handleCloseModal }) => {
  const { removeOffer } = useCases()
  const doUpdateCaseById = useUpdateCaseById()
  const doAddCaseSystemMessage = useAddCaseSystemMessage()

  const handleRemoveOffer = () => {
    doUpdateCaseById(caseData.caseId, {}, true)
      .then(() => {
        doAddCaseSystemMessage(
          caseData.caseId,
          caseData.senderUid,
          caseData.receiverUid,
          {
            [caseData.senderUid]: 'Your offer has been withdrawn.',
            [caseData.receiverUid]: 'The lawyer withdrew the offer.',
          },
          'message'
        )
        removeOffer(caseData.caseId)
        toast.success('Offer successfully withdrawn!')
        handleCloseModal(null, null)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  return (
    <div className='a-modal__removeOffer'>
      <p>Are you sure you want to withdraw this offer?</p>

      <div className='a-modal__actions'>
        <button
          className='a-modal__actions--cancelButton'
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          className='a-modal__actions--submitButton'
          onClick={handleRemoveOffer}
        >
          Withdraw Offer
        </button>
      </div>
    </div>
  )
}

export default RemoveOffer
