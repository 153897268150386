import React, { useEffect, useState } from 'react'

import Geocode from 'react-geocode'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import { RiMapPinLine, RiPencilFill, RiCheckFill } from 'react-icons/ri'

import WhiteBox from 'components/atoms/WhiteBox'
import useDebounce from 'hooks/useDebounce'
import useUserInfo from 'hooks/useUserInfo'
import mapMarker from 'images/map-marker.png'
import { GeoPoint } from 'services/firebase'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

Geocode.setApiKey('AIzaSyDhUB5zHmeVBAaVeizpfFhCgeoAWQ0Vi9s')

const MapWithAMarker = withScriptjs(
  withGoogleMap(({ setLocation, location }) => {
    const handleDragEnd = ({ latLng }) => {
      setLocation({ latitude: latLng.lat(), longitude: latLng.lng() })
    }

    return (
      <GoogleMap
        defaultOptions={{
          mapTypeControl: false,
          streetViewControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
        defaultZoom={16}
        defaultCenter={{ lat: location.latitude, lng: location.longitude }}
        center={{ lat: location.latitude, lng: location.longitude }}
      >
        <Marker
          draggable
          position={{ lat: location.latitude, lng: location.longitude }}
          onDragEnd={handleDragEnd}
          options={{ icon: mapMarker }}
        />
      </GoogleMap>
    )
  })
)

const Address = () => {
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo.address)
  const [location, setLocation] = useState({
    latitude: userInfo.l.u_,
    longitude: userInfo.l.h_,
  })
  const debounceAddress = useDebounce(value, 1500)

  const handleEdit = () => {
    if (edit) {
      doUpdateUserProfile({
        address: value,
        l: new GeoPoint(location.latitude, location.longitude),
      })
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  const handleGetLatLng = async () => {
    if (value && edit) {
      Geocode.fromAddress(value).then((response) => {
        const { lat, lng } = response.results[0].geometry.location
        setLocation({ latitude: lat, longitude: lng })
      })
    }
  }

  useEffect(() => {
    handleGetLatLng()
  }, [debounceAddress]) // eslint-disable-line

  return (
    <WhiteBox
      withOpacity
      title='Address'
      icon={<RiMapPinLine />}
      actions={
        <button
          onClick={handleEdit}
          type='button'
          className={edit ? '-editing' : ''}
        >
          {edit ? <RiCheckFill /> : <RiPencilFill />}
        </button>
      }
    >
      {edit ? (
        <>
          <input
            type='text'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className='map'>
            <div
              style={{
                borderRadius: 5,
                overflow: 'hidden',
                marginTop: 15,
                boxShadow: '0 3px 29px rgba(0, 0, 0, 0.1)',
              }}
            >
              <MapWithAMarker
                setLocation={setLocation}
                location={location}
                googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDhUB5zHmeVBAaVeizpfFhCgeoAWQ0Vi9s&v=3.exp&libraries=geometry,drawing,places'
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '300px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
              />
            </div>
            <small>
              You can move the marker on the map to define a closer location
            </small>
          </div>
        </>
      ) : (
        <span>{value}</span>
      )}
    </WhiteBox>
  )
}

export default Address
