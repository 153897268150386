import { useState, useEffect } from 'react'

import { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video'

type TrackType = LocalVideoTrack | RemoteVideoTrack

export default function useVideoTrackDimensions(track?: TrackType) {
  // @ts-ignore
  const [dimensions, setDimensions] = useState(track.dimensions)

  useEffect(() => {
    // @ts-ignore
    setDimensions(track.dimensions)

    if (track) {
      // @ts-ignore
      const handleDimensionsChanged = (track: TrackType) =>
        setDimensions(track.dimensions)
      track.on('dimensionsChanged', handleDimensionsChanged)
      return () => {
        track.off('dimensionsChanged', handleDimensionsChanged)
      }
    }
  }, [track])

  return dimensions
}
