import React from 'react'
import './Loader.scss'

interface IProps {
  darker?: boolean
}
const Loader = ({ darker = false }: IProps) => {
  return (
    <div className='loader'>
      <div className={`loader__indicator ${darker ? '-darker' : ''}`} />
    </div>
  )
}

export default Loader
