import { useCallback } from 'react'

import { auth, firestore } from 'services/firebase'

const useGetCaseById = () => {
  const { uid } = auth.currentUser
  return useCallback(async (docId) => {
    return firestore
      .collection('cases')
      .doc(docId)
      .get()
      .then(async (snap) => {
        if (snap) {
          return { ...snap.data(), caseId: docId }
        }
        return null
      })
  }, [])
}

export default useGetCaseById
