import React, { useEffect, useRef, useState } from 'react'

import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import ReactCrop from 'react-image-crop'

import useLawyerRegistration from 'hooks/useLawyerRegistration'
import { ReactComponent as CloseIcon } from 'images/icon-close-modal.svg'
import UploadImage from 'images/icon-upload-image.png'

import 'react-image-crop/dist/ReactCrop.css'
import './ProfilePhoto.scss'

const ProfilePhoto = () => {
  const [formValues, setFormValues] = useLawyerRegistration()

  const inputRef = useRef()
  const [crop, setCrop] = useState({
    aspect: 1,
    unit: '%',
    width: 50,
    x: 25,
    y: 25,
  })
  const [showCamera, setShowCamera] = useState(false)
  const [showCrop, setShowCrop] = useState(false)
  const [originalImage, setOriginalImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [image, setImage] = useState(null)

  const handleSelectPhoto = () => {
    inputRef.current.click()
  }

  const handleUploadImage = (e) => {
    const file = e.target.files
    setPreviewImage(URL.createObjectURL(file[0]))
  }

  const handleTakePhoto = (val) => {
    setShowCamera(false)
    setPreviewImage(val)
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName
          resolve(blob)
        },
        'image/jpeg',
        1
      )
    })
  }

  const onImageLoaded = (image) => {
    setOriginalImage(image)
  }

  const handleSaveImage = async () => {
    if (originalImage && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        originalImage,
        crop,
        `profileImage-${Date.now()}.jpg`
      )
      setImage(croppedImageUrl)
      setPreviewImage(URL.createObjectURL(croppedImageUrl))

      setFormValues({ ...formValues, image: croppedImageUrl })
    }
  }

  const handleChangePhoto = () => {
    setPreviewImage(null)
    setImage(null)
    setOriginalImage(null)
    setShowCrop(false)
    setFormValues({ ...formValues, photoURL: null })
  }

  useEffect(() => {
    if (previewImage) {
      setShowCrop(true)
    }
  }, [previewImage])

  return (
    <div className='o-profilePhoto'>
      <div className='o-profilePhoto__content'>
        {showCamera ? (
          <div className='o-profilePhoto__content--camera'>
            <button
              className='o-profilePhoto__content--camera-close'
              onClick={() => setShowCamera(false)}
            >
              <CloseIcon />
            </button>
            <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri)
              }}
            />
          </div>
        ) : showCrop ? (
          <>
            <div className='o-profilePhoto__content--crop'>
              {!image ? (
                <ReactCrop
                  circularCrop
                  src={previewImage}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  onChange={(newCrop) => setCrop(newCrop)}
                />
              ) : (
                <div className='o-profilePhoto__content--cropped'>
                  <img src={previewImage} alt='' />
                </div>
              )}
              <div className='o-profilePhoto__content--cropActions'>
                <button
                  type='button'
                  onClick={handleChangePhoto}
                  className='o-profilePhoto__content--cropActions-button -changePhoto'
                >
                  Change photo
                </button>
                {!image && (
                  <button
                    type='button'
                    onClick={handleSaveImage}
                    className='o-profilePhoto__content--cropActions-button -savePhoto'
                  >
                    Save photo
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <input
              accept='image/x-png,image/gif,image/jpeg'
              type='file'
              id='file'
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={handleUploadImage}
            />
            <div className='o-profilePhoto__content--image'>
              <img src={UploadImage} alt='' />
            </div>
            <span className='o-profilePhoto__content--text'>
              Update Profile Photo
            </span>
            <div className='o-profilePhoto__content--actions'>
              <button
                type='button'
                onClick={() => setShowCamera(true)}
                className='o-profilePhoto__content--actions-button -takePhoto'
              >
                Take photo
              </button>
              <button
                type='button'
                onClick={handleSelectPhoto}
                className='o-profilePhoto__content--actions-button -uploadPhoto'
              >
                Upload photo
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ProfilePhoto
