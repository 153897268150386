import React, { useEffect, useState } from 'react'

import OwlCarousel from 'react-owl-carousel2'

import 'react-owl-carousel2/src/owl.carousel.css'

import CaseFilters from 'components/atoms/CaseFilters'
import Loader from 'components/atoms/Loader'
import PanelContainer from 'components/atoms/PanelContainer'
import WhiteBox from 'components/atoms/WhiteBox'
import CaseCard from 'components/molecules/CaseCard'
import PanelSidebar from 'components/molecules/PanelSidebar'
import Reviews from 'components/molecules/Reviews'
import { useCases } from 'context/cases'
import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as CompletedCasesIcon } from 'images/icons/cases-completed.svg'
import { ReactComponent as InProgressCasesIcon } from 'images/icons/cases-in-progress.svg'
import { ReactComponent as FolderIcon } from 'images/icons/folder.svg'
import { ReactComponent as DashboardIcon } from 'images/icons/mobile-dashboard.svg'
import { ReactComponent as EarningsIcon } from 'images/icons/money.svg'
import { ReactComponent as StarIcon } from 'images/star.svg'
import useGetCases from 'services/firebase/hooks/cases/useGetCases'
import useGetLawyerReviews from 'services/firebase/hooks/lawyers/useGetLawyerReviews'
import useGetUserByUid from 'services/firebase/hooks/users/useGetUserByUid'
import { formatter } from 'utils/functions'

import CompleteAccountValidations from './components/CompleteAccountValidations'
import ProfileCompleteMessage from './components/ProfileCompleteMessage'

import './LawyerDashboard.scss'

const LawyerDashboard = () => {
  const [userInfo] = useUserInfo()

  const doGetLawyerReviews = useGetLawyerReviews()
  const doGetUserByUid = useGetUserByUid()

  const { fetchingCases, getCases, cases } = useCases()
  const doGetCases = useGetCases()
  const [activeCases, setActiveCases] = useState([])
  const [reviews, setReviews] = useState<any>([])
  const [completedCases, setCompletedCases] = useState([])

  const [paymentTotal, setPaymentTotal] = useState(0)
  const [paymentReceived, setPaymentReceived] = useState(0)
  const [paymentPending, setPaymentPending] = useState(0)

  useEffect(() => {
    getCases()

    doGetCases().then(async (result) => {
      if (result) {
        let tempPaymentTotal = 0
        let tempPaymentReceived = 0
        let tempPaymentPending = 0

        await result.map((item) => {
          if (item.paymentType !== 'noWinNoFee') {
            if (item.status !== 'canceled') {
              tempPaymentTotal =
                tempPaymentTotal + (item.amount - item.amount * 0.12)
            }
            if (item.status === 'complete') {
              tempPaymentReceived =
                tempPaymentReceived + (item.amount - item.amount * 0.12)
            }
            if (
              item.status === 'active' ||
              item.status === 'complete_by_lawyer'
            ) {
              tempPaymentPending =
                tempPaymentPending + (item.amount - item.amount * 0.12)
            }
          }
        })

        setPaymentTotal(tempPaymentTotal)
        setPaymentPending(tempPaymentPending)
        setPaymentReceived(tempPaymentReceived)

        const currentActiveCases = await result.filter(
          (item) => item.status === 'active'
        )
        const currentCompletedCases = await result.filter(
          (item) => item.status === 'complete'
        )
        setActiveCases(currentActiveCases)
        setCompletedCases(currentCompletedCases)
      }
    })
    doGetLawyerReviews(userInfo.uid).then(async (items) => {
      const clientsReviews = await items.map((item) => {
        return doGetUserByUid(item.uid).then((client) => {
          return {
            ...item,
            author: client[0].displayName || '--',
            image: client[0].photoURL || null,
          }
        })
      })

      Promise.all(clientsReviews).then((allReviews) => {
        setReviews(allReviews)
      })
    })
  }, [])

  return (
    <PanelContainer>
      <div className='o-lawyerDashboard'>
        <div className='o-lawyerDashboard__container'>
          <div className='o-lawyerDashboard__main'>
            <ProfileCompleteMessage />
            <CompleteAccountValidations />
            <div className='o-lawyerDashboard__main--boxes'>
              <WhiteBox icon={<DashboardIcon />} title='Total Cases'>
                <div className='o-lawyerDashboard__casesSummary'>
                  <div className='o-lawyerDashboard__casesSummary--item -total'>
                    <strong>
                      {activeCases.length + completedCases.length}
                    </strong>
                    <span>Cases</span>
                  </div>
                  <div className='o-lawyerDashboard__casesSummary--item -completed'>
                    <CompletedCasesIcon />
                    <span>{completedCases.length} completed</span>
                  </div>
                  <div className='o-lawyerDashboard__casesSummary--item -inProgress'>
                    <InProgressCasesIcon />
                    <span>{activeCases.length} in progress</span>
                  </div>
                </div>
              </WhiteBox>

              <WhiteBox icon={<EarningsIcon />} title='Total Earnings'>
                <div className='o-lawyerDashboard__totalEarnings'>
                  <div className='o-lawyerDashboard__totalEarnings--item -total'>
                    <strong>Total</strong>
                    <span>{formatter.format(paymentTotal || 0)}</span>
                  </div>
                  <div className='o-lawyerDashboard__totalEarnings--item -received'>
                    <strong>Received</strong>
                    <span>{formatter.format(paymentReceived || 0)}</span>
                  </div>
                  <div className='o-lawyerDashboard__totalEarnings--item -pending'>
                    <strong>Pending</strong>
                    <span>{formatter.format(paymentPending || 0)}</span>
                  </div>
                </div>
              </WhiteBox>
            </div>
            <div className='o-lawyerDashboard__cases'>
              <WhiteBox
                withOpacity
                icon={<FolderIcon className='o-lawyerDashboard__cases--icon' />}
                title='Cases'
                actions={<CaseFilters />}
              >
                {fetchingCases ? (
                  <Loader darker />
                ) : cases.length > 0 ? (
                  <div className='o-lawyerDashboard__cases--carousel'>
                    <OwlCarousel
                      options={{
                        items: 3,
                        margin: 15,
                        stagePadding: 15,
                        responsive: {
                          0: {
                            items: 1,
                          },
                          640: {
                            items: 2,
                          },
                          768: {
                            items: 1,
                          },
                          900: {
                            items: 2,
                          },
                          1200: {
                            items: 3,
                          },
                        },
                      }}
                    >
                      {cases.map((item, key) => (
                        <CaseCard key={`case-${key}`} data={item} />
                      ))}
                    </OwlCarousel>
                  </div>
                ) : (
                  <div className='p-cases__items--empty'>No cases found</div>
                )}
              </WhiteBox>
            </div>
            {reviews.length > 0 && (
              <div className='o-lawyerDashboard__reviews'>
                <WhiteBox
                  withOpacity
                  icon={
                    <StarIcon className='o-lawyerDashboard__reviews--icon' />
                  }
                  title='Reviews'
                >
                  <Reviews
                    dots
                    nav={false}
                    reviews={reviews}
                    subtractItems={1}
                  />
                </WhiteBox>
              </div>
            )}
          </div>
          <PanelSidebar />
        </div>
      </div>
    </PanelContainer>
  )
}

export default LawyerDashboard
