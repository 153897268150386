import React, { useState } from 'react'

import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  withStyles,
  StepConnector,
} from '@material-ui/core'
import axios from 'axios'
import geohash from 'ngeohash'
import { Spinner } from 'react-activity'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import InitialData from 'components/organisms/LawyerRegistration/InitialData'
import OurTerms from 'components/organisms/LawyerRegistration/OurTerms'
import ProfileAddress from 'components/organisms/LawyerRegistration/ProfileAddress'
import ProfileExpertise from 'components/organisms/LawyerRegistration/ProfileExpertise'
import ProfilePhoto from 'components/organisms/LawyerRegistration/ProfilePhoto'
import ProfilePrice from 'components/organisms/LawyerRegistration/ProfilePrice'
import SuccessfullyRegistered from 'components/organisms/LawyerRegistration/SuccessfullyRegistered'
import useLawyerRegistration from 'hooks/useLawyerRegistration'
import useUserInfo from 'hooks/useUserInfo'
import { auth, db, firestore, storage, GeoPoint } from 'services/firebase'
import useSignUpWithEmail from 'services/firebase/hooks/auth/useSignUpWithEmail'
import { firebaseBaseURL } from 'utils/constants'
import './LawyerRegistration.scss'

const useStyles = makeStyles(() => ({
  formControl: {
    marginBottom: 35,
  },
  lastFormControl: {
    marginBottom: 0,
  },
  formControlLabel: {
    marginLeft: 'auto',
    color: '#fff',
    fontFamily: 'Poppins',
  },
  leftFormControlLabel: {
    color: '#fff',
    fontFamily: 'Poppins',
  },
  checkboxLabel: {
    fontSize: 12,
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  stepLabel: {
    display: 'none',
  },
}))

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#18a1db',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#18a1db',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector)

const LawyerRegistration = ({ history }) => {
  const classes = useStyles()

  const [, setUserInfo] = useUserInfo()
  const doSignUpWithEmail = useSignUpWithEmail()
  const [formValues] = useLawyerRegistration()
  const steps = [0, 1, 2, 3, 4, 5]
  const [activeStep, setActiveStep] = useState(0)
  const [errorSteps, setErrorSteps] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false)

  const titles = [
    <>
      <b>Hi,</b> tell us a little about yourself
    </>,
    <>
      Great <b>{formValues.name}</b>, Now <br /> let’s create your proﬁle
    </>,
    <>
      Tell <b>clients</b> what you do
    </>,
    <>
      Make sure people can <b>find</b> you
    </>,
    <>
      Display your <b>price</b>
    </>,
    <>Our Terms & Conditions </>,
  ]

  const handleNext = async () => {
    const errors = []

    if (activeStep === 0) {
      if (!formValues.name) {
        errors.push('name')
      }
      if (!formValues.surname) {
        errors.push('surname')
      }
      if (!formValues.phone) {
        errors.push('phone')
      }
      if (!formValues.email) {
        errors.push('email')
      } else {
        await auth
          .fetchSignInMethodsForEmail(formValues.email)
          .then((result) => {
            if (result.length > 0) {
              toast.error('This email is already linked to another account')
              errors.push('email')
            }
          })
          .catch((err) => {
            toast.error(err.message)
            errors.push('email')
          })
      }
      if (!formValues.password) {
        errors.push('password')
      }
      if (formValues.password && formValues.password.length <= 5) {
        toast.error('Password must contain 6 or more characters')
        errors.push('password')
      }
      if (!formValues.srcOisc && formValues.addSRALater !== true) {
        errors.push('srcOisc')
      }
    }

    if (activeStep === 1) {
      if (!formValues.image) {
        errors.push('image')
      }
    }

    if (activeStep === 2) {
      if (formValues.expertise.length === 0) {
        errors.push('expertise')
      }
      if (formValues.practiceAreas.length === 0) {
        errors.push('practiceAreas')
      }
      if (formValues.languages.length === 0) {
        errors.push('languages')
      }
      if (!formValues.practisingSince) {
        errors.push('practisingSince')
      }
    }

    if (activeStep === 3) {
      if (!formValues.lawFirmName) {
        errors.push('lawFirmName')
      }
      if (!formValues.numberOfEmployees) {
        errors.push('numberOfEmployees')
      }
      if (!formValues.address) {
        errors.push('address')
      }
    }
    if (activeStep === 4) {
      if (!formValues.hourPrice) {
        errors.push('hourPrice')
      }
    }

    if (errors.length > 0) {
      setErrorSteps(errors)
      switch (activeStep) {
        case 1:
          toast.error('You need to send a photo')
          break
        case 4:
          toast.error('Check the mandatory fields')
          break
        default:
          toast.error('All fields are mandatory')
      }
      return false
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSubmit = () => {
    setSubmitting(true)
    doSignUpWithEmail({
      email: formValues.email,
      password: formValues.password,
    })
      .then(async () => {
        const currentUser = auth.currentUser
        const filename = `profileImage-${Date.now()}.png`
        const imagePath = `users/${currentUser.uid}/${filename}`

        const photoURL = await storage
          .ref()
          .child(imagePath)
          .put(new File([formValues.image], filename, { type: 'image/jpeg' }))
          .then(() => {
            return storage
              .ref()
              .child(imagePath)
              .getDownloadURL()
              .then((url) => {
                return url
              })
              .catch((err) => {
                console.log(err.message)
                toast.error(err.message)
              })
          })

        await currentUser.updateProfile({
          displayName: formValues.name,
          phone: formValues.phone,
          photoURL: photoURL,
          role: 'lawyer',
        })

        if (currentUser.uid) {
          const user = formValues
          user.uid = currentUser.uid
          user.photoURL = currentUser.photoURL
          delete user.image
          delete user.addSRALater
          user.role = 'lawyer'
          user.rating = 0
          user.l = new GeoPoint(user.latitude, user.longitude)
          user.g = geohash.encode(user.latitude, user.longitude)
          user.hourPrice = parseFloat(user.hourPrice)

          await axios.post(`${firebaseBaseURL}stripe/account-registration`, {
            user,
          })

          delete user.latitude
          delete user.longitude

          await db.ref(`users/${user.uid}`).set(user)
          await firestore.collection('users').doc(currentUser.uid).set(user)

          setSubmitting(false)
          setSuccessfullyRegistered(true)
          if (currentUser.uid) {
            setUserInfo(user)
            history.push('/panel/dashboard')
          } else {
            history.push('/login')
          }
        }
      })
      .catch((err) => {
        setSubmitting(false)
        return toast.error(err.message)
      })
  }

  return (
    <div className='p-lawyerRegistration'>
      {successfullyRegistered ? (
        <SuccessfullyRegistered />
      ) : (
        <>
          <div className='p-lawyerRegistration__stepper'>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<CustomConnector />}
              className={classes.stepper}
            >
              {steps.map((step, i) => (
                <Step key={step}>
                  <StepLabel
                    error={errorSteps.includes(i)}
                    classes={{ label: classes.stepLabel }}
                  >
                    {step}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className='p-lawyerRegistration__content'>
            <div className='p-lawyerRegistration__content--title'>
              {titles[activeStep]}
            </div>

            <div className='p-lawyerRegistration__content--page'>
              {activeStep === 0 && (
                <InitialData errors={errorSteps} classes={classes} />
              )}
              {activeStep === 1 && (
                <ProfilePhoto errors={errorSteps} classes={classes} />
              )}
              {activeStep === 2 && (
                <ProfileExpertise errors={errorSteps} classes={classes} />
              )}
              {activeStep === 3 && (
                <ProfileAddress errors={errorSteps} classes={classes} />
              )}
              {activeStep === 4 && (
                <ProfilePrice errors={errorSteps} classes={classes} />
              )}
              {activeStep === 5 && <OurTerms />}
            </div>

            <div className='p-lawyerRegistration__content--actions'>
              <button
                disabled={activeStep === 0}
                onClick={handleBack}
                type='button'
                className='p-lawyerRegistration__content--actions-back'
              >
                Go Back
              </button>
              <button
                onClick={activeStep === 5 ? handleSubmit : handleNext}
                className='p-lawyerRegistration__content--actions-forward'
              >
                {submitting ? (
                  <Spinner color='#fff' />
                ) : activeStep === steps.length - 1 ? (
                  'Accept'
                ) : (
                  'Next'
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default withRouter(LawyerRegistration)
