import React from 'react'

import { FaPoundSign } from 'react-icons/fa'

import WhiteBox from 'components/atoms/WhiteBox'

import FixedFee from './FixedFee'
import HourPrice from './HourPrice'

const HourlyRates = () => {
  return (
    <WhiteBox
      title='Hourly Rates'
      modifier='-hourlyRates'
      icon={<FaPoundSign />}
    >
      <main>
        <HourPrice />
        <FixedFee />
      </main>
    </WhiteBox>
  )
}

export default HourlyRates
