import React from 'react'

import moment from 'moment'

import { useNotifications } from 'context/notifications'
import { ReactComponent as CaseStaredIcon } from 'images/icon-case-started.svg'
import { ReactComponent as CompletedOrderIcon } from 'images/icon-completed-order.svg'
import { ReactComponent as PaymentIcon } from 'images/icon-credit-card.svg'
import { ReactComponent as ExtendDeadlineAcceptedIcon } from 'images/icon-extend-deadline.svg'
import { ReactComponent as DisputeIcon } from 'images/icon-lock.svg'
import { ReactComponent as MarkedAsCompleteIcon } from 'images/icon-marked-as-complete.svg'
import { ReactComponent as OfferSentIcon } from 'images/icon-offer-sent.svg'
import { ReactComponent as PaymentMadeIcon } from 'images/icon-payment-made.svg'
import { ReactComponent as RequestExtendDeadlineIcon } from 'images/icon-request-extend-deadline.svg'
import { ReactComponent as ReviewIcon } from 'images/icon-star.svg'
import { ReactComponent as UploadFileIcon } from 'images/icon-upload-file.svg'

import './NotificationItem.scss'

const getNotificationItem = (type) => {
  switch (type) {
    case 'review':
      return <ReviewIcon />
    case 'payment':
      return <PaymentIcon />
    case 'completed':
      return <CompletedOrderIcon />
    case 'completed-accepted':
      return <MarkedAsCompleteIcon />
    case 'extend-time-accepted':
      return <ExtendDeadlineAcceptedIcon />
    case 'extend-time':
      return <RequestExtendDeadlineIcon />
    case 'upload-file':
      return <UploadFileIcon />
    case 'case-started':
      return <CaseStaredIcon />
    case 'offer-accepted':
      return <PaymentMadeIcon />
    case 'offer-sent':
      return <OfferSentIcon />
    case 'dispute':
      return <DisputeIcon />
  }
}

const NotificationItem = ({ notification, handleCloseModal }) => {
  const { markNotificationAsRead } = useNotifications()

  const handleNotificationClick = async () => {
    handleCloseModal(false)
    await markNotificationAsRead(notification.id)
    if (notification.link) {
      window.location = notification.link
    }
  }

  return (
    <button
      onClick={handleNotificationClick}
      className={`m-notificationItem -${notification.type} ${
        notification.read ? '-read' : '-unread'
      }`}
    >
      <div className='m-notificationItem__icon'>
        {getNotificationItem(notification.type)}
      </div>
      <div className='m-notificationItem__content'>
        <div className='m-notificationItem__content--text'>
          {notification.message}
        </div>
        <div className='m-notificationItem__content--date'>
          <small>{moment(notification.date.toDate()).format('DD MMM')}</small>
          <span>
            {moment(notification.date.toDate()).from(moment(new Date()))}
          </span>
        </div>
      </div>
    </button>
  )
}

export default NotificationItem
