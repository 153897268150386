import React, { useEffect, useState } from 'react'

import { useCases } from 'context/cases'

import CancelCase from '../CaseCard/CaseActions/CancelCase'
import MarkAsCompleted from '../CaseCard/CaseActions/MarkAsCompleted'
import NewOffer from '../CaseCard/CaseActions/NewOffer'
import RemoveOffer from '../CaseCard/CaseActions/RemoveOffer'
import ReportADispute from '../CaseCard/CaseActions/ReportADispute'
import RequestExtension from '../CaseCard/CaseActions/RequestExtension'
import ReviewCase from '../CaseCard/CaseActions/ReviewCase'
import Modal from '../Modal'

const CaseModals = () => {
  const { showAction, modalIcon, caseData, setShowAction } = useCases()
  const [modalEmptyMode, setModalEmptyMode] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string | null>(null)

  useEffect(() => {
    let title
    switch (showAction) {
      case 'createOffer':
        title = 'New Offer'
        break
      case 'markAsCompleted':
        title = 'Mark case as completed'
        break
      case 'requestExtension':
        title = 'Request a time extension'
        break
      case 'reportADispute':
        title = 'Report a dispute'
        break
      case 'cancelCase':
        title = 'Cancel case'
        break
      case 'removeOffer':
        title = 'Withdraw offer'
        break
      case 'reviewCase':
        title = 'Review case'
        break
      default:
        title = null
    }

    setModalTitle(title)
  }, [showAction])

  return (
    <div className='a-caseModals'>
      {showAction && (
        <Modal
          emptyMode={modalEmptyMode}
          title={modalTitle}
          icon={modalIcon}
          handleCloseModal={() => setShowAction(null)}
        >
          <>
            {showAction === 'removeOffer' && (
              <RemoveOffer
                handleCloseModal={() => setShowAction(null)}
                caseData={caseData}
              />
            )}
            {showAction === 'markAsCompleted' && (
              <MarkAsCompleted
                handleModalEmptyMode={setModalEmptyMode}
                handleCloseModal={() => setShowAction(null)}
                caseData={caseData}
              />
            )}
            {showAction === 'requestExtension' && (
              <RequestExtension
                handleCloseModal={() => setShowAction(null)}
                caseData={caseData}
              />
            )}
            {showAction === 'reportADispute' && (
              <ReportADispute
                handleCloseModal={() => setShowAction(null)}
                caseData={caseData}
              />
            )}
            {showAction === 'cancelCase' && (
              <CancelCase
                handleCloseModal={() => setShowAction(null)}
                caseData={caseData}
              />
            )}
            {showAction === 'reviewCase' && (
              <ReviewCase
                handleCloseModal={() => setShowAction(null)}
                caseData={caseData}
              />
            )}
            {showAction === 'createOffer' && (
              <NewOffer handleCloseModal={() => setShowAction(null)} />
            )}
          </>
        </Modal>
      )}
    </div>
  )
}

export default CaseModals
