import React from 'react'

import { IWhiteBox } from './IWhiteBox'

import './WhiteBox.scss'

const WhiteBox = ({
  icon,
  title,
  children,
  actions,
  withOpacity = false,
  modifier = '',
}: IWhiteBox) => {
  return (
    <div
      className={`a-whiteBox ${modifier} ${withOpacity ? '-withOpacity' : ''}`}
    >
      {title && icon && (
        <header className='a-whiteBox__header'>
          <div className='a-whiteBox__header--icon'>{icon}</div>
          <span>{title}</span>
          <div className='a-whiteBox__header--actions'>{actions}</div>
        </header>
      )}
      <main className='a-whiteBox__main'>{children}</main>
    </div>
  )
}

export default WhiteBox
