import React, { useEffect, useState } from 'react'

import { FaEnvelopeOpenText } from 'react-icons/fa'
import { HiOutlinePlusCircle } from 'react-icons/hi'
import { IoIosPeople } from 'react-icons/io'

import Modal from 'components/atoms/Modal'
import WhiteBox from 'components/atoms/WhiteBox'
import InviteYourFirm from 'components/molecules/Profile/InviteYourFirm'
import useUserInfo from 'hooks/useUserInfo'
import useGetLawyerTeam from 'services/firebase/hooks/lawyers/useGetLawyerTeam'

import MemberItem from './memberItem'
import './Team.scss'

const Team = () => {
  const [showModal, setShowModal] = useState(false)
  const [userInfo] = useUserInfo()
  const doGetLawyerTeam = useGetLawyerTeam()
  const [team, setTeam] = useState([])
  useEffect(() => {
    if (userInfo) {
      doGetLawyerTeam(userInfo.uid).then((result) => {
        setTeam(result)
      })
    }
  }, [userInfo])
  return (
    <>
      <WhiteBox
        title='My Team'
        modifier='-myTeam'
        icon={<IoIosPeople />}
        actions={
          <button onClick={() => setShowModal(true)} type='button'>
            Invite your Firm <HiOutlinePlusCircle />
          </button>
        }
      >
        <main>
          {team && team.length > 0 ? (
            team.map((item, key) => <MemberItem key={key} data={item} />)
          ) : (
            <div className='m-profileTeam__main--empty'>
              <p>We didn&apos;t find any lawyers on your team</p>
            </div>
          )}
        </main>
      </WhiteBox>
      {showModal && (
        <Modal
          handleCloseModal={() => setShowModal(false)}
          title='Invite your Firm'
          icon={<FaEnvelopeOpenText />}
        >
          <InviteYourFirm />
        </Modal>
      )}
    </>
  )
}

export default Team
