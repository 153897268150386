import React from 'react'

import { ReactComponent as CloseIcon } from 'images/icons/circle-close.svg'

import './Modal.scss'

import { IModal } from './IModal'

const Modal = ({
  title,
  emptyMode,
  icon,
  children,
  handleCloseModal,
}: IModal) => {
  return (
    <div className={`a-modal ${emptyMode && '-emptyMode'}`}>
      <div className='a-modal__container'>
        <div className='a-modal__header'>
          {icon && icon}
          {title && <h3>{title}</h3>}
          <button className='a-modal__header--close' onClick={handleCloseModal}>
            <CloseIcon />
          </button>
        </div>
        <div className='a-modal__content'>{children}</div>
      </div>
    </div>
  )
}

export default Modal
