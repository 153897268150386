import React, { useEffect } from 'react'

import ExitToApp from '@material-ui/icons/ExitToApp'
import GoogleMapReact from 'google-map-react'
import Avatar from 'react-avatar'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import StarRatings from 'react-star-ratings'

import { useChat } from 'context/chat'
import { useNotifications } from 'context/notifications'
import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as IconBell } from 'images/icons/bell.svg'
import { ReactComponent as IconDashboard } from 'images/icons/mobile-dashboard.svg'
import { ReactComponent as IconFolder } from 'images/icons/mobile-folder.svg'
import { ReactComponent as IconMessage } from 'images/icons/mobile-message.svg'
import { ReactComponent as PinIcon } from 'images/icons/pin.svg'
import { ReactComponent as IconProfile } from 'images/icons/profile.svg'
import { db } from 'services/firebase'
import useLogout from 'services/firebase/hooks/auth/useLogout'

import { IMobileMenu } from './IMobileMenu'

const MobileMenu = ({
  handleCloseNav,
  handleShowNotifications,
}: IMobileMenu) => {
  const doLogout = useLogout()
  const history = useHistory()
  const location = useLocation()

  const [userInfo] = useUserInfo()
  const { hasNewNotifications } = useNotifications()
  const { hasUnreadChat, setHasUnreadChat } = useChat()

  useEffect(() => {
    db.ref(`/users/${userInfo.uid}/unreadChats/`).on('value', function (snap) {
      setHasUnreadChat(snap.numChildren())
    })
  }, [])

  const handleNavigation = (to) => {
    handleCloseNav(false)
    history.push(to)
  }

  const handleLogout = () => {
    doLogout().then(() => {
      history.push('/login')
    })
  }

  return (
    <div className='t-mobileMenu'>
      <div className='t-mobileMenu__content'>
        <div className='t-mobileMenu__header'>
          <div className='t-mobileMenu__header--avatar'>
            <span className='-indicator' />
            <Avatar
              size='120'
              maxInitials={2}
              round='100%'
              color='#26395A'
              textSizeRatio={2.5}
              src={userInfo.photoURL}
              name={userInfo.displayName}
            />
          </div>
          <div className='t-mobileMenu__header--info'>
            <strong className='t-mobileMenu__header--name'>
              {userInfo.displayName || `${userInfo.name} ${userInfo.surname}`}
            </strong>
            <div className='t-mobileMenu__header--rating'>
              <StarRatings
                svgIconPath='M48.856,22.73c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
              c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
              L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.622c-1.359,0.197-2.467,1.131-2.892,2.438
              c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.701
              c-0.183,1.06,0.095,2.091,0.781,2.904c1.066,1.267,2.927,1.653,4.415,0.871l10.814-5.686c0.452-0.237,1.021-0.235,1.472,0
              l10.815,5.686c0.526,0.277,1.087,0.417,1.666,0.417c1.057,0,2.059-0.47,2.748-1.288c0.687-0.813,0.964-1.846,0.781-2.904
              l-2.065-12.042c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.73z'
                starDimension={'15px'}
                starEmptyColor='#fff'
                starRatedColor='#499FD7'
                rating={userInfo.rating}
                numberOfStars={5}
                starSpacing={'3px'}
              />
            </div>
            <div className='t-mobileMenu__header--viewProfile'>
              {userInfo.role === 'lawyer' && (
                <Link to={`/profile/${userInfo.uid}`}>View Profile</Link>
              )}
            </div>
          </div>
        </div>
        <div className='t-mobileMenu__subHeader'>
          {userInfo?.l && (
            <div className='t-mobileMenu__subHeader--map'>
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{
                  key: 'AIzaSyDhUB5zHmeVBAaVeizpfFhCgeoAWQ0Vi9s',
                }}
                defaultCenter={{
                  lat: userInfo.l.latitude,
                  lng: userInfo.l.longitude,
                }}
                center={{ lat: userInfo.l.latitude, lng: userInfo.l.longitude }}
                zoom={14}
                options={{
                  draggable: false,
                  panControl: false,
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  overviewMapControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                }}
              >
                <div className='t-mobileMenu__subHeader--map-pin'>
                  <PinIcon />
                </div>
              </GoogleMapReact>
            </div>
          )}
          <div
            className={`t-mobileMenu__subHeader--notifications ${
              userInfo?.l ? '-half' : '-full'
            }`}
          >
            <button onClick={() => handleShowNotifications(true)}>
              <div className='-icon'>
                <IconBell />
                {hasNewNotifications > 0 && (
                  <i className='t-mobileMenu__nav--link-notification'>
                    {hasNewNotifications}
                  </i>
                )}
              </div>
              <span>Notifications</span>
              <p>
                {hasNewNotifications
                  ? 'You have new notifications'
                  : "You don't have notifications"}
              </p>
            </button>
          </div>
        </div>
        <div className='t-mobileMenu__menu'>
          <nav className={`t-mobileMenu__nav`}>
            <a
              onClick={() => handleNavigation('/panel/messages')}
              className={`t-mobileMenu__nav--link ${
                location.pathname === '/panel/messages' ? '-current' : ''
              }`}
            >
              <div className='-icon'>
                <IconMessage />
                {hasUnreadChat > 0 && (
                  <i className='t-mobileMenu__nav--link-notification'>
                    {hasUnreadChat}
                  </i>
                )}
              </div>
              <span>Messages</span>
            </a>
            <a
              onClick={() => handleNavigation('/panel/cases')}
              className={`t-mobileMenu__nav--link ${
                location.pathname.includes('/panel/cases') ? '-current' : ''
              }`}
            >
              <div className='-icon'>
                <IconFolder />
              </div>
              <span>Cases</span>
            </a>
            <a
              onClick={() => handleNavigation('/panel/dashboard')}
              className={`t-mobileMenu__nav--link ${
                location.pathname === '/panel/dashboard' ? '-current' : ''
              }`}
            >
              <div className='-icon'>
                <IconDashboard />
              </div>
              <span>Dashboard</span>
            </a>
            <a
              onClick={() => handleNavigation('/panel/profile')}
              className={`t-mobileMenu__nav--link ${
                location.pathname === '/panel/dashboard' ? '-current' : ''
              }`}
            >
              <div className='-icon -big'>
                <IconProfile />
              </div>
              <span>Profile</span>
            </a>
          </nav>
        </div>
        <div className='t-mobileMenu__logout'>
          <button type='button' onClick={handleLogout}>
            <span>Logout</span>
            <ExitToApp />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
