import React, { useState } from 'react'

import { toast } from 'react-toastify'

import { useNotifications } from 'context/notifications'
import { auth } from 'services/firebase'
import useRegisterTimelineItem from 'services/firebase/hooks/cases/useRegisterTimelineItem'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'

const ReportADispute = ({ caseData, handleCloseModal }) => {
  const { uid, displayName } = auth.currentUser

  const { updateNotificationsStates } = useNotifications()

  const doUpdateCaseById = useUpdateCaseById()
  const doRegisterTimelineItem = useRegisterTimelineItem()
  const doAddCaseSystemMessage = useAddCaseSystemMessage()
  const doRegisterUserNotification = useRegisterUserNotification()

  const [disputeMessage, setDisputeMessage] = useState('')

  const handleReportADispute = () => {
    if (disputeMessage.length > 50) {
      doUpdateCaseById(caseData.caseId, {
        status: 'dispute',
        disputeMessage: disputeMessage,
        disputeInitiatedBy: {
          uid,
          displayName,
        },
      }).then(async () => {
        /**
         * Lawyer notification
         */
        await doRegisterUserNotification(uid, {
          message: 'You started a dispute in this case',
          type: 'dispute',
          link: `/panel/cases/${caseData.caseId}`,
        })

        /**
         * Client notification
         */
        const userUid =
          uid === caseData.senderUid ? caseData.receiverUid : caseData.senderUid
        const messageName =
          uid === caseData.senderUid
            ? `${caseData.senderName || 'The lawyer'}`
            : `${caseData.receiverName || 'The client'} `
        await doRegisterUserNotification(userUid, {
          message: `${messageName} started a dispute in this case.`,
          type: 'dispute',
          link: `/panel/cases/${caseData.caseId}`,
        })

        await doRegisterTimelineItem(caseData.caseId, {
          type: 'dispute',
          messages: {
            lawyer: `${messageName} started a dispute in this case.`,
            client: `${messageName} started a dispute in this case.`,
          },
          createdAt: new Date(),
        })

        await updateNotificationsStates()

        await doAddCaseSystemMessage(
          caseData.caseId,
          caseData.senderUid,
          caseData.receiverUid,
          'This case is in dispute.',
          'link'
        )

        toast.success('Dispute request sent successfully!')
        handleCloseModal(null, null)
      })
    } else {
      toast.error('The message must have more than 50 characters.')
    }
  }
  return (
    <div className='a-modal__reportADispute'>
      <header>
        <label>Report a dispute</label>
        <small>{disputeMessage.length}/1000</small>
      </header>
      <textarea
        placeholder='Describe why you would like to report this dispute.'
        value={disputeMessage}
        onChange={(e) => setDisputeMessage(e.target.value)}
        maxLength={1000}
      />

      <div className='a-modal__actions'>
        <button
          className='a-modal__actions--cancelButton'
          onClick={handleCloseModal}
        >
          Cancel
        </button>
        <button
          className='a-modal__actions--submitButton'
          onClick={handleReportADispute}
        >
          Report
        </button>
      </div>
    </div>
  )
}

export default ReportADispute
