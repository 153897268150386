import React, { useEffect, useRef, useState } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { RiPencilFill, RiCheckFill } from 'react-icons/ri'

import useUserInfo from 'hooks/useUserInfo'
import useUpdateUserProfile from 'services/firebase/hooks/users/useUpdateUserProfile'

import './Generic.scss'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 46,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#18a1db',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#18a1db',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

const Generic = ({
  type,
  title,
  legend,
  toggle = false,
  icon = null,
  emptyMessage = false,
}) => {
  const elementRef = useRef(null)
  const [userInfo] = useUserInfo()
  const [edit, setEdit] = useState(false)
  const doUpdateUserProfile = useUpdateUserProfile()
  const [value, setValue] = useState(userInfo[type])

  const handleEdit = () => {
    if (edit) {
      if (typeof value !== 'undefined') {
        doUpdateUserProfile({
          [type]: value,
        })
      }
      setEdit(false)
    } else {
      setEdit(true)
    }
  }

  const handleChange = (e) => {
    setValue(e.target.checked)
    doUpdateUserProfile({
      [type]: e.target.checked,
    })
  }

  useEffect(() => {
    if (edit && !toggle) {
      elementRef.current.focus()
    }
  }, [edit, toggle])

  return (
    <div className='m-generic'>
      {icon && <div className='m-generic__icon'>{icon}</div>}
      <div className='m-generic__component'>
        <strong>{title}</strong>
        {edit && !toggle ? (
          <input
            ref={elementRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <div>
            <div>
              <span>
                {value && value}
                {!value && emptyMessage && emptyMessage}
              </span>
              {legend && <p>{legend}</p>}
            </div>
          </div>
        )}
      </div>
      <div className='m-generic__action'>
        {toggle ? (
          <IOSSwitch checked={value} onChange={handleChange} name={type} />
        ) : (
          <button
            onClick={handleEdit}
            type='button'
            className={edit ? '-editing' : ''}
          >
            {edit ? <RiCheckFill /> : <RiPencilFill />}
          </button>
        )}
      </div>
    </div>
  )
}

export default Generic
