import React, { useEffect, useState } from 'react'

import { Button, makeStyles, MenuItem, Select, Input } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { Spinner } from 'react-activity'
import { Controller, useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

import useLawyersSearch from 'hooks/useLawyersSearch'
import useGetLawyers from 'services/firebase/hooks/lawyers/useGetLawyers'
import useGetUnregisteredLawyers from 'services/firebase/hooks/lawyers/useGetUnregisteredLawyers'
import { expertise } from 'utils/constants'
import { calculateDistance, getLatLngFromPostcode } from 'utils/functions'

import './ResultsSearchBar.scss'

const useStyles = makeStyles((theme) => ({
  select: {
    padding: 0,
    width: '100%',
    height: '100%',
    minWidth: 140,
    borderRadius: 0,
  },
  icon: {
    right: 15,
    [theme.breakpoints.down('md')]: {
      right: 0,
    },
  },
  input: {
    padding: theme.spacing(0, 3),
    width: '100%',
    height: 68,
    lineHeight: '68px',
    backgroundColor: '#f8f9ff',
  },
  button: {
    width: 240,
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    height: 70,
    lineHeight: '70px',
    padding: 0,
    borderRadius: 0,
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      width: 120,
    },
  },
  field: {
    background: 'transparent',
    border: 'none',
  },
}))

const ResultsSearchBar = ({ showSearchBar, history, match }) => {
  const classes = useStyles()
  const doGetLawyers = useGetLawyers()
  const doGetUnregisteredLawyers = useGetUnregisteredLawyers()
  const { handleSubmit, control, setValue } = useForm()

  const [lawyers, setLawyers] = useLawyersSearch()
  const [practiceAreas, setPracticeAreas] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const getLawyersList = async (data = null) => {
    const { expertise, practiceArea, postcode } = data || match.params
    const coordinates = {}

    const postcodeInfo = await getLatLngFromPostcode(postcode).then(
      (result) => {
        if (result.length > 0) {
          return result[0]
        }
        toast.error('The postcode entered is invalid.')
        return null
      }
    )

    if (postcodeInfo) {
      coordinates.latitude = postcodeInfo.geometry.location.lat
      coordinates.longitude = postcodeInfo.geometry.location.lng

      doGetLawyers({
        ...coordinates,
        distance: 50,
        expertise,
        practiceArea,
      })
        .then(async (result) => {
          const unregisteredLawyers = await doGetUnregisteredLawyers({
            ...coordinates,
            distance: 50,
            expertise,
          }).then((result) => {
            const unregisteredLawyersList = []
            result.map((item) => {
              unregisteredLawyersList.push({
                ...item,
                distance: calculateDistance(
                  item.l.latitude,
                  item.l.longitude,
                  coordinates.latitude,
                  coordinates.longitude
                ),
              })
              return true
            })
            return unregisteredLawyersList
          })

          window.localStorage.setItem('current-latitude', coordinates.latitude)
          window.localStorage.setItem(
            'current-longitude',
            coordinates.longitude
          )

          const lawyersList = []
          result.map((item) => {
            lawyersList.push({
              ...item,
              distance: calculateDistance(
                item.l.latitude,
                item.l.longitude,
                coordinates.latitude,
                coordinates.longitude
              ),
            })
          })
          setLawyers({
            original: lawyersList,
            unregisteredLawyers,
            withFilters: lawyersList,
            loading: false,
          })
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      setIsSubmitting(false)
      setLawyers({ ...lawyers, loading: false })
    }
  }

  useEffect(() => {
    getLawyersList()
  }, [])

  useEffect(() => {
    changePracticeAreas(match.params.expertise)
  }, [])

  const changePracticeAreas = (newExpertise) => {
    setPracticeAreas([])
    setValue('practiceArea', 'all')
    if (newExpertise) {
      const tempPracticeAreas = expertise.filter((item) =>
        newExpertise.includes(item.value)
      )
      setPracticeAreas(tempPracticeAreas)
    }
  }

  const onSubmit = (data) => {
    setIsSubmitting(true)
    setLawyers({ ...lawyers, loading: true })
    history.push(
      `/search/${data.expertise}/${data.practiceArea}/${data.postcode}`
    )
    getLawyersList(data)
  }

  return (
    <div className={'container mx-auto'}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`o-resultsSearchBar ${showSearchBar ? '-show' : '-hide'}`}
      >
        <div className='o-resultsSearchBar__field'>
          <Controller
            render={({ field }) => (
              <Select
                {...field}
                disableUnderline
                IconComponent={KeyboardArrowDown}
                className={classes.select}
                inputProps={{ className: classes.input }}
                classes={{
                  icon: classes.icon,
                }}
              >
                {expertise.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
            name='expertise'
            control={control}
            onChange={([selected]) => {
              changePracticeAreas(selected.target.value)
              return selected.target.value
            }}
            defaultValue={match.params.expertise}
          />
        </div>
        <div className='o-resultsSearchBar__divider' />
        <div className='o-resultsSearchBar__field'>
          <Controller
            render={({ field }) => (
              <Select
                {...field}
                disableUnderline
                IconComponent={KeyboardArrowDown}
                className={classes.select}
                inputProps={{ className: classes.input }}
                displayEmpty
                classes={{
                  icon: classes.icon,
                }}
              >
                <MenuItem value='all'>All</MenuItem>
                {practiceAreas.map((item) =>
                  item.options.map((subItem) => {
                    if (!subItem.placeholder) {
                      return (
                        <MenuItem key={subItem.value} value={subItem.value}>
                          {subItem.label}
                        </MenuItem>
                      )
                    }
                    return null
                  })
                )}
              </Select>
            )}
            name='practiceArea'
            control={control}
            onChange={([selected]) => {
              changePracticeAreas(selected.target.value)
              return selected.target.value
            }}
            defaultValue={match.params.practiceArea}
          />
        </div>
        <div className='o-resultsSearchBar__divider' />
        <div className='o-resultsSearchBar__field -postcode'>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                fullWidth
                className={classes.input}
                disableUnderline
                placeholder='Postcode'
              />
            )}
            name='postcode'
            control={control}
            defaultValue={match.params.postcode}
            required
          />
        </div>
        <div className='o-resultsSearchBar__action'>
          <Button
            className={classes.button}
            size='large'
            variant='contained'
            color='primary'
            type={isSubmitting ? 'button' : 'submit'}
          >
            {isSubmitting ? <Spinner color='#fff' /> : 'Search'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default withRouter(ResultsSearchBar)
