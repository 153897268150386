import React from 'react'

import { FormControlLabel, makeStyles, Switch } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#25a115',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 16,
    height: 16,
  },
  track: {
    borderRadius: 10,
    border: `none`,
    backgroundColor: '#dadbdd',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  label: {
    color: '#575759',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
}))

const IOSSwitch = ({ label, checked, value, handleChange }) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      control={
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          checked={checked}
          value={value}
          onChange={() => handleChange(!checked)}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      }
      classes={{
        label: classes.label,
      }}
      label={label}
    />
  )
}

export default IOSSwitch
