import React, { useState } from 'react'

import { Button, FormControl, makeStyles } from '@material-ui/core'
import { Link, withRouter } from 'react-router-dom'

import { ReactComponent as FacebookIcon } from 'images/facebook.svg'
import { ReactComponent as InstagramIcon } from 'images/instagram.svg'
import { ReactComponent as LinkedinIcon } from 'images/linkedin.svg'
import { ReactComponent as TwitterIcon } from 'images/twitter.svg'

import './Footer.scss'

const Footer = ({ location }) => {
  if (
    location.pathname.includes('/panel/') ||
    location.pathname === '/login' ||
    location.pathname === '/lawyer-registration'
  ) {
    return false
  }

  return (
    <footer className='t-mainFooter'>
      <div className='container mx-auto'>
        <div className='t-mainFooter__actions'>
          <div className='t-mainFooter__actions--column'>
            <strong>Essential Links</strong>
            <ul>
              <li>
                <Link to='/'>Events</Link>
              </li>
              <li>
                <Link to='/login'>Log In</Link>
              </li>
              <li>
                <Link to='/'>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className='t-mainFooter__actions--column'>
            <strong>Our Company</strong>
            <ul>
              <li>
                <Link to='/terms-and-conditions'>Terms & Conditions</Link>
              </li>
              <li>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
              <li>
                <Link to='/be-featured'>Become a featured lawyer</Link>
              </li>
            </ul>
          </div>
          <div className='t-mainFooter__actions--column -join'>
            <Link to='/join'>Join Now</Link>
          </div>
        </div>
        <div className='t-mainFooter__copyright'>
          <div className='t-mainFooter__copyright--text'>
            Lawyerup is the online trading name of Eclatech Ltd which is
            registered & incorporated in England & Wales. Company Registration
            number 11777022. SIC:74909
          </div>
          <div className='t-mainFooter__copyright--social'>
            <Link to='/'>
              <FacebookIcon />
            </Link>
            <Link to='/'>
              <TwitterIcon />
            </Link>
            <Link to='/'>
              <InstagramIcon />
            </Link>
            <Link to='/'>
              <LinkedinIcon />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default withRouter(Footer)
