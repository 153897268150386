import React from 'react'

import Button from '@material-ui/core/Button'

import ResultsMap from 'components/organisms/ResultsMap'
import { ReactComponent as PinIcon } from 'images/icon-pin-map.svg'
import './GetDirections.scss'

const GetDirections = ({ lawyerData }) => {
  return (
    <div className='m-getDirections'>
      <div className='m-getDirections__info'>
        <div className='m-getDirections__info--container'>
          <div className='m-getDirections__info--content'>
            <h3>Location</h3>
          </div>
        </div>
      </div>
      <div className='m-getDirections__map'>
        <ResultsMap single list={[lawyerData]} />
      </div>
    </div>
  )
}

export default GetDirections
