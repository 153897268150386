import { useCallback } from 'react'

import { firestore } from 'services/firebase'

const useGetLawyerByUid = () => {
  return useCallback(async (uid) => {
    return firestore
      .collection('users')
      .where('uid', '==', uid)
      .where('role', '==', 'lawyer')
      .get()
      .then((snap) => {
        return snap.docs.map((item) => {
          return item.data()
        })
      })
  }, [])
}

export default useGetLawyerByUid
