import React, { useState, useEffect } from 'react'

import Modal from '@material-ui/core/Modal'
import moment from 'moment'
import { Spinner } from 'react-activity'
import { FaClock } from 'react-icons/all'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import StripeCheckoutForm from 'components/organisms/StripeCheckoutForm/StripeCheckoutForm'
import { useNotifications } from 'context/notifications'
import { ReactComponent as BusinessEmploymentIcon } from 'images/cases/icons/business-employment.svg'
import { ReactComponent as CriminalLawIcon } from 'images/cases/icons/criminal-law.svg'
import { ReactComponent as FamilyIcon } from 'images/cases/icons/family.svg'
import { ReactComponent as NoWinNoFeeIcon } from 'images/cases/icons/no-win-no-fee.svg'
import { ReactComponent as OtherIcon } from 'images/cases/icons/other.svg'
import { ReactComponent as PropertyIcon } from 'images/cases/icons/property.svg'
import { ReactComponent as VisaImmigrationIcon } from 'images/cases/icons/visa-immigration.svg'
import { ReactComponent as WillsProbateIcon } from 'images/cases/icons/wills-probate.svg'
import { ReactComponent as IconReport } from 'images/icon-exclamation.svg'
import { ReactComponent as FileIcon } from 'images/icon-file.svg'
import { ReactComponent as VideoIcon } from 'images/icon-video.svg'
import { ReactComponent as CalendarIcon } from 'images/icons/calendar.svg'
import { ReactComponent as PercentageIcon } from 'images/icons/percentage.svg'
import { ReactComponent as PoundIcon } from 'images/icons/pound.svg'
import { auth } from 'services/firebase'
import useGetCaseById from 'services/firebase/hooks/cases/useGetCaseById'
import useRegisterTimelineItem from 'services/firebase/hooks/cases/useRegisterTimelineItem'
import useUpdateCaseById from 'services/firebase/hooks/cases/useUpdateCaseById'
import useAddCaseSystemMessage from 'services/firebase/hooks/chat/useAddCaseSystemMessage'
import useRegisterUserNotification from 'services/firebase/hooks/notifications/useRegisterUserNotification'
import { expertise } from 'utils/constants'
import { formatter } from 'utils/functions'

import './ChatMessage.scss'

const getIcon = (type) => {
  switch (type) {
    case 'family':
      return <FamilyIcon />
    case 'property':
      return <PropertyIcon />
    case 'noWinNoFee':
      return <NoWinNoFeeIcon />
    case 'willsProbate':
      return <WillsProbateIcon />
    case 'businessEmployment':
      return <BusinessEmploymentIcon />
    case 'visaImmigration':
      return <VisaImmigrationIcon />
    case 'criminalLaw':
      return <CriminalLawIcon />
    default:
      return <OtherIcon />
  }
}

const ChatMessage = ({
  chatId,
  messageId,
  message,
  caseId = null,
  fileUrl = null,
  thumbnail = null,
  filename = null,
  timestamp,
  sender,
  senderFrom = null,
  handleScroll,
  handleOpenVideo,
  handleShowReportModal,
  type = null,
}) => {
  const { updateNotificationsStates } = useNotifications()

  const doAddCaseSystemMessage = useAddCaseSystemMessage()
  const doRegisterTimelineItem = useRegisterTimelineItem()
  const doRegisterUserNotification = useRegisterUserNotification()

  const { uid, displayName, email } = auth.currentUser
  const [offer, setOffer] = useState(null)
  const [offerStatus, setOfferStatus] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const doGetCaseById = useGetCaseById()
  const doUpdateCaseById = useUpdateCaseById()

  const fetchInitialData = async () => {
    if (caseId) {
      doGetCaseById(caseId).then((result) => {
        if (result) {
          setOffer(result)
          setOfferStatus(result.status)
          setTimeout(() => {
            handleScroll()
          }, 100)
        }
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchInitialData()
  }, [type, thumbnail, fileUrl])

  const handleRemoveOffer = () => {
    doUpdateCaseById(caseId, {}, true)
      .then(() => {
        setOffer(null)
        doAddCaseSystemMessage(
          caseId,
          offer.senderUid,
          offer.receiverUid,
          {
            [offer.senderUid]: 'Your offer has been withdrawn.',
            [offer.receiverUid]: 'The lawyer withdrew the offer.',
          },
          'message'
        )
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const handleAcceptOffer = async (data) => {
    doUpdateCaseById(caseId, { status: 'active', stripe: data || null }).then(
      async () => {
        await doAddCaseSystemMessage(
          caseId,
          offer.senderUid,
          offer.receiverUid,
          {
            [offer.senderUid]: 'Offer accepted! You can start working',
            [offer.receiverUid]: 'Offer accepted!',
          },
          'offerAccepted'
        )

        await doRegisterTimelineItem(caseId, {
          type: 'started',
          messages: {
            lawyer: 'The client accepted your offer and the case started',
            client: "You accepted the lawyer's offer and the case started",
          },
          createdAt: new Date(),
        })

        /**
         * Lawyer notification
         */
        await doRegisterUserNotification(offer.senderUid, {
          message: `${displayName} accepted your offer.`,
          type: 'offer-accepted',
          link: `/panel/cases/${caseId}`,
        })

        /**
         * Client notification
         */
        await doRegisterUserNotification(offer.receiverUid, {
          message: `Your case has started.`,
          type: 'case-started',
          link: `/panel/cases/${caseId}`,
        })

        await updateNotificationsStates()

        toast.success('Offer successfully started!')
        setOfferStatus('active')
      }
    )
  }

  const handleAcceptOfferAction = () => {
    if (
      offer.paymentType === 'fixedFee' ||
      offer.paymentType === 'hourlyRate'
    ) {
      setShowPaymentModal(true)
    } else {
      handleAcceptOffer()
    }
  }

  return (
    <>
      <div
        className={`m-chatMessage ${uid === sender ? '-sender' : '-receiver'} ${
          caseId ? '-offer' : ''
        } ${sender === 'system' ? '-system' : ''}`}
      >
        {!isLoading ? (
          caseId && offer && sender !== 'system' ? (
            <div className='m-chatMessage__offer'>
              <header>
                <div className='m-chatMessage__offer--type'>
                  <div className='m-chatMessage__offer--type-title'>
                    <div className='m-chatMessage__offer--type-icon'>
                      {getIcon(offer.paymentType)}
                    </div>
                    <strong>
                      {offer.for &&
                        expertise.find((exp) => exp.value === offer.for).label}
                    </strong>
                  </div>
                  {offerStatus === 'offer' && <span>New offer</span>}
                  {offerStatus === 'canceled' && <span>Canceled</span>}
                  {offerStatus !== 'canceled' && offerStatus !== 'offer' && (
                    <span>Case</span>
                  )}
                </div>
                <ul>
                  {offer.rate && offer.paymentType !== 'noWinNoFee' && (
                    <li>
                      <PoundIcon />
                      <span>Hourly Rate</span>
                      <span>{formatter.format(offer.rate)}</span>
                    </li>
                  )}
                  {offer.paymentType === 'noWinNoFee' ? (
                    <li>
                      <PoundIcon />
                      <span>Rate</span>
                      <span>{offer.rate}%</span>
                    </li>
                  ) : (
                    <li>
                      {offer.paymentType === 'legalAid' ? (
                        <>
                          <PoundIcon />
                          <span>Legal Aid</span>
                          <span>-</span>
                        </>
                      ) : (
                        <>
                          <PoundIcon />
                          <span>Price</span>
                          {!isNaN(offer.amount) && (
                            <span>{formatter.format(offer.amount)}</span>
                          )}
                        </>
                      )}
                    </li>
                  )}
                  {offer.estimatedHours && (
                    <li>
                      <FaClock />
                      <span>Estimated Hours</span>
                      <span>{offer.estimatedHours} hours</span>
                    </li>
                  )}
                  <li>
                    <CalendarIcon />
                    <span>Deadline</span>
                    <span>
                      {offer.deadline &&
                        moment(offer.deadline.toDate()).format('DD/MM/YYYY')}
                    </span>
                  </li>
                  {uid === offer.senderUid && (
                    <>
                      {offer.paymentType !== 'legalAid' &&
                        offer.paymentType !== 'noWinNoFee' && (
                          <li>
                            <PercentageIcon />
                            <span>Lawyerup 10% + VAT:</span>
                            <span>{formatter.format(offer.amount * 0.12)}</span>
                          </li>
                        )}
                      {offer.paymentType === 'noWinNoFee' && (
                        <li>
                          <PercentageIcon />
                          <span>Lawyerup 10% + VAT:</span>
                          <span>{(offer.rate * 0.12).toFixed(2)}%</span>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </header>
              <main>
                <p>{offer.description}</p>
                {uid === offer.senderUid && (
                  <div className='m-chatMessage__offer--paymentDetails'>
                    {offer.paymentType === 'legalAid' && (
                      <p>
                        If eligible for legal aid, zero commission will be
                        applied. You must however send this offer to respective
                        client to confirm your services have been instructed.
                      </p>
                    )}

                    <ul
                      className={`${
                        (offer.paymentType !== 'legalAid' &&
                          offer.paymentType !== 'noWinNoFee') ||
                        offer.paymentType === 'noWinNoFee'
                          ? '-show'
                          : '-hide'
                      }`}
                    >
                      {offer.paymentType !== 'legalAid' &&
                        offer.paymentType !== 'noWinNoFee' && (
                          <li>
                            <span>You receive after completion:</span>
                            <span>{formatter.format(offer.amount * 0.88)}</span>
                          </li>
                        )}
                      {offer.paymentType === 'noWinNoFee' && (
                        <>
                          <li>
                            <span>You receive after completion:</span>
                            <span>{(offer.rate * 0.88).toFixed(2)}%</span>
                          </li>
                          <li>
                            <span>Client will receive:</span>
                            <span>{100 - offer.rate}%</span>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                )}
              </main>
              <footer>
                {offerStatus === 'canceled' && (
                  <button disabled>This case was canceled</button>
                )}
                {offerStatus &&
                offerStatus !== 'offer' &&
                offerStatus !== 'canceled' ? (
                  <>
                    <Link to={`/panel/cases/${caseId}`}>View offer</Link>
                    <button disabled className='-accepted'>
                      Offer accepted
                    </button>
                  </>
                ) : (
                  offerStatus !== 'canceled' && (
                    <>
                      {uid === offer.senderUid ? (
                        <button onClick={handleRemoveOffer}>
                          Withdraw offer
                        </button>
                      ) : (
                        <button onClick={handleAcceptOfferAction}>
                          Accept offer
                        </button>
                      )}
                    </>
                  )
                )}
              </footer>
            </div>
          ) : (
            <>
              {(!caseId || sender === 'system') && (
                <>
                  {type === 'link' && (
                    <Link
                      to={`/panel/cases/${caseId}`}
                      className='m-chatMessage__message -linkMessage'
                    >
                      {typeof message === 'object'
                        ? message[uid].split(/\r?\n/g).map((item, key) => {
                            return item.length ? (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            ) : (
                              ''
                            )
                          })
                        : message.split(/\r?\n/g).map((item, key) => {
                            return item.length ? (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            ) : (
                              ''
                            )
                          })}
                      <span className='m-chatMessage__button'>
                        {typeof message === 'object' &&
                        message[uid] ===
                          'The lawyer marked the case as complete.'
                          ? 'Click to approve'
                          : 'View'}
                      </span>
                      <div className='m-chatMessage__sentAt'>
                        {moment.unix(timestamp).format('HH:mm')}
                      </div>
                    </Link>
                  )}
                  {type === 'videoRoom' && (
                    <div className='m-chatMessage__message -videoRoom'>
                      {uid === senderFrom
                        ? 'You entered the video room'
                        : message}
                      <button
                        onClick={() => handleOpenVideo(true)}
                        className='m-chatMessage__button'
                      >
                        <VideoIcon />
                        Join
                      </button>
                      <div className='m-chatMessage__sentAt'>
                        {moment.unix(timestamp).format('HH:mm')}
                      </div>
                    </div>
                  )}
                  {type !== 'link' && type !== 'videoRoom' && (
                    <div
                      className={`m-chatMessage__message ${
                        type ? `-${type}` : ''
                      }`}
                    >
                      {typeof message === 'object' ? (
                        message[uid].split(/\r?\n/g).map((item, key) =>
                          item.length ? (
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          ) : (
                            ''
                          )
                        )
                      ) : (
                        <>
                          {type === 'loading' && (
                            <div
                              className={`m-chatMessage__message--${type} -placeholder`}
                            >
                              <Spinner color='#fff' size={24} />
                            </div>
                          )}
                          {type && type !== 'message' && (
                            <a
                              href={fileUrl}
                              target='_blank'
                              rel='noopener noreferrer'
                              className={`m-chatMessage__message--${type}`}
                            >
                              {type === 'image' && (
                                <img
                                  src={thumbnail ? thumbnail : fileUrl}
                                  alt=''
                                />
                              )}
                              {type === 'video' && (
                                <>
                                  <VideoIcon />
                                  {filename}
                                </>
                              )}
                              {type === 'document' && (
                                <>
                                  <FileIcon />
                                  {filename}
                                </>
                              )}
                            </a>
                          )}
                          {(!type || type === 'message') &&
                            message.split(/\r?\n/g).map((item, key) =>
                              item.length ? (
                                <span key={key}>
                                  {item}
                                  <br />
                                </span>
                              ) : (
                                ''
                              )
                            )}
                        </>
                      )}

                      <div className='m-chatMessage__sentAt'>
                        {moment.unix(timestamp).format('HH:mm')}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )
        ) : (
          caseId && (
            <div className='m-chatMessage__offer--placeholder'>
              <Spinner color='#fff' size={34} />
            </div>
          )
        )}
        {uid !== sender && sender !== 'system' && !offer && (
          <IconReport
            className='report'
            width='20'
            height='20'
            onClick={() =>
              handleShowReportModal({
                uid,
                displayName,
                email,
                chatId,
                messageId,
                sender,
                message,
              })
            }
          />
        )}
      </div>
      <Modal open={showPaymentModal}>
        <>
          <StripeCheckoutForm
            handleShowModal={setShowPaymentModal}
            offerId={caseId}
            offer={offer}
            handleOffer={handleAcceptOffer}
          />
        </>
      </Modal>
    </>
  )
}

export default ChatMessage
