import React, { useState, useEffect, useCallback } from 'react'

import moment from 'moment'
import Avatar from 'react-avatar'

import { auth, db } from 'services/firebase'

import './ChatContact.scss'

const ChatContact = ({
  members,
  newMessages,
  timestamp,
  handleOnClick,
  roomId,
  lastMessage,
  isActive,
  search,
}) => {
  const { uid } = auth.currentUser

  const [name, setName] = useState('-')
  const [avatar, setAvatar] = useState(null)
  const [status, setStatus] = useState('offline')
  const [isLoading, setIsLoading] = useState(true)
  const [contactUid, setContactUid] = useState(null)

  const handleGetContact = useCallback(async (userId) => {
    return db.ref(`users/${userId}`).on('value', function (snap) {
      const userSnap = snap.val()

      handleUserData(
        userSnap.displayName || `${userSnap.name} ${userSnap.surname}`,
        `${userSnap.photoURL}?size=small`
      )
      setStatus(userSnap.status)
      setIsLoading(false)
    })
  }, [])

  const handleUserData = (name, avatar) => {
    setName(name)
    setAvatar(avatar)
  }

  useEffect(() => {
    Object.keys(members).map((userId) => {
      if (userId !== uid) {
        handleGetContact(userId)
        setContactUid(userId)
      }
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isActive) {
      handleOnClick(roomId, contactUid)
    }
  }, [contactUid]) // eslint-disable-line

  if (search && name.search(new RegExp(search, 'i')) === -1) {
    return <></>
  }

  return (
    <div
      className={`m-chatContact ${isActive ? '-active' : ''} ${
        typeof newMessages !== 'undefined' && newMessages[uid] === true
          ? '-newMessages'
          : ''
      }`}
    >
      <button
        className='m-chatContact__button'
        title={name}
        onClick={() => handleOnClick(roomId, contactUid)}
      >
        {isLoading ? (
          <div className='m-chatContact__button--placeholder'>
            <div className='m-chatContact__button--placeholder-avatar' />
            <div className='m-chatContact__button--placeholder-message' />
          </div>
        ) : (
          <>
            <div className={`m-chatContact__button--avatar -${status}`}>
              <Avatar
                size='60'
                maxInitials={2}
                round='100%'
                color='#26395A'
                textSizeRatio={2.5}
                src={avatar}
                name={name}
              />
            </div>
            <div className='m-chatContact__button--info'>
              <small>
                {timestamp &&
                  moment.unix(timestamp).calendar(null, {
                    sameDay: 'HH:mm',
                    nextDay: '[Tomorrow]',
                    lastDay: '[Yesterday]',
                    lastWeek: 'DD/MM/YYYY',
                    sameElse: 'DD/MM/YYYY',
                  })}
              </small>
              <strong>{name}</strong>
              <p>
                {(typeof lastMessage === 'object'
                  ? lastMessage[uid]
                  : lastMessage) || '--'}
              </p>
            </div>
          </>
        )}
      </button>
    </div>
  )
}

export default ChatContact
