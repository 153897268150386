import React from 'react'
import './Section.scss'

const Section = ({
  children,
  modifier = '',
  className = '',
  container = true,
}) => {
  return (
    <section className={`a-section ${modifier} ${className}`}>
      {container ? (
        <div className='container mx-auto'>{children}</div>
      ) : (
        children
      )}
    </section>
  )
}

export default Section
