import React, { useEffect, useState } from 'react'

import {
  MenuItem,
  Select,
  InputLabel,
  makeStyles,
  FormControl,
  ListItemText,
  Checkbox,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

import useLawyerRegistration from 'hooks/useLawyerRegistration'
import { expertise, languages } from 'utils/constants'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    padding: 0,
    width: '100%',
    height: '100%',
    minWidth: 140,
    borderRadius: 5,
  },
  icon: {
    right: 15,
  },
  button: {
    width: 200,
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
    height: 70,
    lineHeight: '70px',
    padding: 0,
    borderRadius: 0,
    boxShadow: 'none',
  },
}))

const ProfileExpertise = ({ errors }) => {
  const classes = useStyles()
  const [formValues, setFormValues] = useLawyerRegistration()
  const [practiceAreas, setPracticeAreas] = useState([])
  const [stepErros, setStepErros] = useState([])

  useEffect(() => {
    setStepErros(errors)
  }, [errors])

  const handleFormValues = (e) => {
    if (e.target.name === 'practiceAreas') {
      const filteredValues = e.target.value.map((item) => item.value)
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        expertiseAreas: filteredValues,
      })
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }
    if (stepErros.includes(e.target.name)) {
      const tempErr = stepErros
      const errIndex = stepErros.indexOf(e.target.name)
      tempErr.splice(errIndex, 1)
      setStepErros(tempErr)
    }
  }

  useEffect(() => {
    setPracticeAreas([])
    setFormValues({ ...formValues, practiceAreas: [], expertiseAreas: [] })
    if (formValues.expertise) {
      const tempPracticeAreas = expertise.filter((item) =>
        formValues.expertise.includes(item.value)
      )
      setPracticeAreas(tempPracticeAreas)
    }
  }, [formValues.expertise]) // eslint-disable-line

  return (
    <div>
      <FormControl
        error={stepErros.includes('expertise')}
        required
        component='div'
        variant='filled'
        className={classes.formControl}
      >
        <InputLabel id='select-expertise'>Select your expertise</InputLabel>
        <Select
          required
          error={stepErros.includes('expertise')}
          multiple
          labelId='select-expertise'
          disableUnderline
          IconComponent={KeyboardArrowDown}
          name='expertise'
          value={formValues.expertise}
          classes={{
            icon: classes.icon,
          }}
          className={classes.select}
          inputProps={{ className: classes.input }}
          onChange={handleFormValues}
          renderValue={(selected) => {
            return selected
              .map((item) => {
                const currentItem = expertise.find((exp) => item === exp.value)
                return currentItem.label
              })
              .join(', ')
          }}
        >
          {expertise.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox
                color='primary'
                checked={formValues.expertise.indexOf(item.value) > -1}
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        error={stepErros.includes('practiceAreas')}
        component='div'
        variant='filled'
        className={classes.formControl}
        disabled={formValues.expertise.length === 0}
      >
        <InputLabel id='select-practice-areas'>
          Select your practice areas
        </InputLabel>
        <Select
          required
          error={stepErros.includes('practiceAreas')}
          multiple
          labelId='select-practice-areas'
          disableUnderline
          IconComponent={KeyboardArrowDown}
          name='practiceAreas'
          value={formValues.practiceAreas}
          classes={{
            icon: classes.icon,
          }}
          className={classes.select}
          inputProps={{ className: classes.input }}
          onChange={handleFormValues}
          renderValue={(selected) =>
            selected.map((item) => item.label).join(', ')
          }
        >
          {practiceAreas.map((item) =>
            item.options.map((subItem) => (
              <MenuItem
                disabled={subItem.placeholder || false}
                key={subItem.value}
                value={subItem}
              >
                <Checkbox
                  color='primary'
                  checked={formValues.practiceAreas.indexOf(subItem) > -1}
                />
                <ListItemText primary={subItem.label} />
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      <FormControl
        error={stepErros.includes('languages')}
        component='div'
        variant='filled'
        className={classes.formControl}
      >
        <InputLabel id='select-languages'>Languages you speak</InputLabel>
        <Select
          required
          error={stepErros.includes('languages')}
          multiple
          labelId='select-languages'
          disableUnderline
          IconComponent={KeyboardArrowDown}
          name='languages'
          value={formValues.languages}
          classes={{
            icon: classes.icon,
          }}
          className={classes.select}
          inputProps={{ className: classes.input }}
          onChange={handleFormValues}
          renderValue={(selected) =>
            selected.map((item) => item.name).join(', ')
          }
        >
          {languages.map((item) => {
            return (
              <MenuItem key={item.initials} value={item}>
                <Checkbox
                  color='primary'
                  checked={formValues.languages.indexOf(item) > -1}
                />
                <ListItemText primary={item.name} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl
        error={stepErros.includes('practisingSince')}
        component='div'
        variant='filled'
        className={classes.formControl}
      >
        <InputLabel id='select-practising-since'>Practising since</InputLabel>
        <Select
          required
          error={stepErros.includes('practisingSince')}
          labelId='select-practising-since'
          disableUnderline
          IconComponent={KeyboardArrowDown}
          name='practisingSince'
          value={formValues.practisingSince}
          classes={{
            icon: classes.icon,
          }}
          className={classes.select}
          inputProps={{ className: classes.input }}
          onChange={handleFormValues}
        >
          <MenuItem value={1}>1+ year</MenuItem>
          <MenuItem value={5}>5+ years</MenuItem>
          <MenuItem value={10}>10+ years</MenuItem>
          <MenuItem value={20}>20+ years</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default ProfileExpertise
