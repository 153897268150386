import React, { useState } from 'react'

import {
  FormControl,
  TextField,
  Button,
  makeStyles,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import Email from '@material-ui/icons/Email'
import Lock from '@material-ui/icons/Lock'
import Person from '@material-ui/icons/Person'
import PhoneEnabled from '@material-ui/icons/PhoneEnabled'
import { Spinner } from 'react-activity'
import { useHistory, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import useUserInfo from 'hooks/useUserInfo'
import { ReactComponent as CheckIcon } from 'images/icon-check-button.svg'
import { ReactComponent as CloseIcon } from 'images/icon-close-modal.svg'
import { ReactComponent as FacebookIcon } from 'images/icon-facebook.svg'
import loginLogo from 'images/login-logo.png'
import { auth, db, firestore } from 'services/firebase'
import useSignInWithEmail from 'services/firebase/hooks/auth/useSignInWithEmail'
import useSignUpWithEmail from 'services/firebase/hooks/auth/useSignUpWithEmail'
import useSignUpWithFacebook from 'services/firebase/hooks/auth/useSignUpWithFacebook'

import 'react-activity/dist/Spinner.css'
import './Login.scss'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  formControlLabel: {
    marginLeft: 0,
    color: '#888',
    fontFamily: 'Poppins',
  },
  checkboxLabel: {
    fontSize: 14,
  },
}))

const LoginPage = ({
  isModal = false,
  handleCloseModal,
  handleModalRedirect,
}) => {
  const history = useHistory()
  const [, setUserInfo] = useUserInfo()

  const doSignUpWithFacebook = useSignUpWithFacebook()
  const doSignUpWithEmail = useSignUpWithEmail()
  const doSignInWithEmail = useSignInWithEmail()

  const classes = useStyles()
  const [page, setPage] = useState('login')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const handleFacebookAction = async () => {
    setSubmitting(true)
    await doSignUpWithFacebook()
      .then((result) => {
        if (isModal) {
          handleModalRedirect(result.user.uid)
          return
        }
        return history.push('/panel/dashboard')
      })
      .catch((err) => {
        setSubmitting(false)
        return toast.error(err.message)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    if (email && password) {
      if (page === 'login') {
        doSignInWithEmail({ email, password })
          .then((result) => {
            if (isModal) {
              handleModalRedirect(result.user.uid)
              return
            }
            return history.push('/panel/dashboard')
          })
          .catch((err) => {
            setSubmitting(false)
            if (err) {
              return toast.error(err.message)
            }
          })
      } else {
        if (name && phone) {
          if (name.indexOf(' ') !== -1) {
            doSignUpWithEmail({ name, phone, email, password })
              .then(async () => {
                const currentUser = auth.currentUser
                await currentUser.updateProfile({
                  displayName: name,
                  phone: phone,
                  photoURL: '',
                  role: 'client',
                })

                if (currentUser.uid) {
                  const user = {
                    uid: currentUser.uid,
                    email: email,
                    displayName: name,
                    photoURL: '',
                    role: 'client',
                    phone: phone,
                  }
                  setUserInfo(user)
                  await db.ref(`users/${user.uid}`).set(user)
                  await firestore
                    .collection('users')
                    .doc(currentUser.uid)
                    .set(user)
                }

                if (isModal) {
                  handleModalRedirect(currentUser.uid)
                  return
                }
                history.push('/panel/dashboard')
              })
              .catch((err) => {
                setSubmitting(false)
                return toast.error(err.message)
              })
          } else {
            setSubmitting(false)
            return toast.error('Please enter your full name.')
          }
        } else {
          setSubmitting(false)
          return toast.error('All fields are mandatory')
        }
      }
    } else {
      setSubmitting(false)
      toast.error('All fields are mandatory')
      return false
    }
  }

  return (
    <div className={`p-loginPage ${isModal ? '-modal' : ''}`}>
      <div className='p-loginPage__intro'>
        <div className='p-loginPage__intro--content'>
          <Link to='/'>
            <img src={loginLogo} alt='' />
          </Link>
        </div>
      </div>
      <div className='p-loginPage__forms'>
        {isModal && (
          <button
            className='p-loginPage__forms--closeModal'
            onClick={() => handleCloseModal(false)}
          >
            <CloseIcon />
          </button>
        )}
        <div className='p-loginPage__forms--content'>
          <h1>Login to Lawyerup</h1>
          <div className='p-loginPage__forms--tabs'>
            <button
              type='button'
              onClick={() => setPage('signUp')}
              className={`p-loginPage__forms--tab ${
                page === 'signUp' ? '-selected' : ''
              }`}
            >
              <i>
                <CheckIcon />
              </i>
              <span>New Client</span>
            </button>
            <button
              type='button'
              onClick={() => setPage('login')}
              className={`p-loginPage__forms--tab ${
                page === 'login' ? '-selected' : ''
              }`}
            >
              <i>
                <CheckIcon />
              </i>
              <span>Existing Client</span>
            </button>
          </div>
          <form
            onSubmit={handleSubmit}
            className='p-loginPage__form'
            autoComplete='none'
          >
            {page === 'signUp' && (
              <div className='p-loginPage__form--half'>
                <div className='p-loginPage__form--field'>
                  <FormControl component='div' fullWidth variant='filled'>
                    <TextField
                      label='Name'
                      variant='filled'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>
                <div className='p-loginPage__form--field'>
                  <FormControl component='div' fullWidth variant='filled'>
                    <TextField
                      label='Mobile number'
                      variant='filled'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <PhoneEnabled />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            )}
            <div className='p-loginPage__form--field'>
              <FormControl component='div' fullWidth variant='filled'>
                <TextField
                  type='email'
                  label='Email Address'
                  variant='filled'
                  value={email}
                  onChange={(e) => setEmail(e.target.value.replace(/\s/g, ''))}
                  autoComplete='none'
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: 'none',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div className='p-loginPage__form--field'>
              <FormControl component='div' fullWidth variant='filled'>
                <TextField
                  type='password'
                  label='Password'
                  variant='filled'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete='none'
                  InputProps={{
                    disableUnderline: true,
                    autoComplete: 'none',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div className='p-loginPage__form--action'>
              <FormControl component='div' fullWidth variant='outlined'>
                <Button
                  type='submit'
                  className={classes.button}
                  size='large'
                  variant='contained'
                  color='primary'
                >
                  {submitting ? (
                    <Spinner color='#fff' />
                  ) : page === 'login' ? (
                    'Login'
                  ) : (
                    'Sign Up'
                  )}
                </Button>
              </FormControl>
            </div>
            {page === 'login' && (
              <div className='p-loginPage__form--extra'>
                <FormControlLabel
                  className={classes.formControlLabel}
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox defaultChecked value='true' color='primary' />
                  }
                  label='Remember me'
                />
                <Link to='/forgot-password'>Forgot password?</Link>
              </div>
            )}
          </form>
          <div className='p-loginPage__social'>
            <div className='p-loginPage__social--divider'>or</div>
            <div className='p-loginPage__social--facebook'>
              <button type='button' onClick={handleFacebookAction}>
                <i>
                  <FacebookIcon />
                </i>
                <span>continue with facebook</span>
              </button>
            </div>
            <p>By clicking Login you agree to our T&C’s</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
