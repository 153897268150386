import React, { memo } from 'react'

import { GiRoundStar } from 'react-icons/gi'

import { useCases } from 'context/cases'
import { ReactComponent as CancelIcon } from 'images/cases/icons/cancel.svg'
import { ReactComponent as ClockIcon } from 'images/cases/icons/clock.svg'
import { ReactComponent as GavelIcon } from 'images/cases/icons/gavel.svg'
import { ReactComponent as ThumbIcon } from 'images/cases/icons/thumb-up.svg'
import { ReactComponent as TrashIcon } from 'images/cases/icons/trash.svg'
import { auth } from 'services/firebase'
import { formatter } from 'utils/functions'

import './CaseCardContent.scss'

const handleCaseStatus = (type: string): string => {
  switch (type) {
    case 'offer':
      return 'Offer'
    case 'active':
      return 'In Progress'
    case 'complete':
    case 'completed_by_lawyer':
      return 'Completed'
    case 'canceled':
      return 'Canceled'
    case 'dispute':
      return 'Dispute'
    default:
      return '--'
  }
}
const CaseCardContent = ({ caseData }) => {
  const { uid } = auth.currentUser
  const { handleModal } = useCases()

  return (
    <>
      <div className='a-caseCardContent'>
        <p>{caseData.description}</p>

        <div className='a-caseCardContent__actions'>
          {caseData.status === 'offer' && caseData.senderUid === uid ? (
            <button
              title='Remove offer'
              type='button'
              onClick={() =>
                handleModal({
                  caseData,
                  type: 'removeOffer',
                  icon: <TrashIcon />,
                })
              }
            >
              <TrashIcon />
            </button>
          ) : (
            <>
              <button
                title={
                  caseData.senderUid !== uid
                    ? 'Accept delivery'
                    : 'Mark as complete'
                }
                disabled={
                  (caseData.senderUid !== uid &&
                    caseData.status !== 'completed_by_lawyer') ||
                  (caseData.senderUid === uid &&
                    caseData.status === 'completed_by_lawyer') ||
                  caseData.status === 'complete'
                }
                type='button'
                onClick={() =>
                  handleModal({
                    caseData,
                    type: 'markAsCompleted',
                    icon: <ThumbIcon />,
                  })
                }
              >
                <ThumbIcon />
              </button>
              <button
                title={
                  caseData.senderUid !== uid
                    ? 'Accept time extension'
                    : 'Request extension'
                }
                disabled={
                  (caseData.senderUid !== uid && !caseData.newDeadline) ||
                  (caseData.senderUid === uid &&
                    caseData.status === 'completed_by_lawyer') ||
                  caseData.status === 'complete'
                }
                type='button'
                onClick={() =>
                  handleModal({
                    caseData,
                    type: 'requestExtension',
                    icon: <ClockIcon />,
                  })
                }
              >
                <ClockIcon />
              </button>
              <button
                title='Report a dispute'
                disabled={
                  caseData.status === 'offer' || caseData.status === 'complete'
                }
                type='button'
                onClick={() =>
                  handleModal({
                    caseData,
                    type: 'reportADispute',
                    icon: <GavelIcon />,
                  })
                }
              >
                <GavelIcon />
              </button>
              {caseData.receiverUid === uid && (
                <button
                  title='Send a review'
                  disabled={caseData.status !== 'complete' || caseData.rated}
                  type='button'
                  onClick={() =>
                    handleModal({
                      caseData,
                      type: 'reviewCase',
                      icon: <GiRoundStar />,
                    })
                  }
                >
                  <GiRoundStar />
                </button>
              )}
              {caseData.senderUid === uid && (
                <button
                  title='Cancel case'
                  disabled={
                    caseData.status === 'offer' ||
                    (caseData.senderUid === uid &&
                      caseData.status === 'completed_by_lawyer') ||
                    caseData.status === 'complete'
                  }
                  type='button'
                  onClick={() =>
                    handleModal({
                      caseData,
                      type: 'cancelCase',
                      icon: <CancelIcon />,
                    })
                  }
                >
                  <CancelIcon />
                </button>
              )}
            </>
          )}
        </div>
        <div className='a-caseCardContent__footer'>
          <div
            className={`a-caseCardContent__footer--status -${caseData.status}`}
          >
            {handleCaseStatus(caseData.status)}
          </div>
          <div className='a-caseCardContent__footer--total'>
            {caseData.paymentType === 'legalAid' && <span>Legal Aid</span>}
            {caseData.paymentType === 'noWinNoFee' && (
              <span>
                {uid === caseData.senderUid ? 'You' : 'Lawyer'} {caseData.rate}%
                <div className='w-6' />
                {uid === caseData.senderUid ? 'Client' : 'You'}{' '}
                {caseData.clientRate}%
              </span>
            )}

            {caseData.paymentType !== 'legalAid' &&
              caseData.paymentType !== 'noWinNoFee' && (
                <span>{formatter.format(caseData.amount)}</span>
              )}
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(CaseCardContent)
